/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { Utils } from '../../../../client/system/Utils'
import { User, UserTypes } from '../../../../client/user/User'
import { Chat } from '../../../../client/chat/Chat'
import { EnvironmentType, FirebaseApp } from '../../../../client/FirebaseApp'
import { useSupportContext } from '../../core/SupportProvider'

export function AsideMenuMain() {
  const { notificationCount } = useSupportContext();
  const siteRef = FirebaseApp.environment === EnvironmentType.STAGING ? 'localhost' : 'flirtybum';
  const [countLockedRooms, setCountLockedRooms] = useState(0);

  useEffect(() => {
    
    Chat.GetLockedChatRooms().then((data) => {
      if (data)
      {
        setCountLockedRooms(data.length);
      }
    });
}, [siteRef, notificationCount]);

  return (
    <>
      {Utils.checkAccess('readAny', 'chat') ? (
        <AsideMenuItemWithSub
          to='/apps/chat'
          title='Chat'
          fontIcon='bi-chat-left'
          icon='/media/icons/duotune/communication/com012.svg'
        >
          {Utils.checkAccess('readAny', 'chat-feed') && <AsideMenuItem
            to='/dashboard'
            title="Chat Feed"
            fontIcon='bi-app-indicator'
            hasBullet={true}
          />}
          {Utils.checkAccess('readAny', 'chat-logs', User.Model?.userType === UserTypes.TYPE_CHATTER) && <AsideMenuItem
            to='/chat-logs'
            title="Chat Logs"
            fontIcon='bi-app-indicator'
            hasBullet={true}
          />}
          {Utils.checkAccess('readAny', 'users-list-chatters') && <AsideMenuItem
            to='/users-list'
            title="Users List"
            hasBullet={true}
          />}
          {Utils.checkAccess('readAny', 'chatter-user-history') && <AsideMenuItem
            to='/chatter-user-history'
            title="Chatter User History"
            hasBullet={true}
          />}
        </AsideMenuItemWithSub>
      ) : (
        <>
        </>
      )}

      {Utils.checkAccess('readAny', 'profile') && <AsideMenuItemWithSub
        to='#'
        title='Profile'
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        {Utils.checkAccess('createAny', 'profile') && <AsideMenuItem
          to='/profile-creation'
          title="Profile Creation"
          hasBullet={true}
        />}

        {Utils.checkAccess('readAny', 'profile-push') && <AsideMenuItem
          to='/profiles'
          title="Profiles"
          hasBullet={true}
        />}

        {/* {Utils.checkAccess('readAny', 'profile-creator-list') && <AsideMenuItem
          to='/profile-creator-list'
          title="Profile Creators"
          hasBullet={true}
        />} */}

        {/* {Utils.checkAccess('updateAny', 'profile-approval') && <AsideMenuItem
          to='/profile-approval-list'
          title='Profile Approval List'
          hasBullet={true}
        />}

        {Utils.checkAccess('updateAny', 'profile-declined-list') && <AsideMenuItem
          to='/profile-declined-list'
          title='Profile Declined List'
          hasBullet={true}
        />} */}
      </AsideMenuItemWithSub>
      }


      {Utils.checkAccess('readAny', 'analytics', User.Model?.userType === UserTypes.TYPE_CHATTER) &&
        <AsideMenuItemWithSub
          to='#'
          title='Analytics'
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/communication/com012.svg'
        >
          {Utils.checkAccess('readAny', 'generalAnalytics', User.Model?.userType === UserTypes.TYPE_CHATTER) && <AsideMenuItem
            to='/general-analytics'
            title="General Analytics"
            hasBullet={true}
          />}

          {/* {Utils.checkAccess('readAny', 'signups', User.Model?.userType === UserTypes.TYPE_CHATTER) && <AsideMenuItem
            to='/signup-count'
            title="Sign Ups"
            hasBullet={true}
          />} */}
          {/* {Utils.checkAccess('readAny', 'users-by-status') && <AsideMenuItem
            to='/users-by-status'
            title="Users By Status"
            hasBullet={true}
          />}
          {Utils.checkAccess('readAny', 'double-opt-in-users') && <AsideMenuItem
            to='/double-opt-in-users'
            title="Double Opt-In Users"
            hasBullet={true}
          />} */}
          {/* {Utils.checkAccess('readAny', 'revenue', User.Model?.userType === UserTypes.TYPE_CHATTER) && <AsideMenuItem
            to='/revenue'
            title="Revenue"
            hasBullet={true}
          />} */}
          {Utils.checkAccess('readAny', 'chatters', User.Model?.userType === UserTypes.TYPE_CHATTER) && <AsideMenuItem
            to='/chatters'
            title="Chatters"
            hasBullet={true}
          />}
          {Utils.checkAccess('readAny', 'users-list', User.Model?.userType === UserTypes.TYPE_CHATTER) && <AsideMenuItem
            to='/users-list'
            title="Users List"
            hasBullet={true}
          />}
          {Utils.checkAccess('readAny', 'users-list', User.Model?.userType === UserTypes.TYPE_CHATTER) && <AsideMenuItem
            to='/trigger-analytics'
            title="Trigger Analytics"
            hasBullet={true}
          />}
           {/* {Utils.checkAccess('readAny', 'chatters') && <AsideMenuItem
            to='/individual-chatter-triggers'
            title="Individual Chatter Triggers"
            hasBullet={true}
          />} */}
           {/* {Utils.checkAccess('readAny', 'chatters', User.Model?.userType === UserTypes.TYPE_CHATTER) && <AsideMenuItem
            to='/profiles'
            title="Profiles"
            hasBullet={true}
          />} */}
          {/* {Utils.checkAccess('readAny', 'chatters', User.Model?.userType === UserTypes.TYPE_CHATTER) && <AsideMenuItem
            to='/landing-page-analytics'
            title="Landing Page Analytics"
            hasBullet={true}
          />} */}
        </AsideMenuItemWithSub>
      }

      {/* {Utils.checkAccess('readAny', 'misc') &&
        <AsideMenuItemWithSub
          to='#'
          title='Misc'
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/communication/com012.svg'
        > */}
          {/* {Utils.checkAccess('createAny', 'chatter') && <AsideMenuItem
            to='/user-search'
            title="User Search"
            hasBullet={true}
          />}
          {Utils.checkAccess('createAny', 'chatter') && <AsideMenuItem
            to='/deleted-users'
            title="Deleted Users"
            hasBullet={true}
          />} */}
          {/* {Utils.checkAccess('createAny', 'chatter') && <AsideMenuItem
            to='/create-chatter'
            title="Create Chatter"
            hasBullet={true}
          />}
          {Utils.checkAccess('deleteAny', 'chatter') && <AsideMenuItem
            to='#'
            title="Delete Chatter"
            hasBullet={true}
          />}
          {Utils.checkAccess('readAny', 'chatter') && <AsideMenuItem
            to='/chatter-list'
            title="List Chatters"
            hasBullet={true}
          />} */}
          {/* {Utils.checkAccess('deleteAny', 'user') && <AsideMenuItem
            to='#'
            title="Delete User"
            hasBullet={true}
          />} */}
          {/* {Utils.checkAccess('readAny', 'credits') && <AsideMenuItem
            to='/give-credits'
            title="Give Credits"
            hasBullet={true}
          />} */}
          {/* {Utils.checkAccess('readAny', 'profile-pic-logs') && <AsideMenuItem
            to='/profile-pic-logs'
            title="Profile Pic Logs"
            hasBullet={true}
          />} */}
        {/* </AsideMenuItemWithSub>
      } */}

      {Utils.checkAccess('readAny', 'misc') &&
        <AsideMenuItemWithSub
          to='#'
          title='Chatter'
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/communication/com012.svg'
        >
          {Utils.checkAccess('readAny', 'chatter') && <AsideMenuItem
            to='/chatter-list'
            title="List Chatters"
            hasBullet={true}
          />}

          {/* {Utils.checkAccess('readAny', 'chatter') && <AsideMenuItem
            to='/chatters-revenue'
            title="Chatters with FTD"
            hasBullet={true}
          />} */}
        </AsideMenuItemWithSub>
      }

      {Utils.checkAccess('readAny', 'users') &&
        <AsideMenuItemWithSub
          to='#'
          title='Users'
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/communication/com012.svg'
        >
          {Utils.checkAccess('readAny', 'purchases', User.Model?.userType === UserTypes.TYPE_CHATTER) && <AsideMenuItem
            to='/purchases'
            title="Purchases"
            hasBullet={true}
          />}
        </AsideMenuItemWithSub>
      }

      {/* {Utils.checkAccess('readAny', 'support') &&
        <AsideMenuItemWithSub
          to='#'
          title={`Support - ${notificationCount} `}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/communication/com012.svg'
        >
          {Utils.checkAccess('readAny', 'support') && 
          <AsideMenuItem
            to='/support-logs'
            title="Support Logs"
            fontIcon='bi-app-indicator'
            hasBullet={true}
          />}
        </AsideMenuItemWithSub>
      } */}

      {/* {Utils.checkAccess('readAny', 'locked-chat-rooms') &&
        <AsideMenuItem
        to='/locked-chat-rooms'
        title='Locked Chat Rooms'
        icon='/media/icons/duotune/communication/com012.svg'
        hasValue={true}
        value={countLockedRooms}
        />
      } */}
    </>
  )
}
