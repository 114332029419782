import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { Chat } from '../../../../client/chat/Chat';
import { Profile, ProfileModel } from '../../../../client/user/Profile';
import { User, UserModel, UserTypes } from '../../../../client/user/User';
import { FirebaseApp, EnvironmentType } from '../../../../client/FirebaseApp';
import { MessageModel } from '../../../../_metronic/helpers';
import { CircularProgress } from '@mui/material';
import { Utils } from '../../../../client/system/Utils'
import moment from 'moment';

function MessageList(props: any) {
    const [chatters, setChatters] = useState<UserModel[]>([]);
    const [userModels, setUserModels] = useState<UserModel[]>([]);
    const [profileModels, setProfileModels] = useState<ProfileModel[]>([]);
    let siteRef = ''
    if(props?.isLiveSite){
        siteRef = 'flirtybum'
    }
    else{
        siteRef =  FirebaseApp.environment === EnvironmentType.STAGING ? 'localhost' : 'flirtybum';
    }

    const [messagesNew, setMessagesNew] = useState<MessageModel[]>([]);

    const getChatterById = (userId: string | undefined): UserModel | undefined => {
        return chatters.find((x) => {
            return x.uuid === userId;
        });
    };

    const getUserModelById = (userId: string | undefined): UserModel | undefined => {
        return userModels.find((x) => {
            return x.uuid === userId;
        });
    };

    const getProfileModelById = (profileId: string | undefined): ProfileModel | undefined => {
        return profileModels.find((x) => {
            return x.id === profileId;
        });
    };

    function GetUsers(msgEntries: MessageModel[] | undefined) {
        let uuidList: string[] = [];
        if (msgEntries) {
            for (let i = 0; i < msgEntries.length; i++) {
                let entry = msgEntries[i];
                let id = entry.isChatter ? entry.recepient_id : entry.user;

                if (
                    !uuidList.includes(id as string) &&
                    id !== undefined &&
                    id !== '' &&
                    id !== null
                ) {
                    uuidList.push(id as string);
                }
            }

            if (uuidList && uuidList.length > 0) {
                User.GetUsersByIds(uuidList).then((result) => {
                    if (result) setUserModels(result);
                });
            }
        }
    }

    function GetProfiles(msgEntries: MessageModel[] | undefined) {
        let uuidList: string[] = [];
        if (msgEntries) {
            for (let i = 0; i < msgEntries.length; i++) {
                let entry = msgEntries[i];
                let id = entry.isChatter ? entry.user : entry.recepient_id;

                if (!uuidList.includes(id as string)) {
                    uuidList.push(id as string);
                }
            }

            if (uuidList && uuidList.length > 0) {
                Profile.GetProfilesByIds(uuidList).then((result) => {
                    if (result) setProfileModels(result);
                });
            }
        }
    }

    useEffect(() => {
        User.GetAllUsers(UserTypes.TYPE_CHATTER).then((chatters) => {
            setChatters(chatters);
        });
        Chat.StopListeningForChatRoomMessages(props.room);
        Chat.ListenForChatRoomMessages(props.room, siteRef, 35, async (data: any) => {
            if (data) {
                setMessagesNew(data);
                GetUsers(data);
                GetProfiles(data);
            }
        });
    }, []);

    return (
        <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
            <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>Chat List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 table-bordered'>
                    {/* begin::Table head */}
                    <thead>
                        <tr className='fw-bolder text-center'>
                            <th className='min-w-120px'>Chatter Name</th>
                            <th className='min-w-120px'>Sender</th>
                            <th className='min-w-120px'>Receiver</th>
                            <th className='min-w-120px'>Text</th>
                            <th className='min-w-120px'>Time</th>
                            <th className='min-w-120px'>Date</th>
                        </tr>
                    </thead>
                    {/* End::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                        {messagesNew.length <= 0 ? (
                            <tr className='text-center'>
                                <td colSpan={6}>
                                    {' '}
                                    <CircularProgress />
                                </td>
                            </tr>
                        ) : (
                            messagesNew &&
                            messagesNew.length > 0 &&
                            messagesNew.map((data: any) => {
                                const adminGeneratedMsgId = ['eFlEBbntRktyUlhHXQjt','AmGDybgyWsnevuGM86oc','iNHGWpQWkCahKzVLHNcE','fsDSBXG2ovODPIWs0lsA'];
                                let isAdminGeneratedMessage = adminGeneratedMsgId.includes(data.user) && (data.text.startsWith('Hey there, Mister!') || 
                                data.text.startsWith('Started to feel') || data.text.startsWith('What are you up') || 
                                data.text.startsWith('Would you mind if I had a boyfriend?'));
                                let chatter;
                                let userId;
                                let profileId;
                                let chatterId;
                            // let formattedDate = moment(data.time.split(',')[0], 'DD/MM/YYYY').format("DD/MM/YY");

                                if (data.isChatter) {
                                    userId = data.recepient_id;
                                    profileId = data.user;
                                    chatterId = data.chatter_id;
                                } else {
                                    userId = data.user;
                                    profileId = data.recepient_id;
                                }

                                let user = getUserModelById(userId) as UserModel;
                                let profile = getProfileModelById(profileId) as ProfileModel;

                                let userName = user ? user.displayName : 'User';
                                let profileName = profile ? profile.displayName : 'Profile';
                                let senderName = data.isChatter ? profileName : userName;
                                let recepientName = data.isChatter ? userName : profileName;

                                let convertedDate = Utils.ConvertDateTimeFormat(data.time, false);
                                let formattedDate = moment(
                                    convertedDate,
                                    "MM/DD/YYYY - hh:mm:ss A"
                                  );
                                if (chatterId) {
                                    chatter = getChatterById(chatterId) as UserModel;
                                }
                                return (
                                    <tr key={Math.random()} className='text-center'>
                                        <td>{isAdminGeneratedMessage ? 'Admin' : chatter?.displayName}</td>
                                        <td>{senderName}</td>
                                        <td>{recepientName}</td>
                                        <td>
                                            {data.text && data.text.startsWith('http')
                                                ? 'Image/Gif'
                                                : data.text}
                                        </td>
                                        <td>{formattedDate.fromNow()}</td>
                                        <td>{data.time.split(',')[0]}</td>
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                    {/* End::Table body */}
                </table>
            </Modal.Body>
        </Modal>
    );
}
export default MessageList;
