import { DocumentData, QueryDocumentSnapshot } from "@firebase/firestore"
import { ProfileModel } from "../../client/user/Profile"
import { UserModel } from "../../client/user/User"

export interface MessageModel {
  id: string
  user: string
  type: 'in' | 'out'
  text: string
  time: string
  contentType?: `text` | `image`
  displayName?: string
  photoUrl?: string
  template?: boolean
  recepient_id?: string
  isChatter?: boolean
  chatter_id?: string
  siteOfOrigin?: string
  tag?: string
  chatterName?: string
  isChatterReplied?: boolean
}

export interface MessageModelNew {
  // filter(arg0: (element1: any) => void): any
  id: string
  user: string
  type: 'in' | 'out'
  text: string
  time: string
  contentType?: `text` | `image`
  displayName?: string
  photoUrl?: string
  template?: boolean
  recepient_id?: string
  isChatter?: boolean
  chatter_id?: string
  siteOfOrigin?: string
  tag?: string
  chatterName?: string
  userName?:string
  recName?:string
  room?:string
  newDate?: string
  newTime?:string
  isChatterReplied?: boolean
}

export interface ChatLogModel {
  id: string,
  profile_id: string,
  user_id: string
}

export class ChatterUserHistoryModel {
  id: string = "";
  userId: string = "";
  profileId: string = "";
  createdAt: Date = null as any;
  lastUpdated: Date = null as any;

  constructor(params: any)
  {
    this.id = params.id;
    this.userId = params.uuid;
    this.profileId = params.profileId;
    this.createdAt = params.createdAt;
    this.lastUpdated = params.lastUpdated;
  }
}

export interface DetailedChatLogModel {
  id: string,
  room_id: string,
  user_id: string,
  last_updated: any,
  profile_id: string,
  chatter_id: string,
  read_status: string,
  message: string,
  messageType: string,
  isChatter: boolean,
  timeToRespond: number,
}

export interface ChatRoomModel {
  id: string,
  chattersInvolved: string[],
  isRoomLocked: boolean,
  lastUpdated: any,
  profileId: string,
  readStatus: 'read' | 'unread',
  userId: string
}

export interface FeedEntryModel {
  id: string
  message?: MessageModel
  createdAt?: string
  userId: string
  roomId: string
  siteOfOrigin: string
  tag: string
  append?: string
}

export interface ProfileAndLatestMessageModel {
  profile_id: string,
  message: MessageModel,
  read_status: 'unread' | 'read'
}

export interface UserMessageTable {
  profile_id: string,
  gender: string,
  profile_name: string,
  age: string,
  country: string,
  appearance: string,
  sexualorientation: string,
  meritalstatus: string,
  length: string,
  photoUrl: string
}

export interface AlertModel {
  title: string
  description: string
  time: string
  icon: string
  state: 'primary' | 'danger' | 'warning' | 'success' | 'info'
}

const defaultAlerts: Array<AlertModel> = [
  {
    title: 'Project Alice',
    description: 'Phase 1 development',
    time: '1 hr',
    icon: 'icons/duotune/technology/teh008.svg',
    state: 'primary',
  },
  {
    title: 'HR Confidential',
    description: 'Confidential staff documents',
    time: '2 hrs',
    icon: 'icons/duotune/general/gen044.svg',
    state: 'danger',
  },
  {
    title: 'Company HR',
    description: 'Corporeate staff profiles',
    time: '5 hrs',
    icon: 'icons/duotune/finance/fin006.svg',
    state: 'warning',
  },
  {
    title: 'Project Redux',
    description: 'New frontend admin theme',
    time: '2 days',
    icon: 'icons/duotune/files/fil023.svg',
    state: 'success',
  },
  {
    title: 'Project Breafing',
    description: 'Product launch status update',
    time: '21 Jan',
    icon: 'icons/duotune/maps/map001.svg',
    state: 'primary',
  },
  {
    title: 'Banner Assets',
    description: 'Collection of banner images',
    time: '21 Jan',
    icon: 'icons/duotune/general/gen006.svg',
    state: 'info',
  },
  {
    title: 'Icon Assets',
    description: 'Collection of SVG icons',
    time: '20 March',
    icon: 'icons/duotune/art/art002.svg',
    state: 'warning',
  },
]
export interface LogModel {
  code: string
  state: 'success' | 'danger' | 'warning'
  message: string
  time: string
}

const defaultLogs: Array<LogModel> = [
  { code: '200 OK', state: 'success', message: 'New order', time: 'Just now' },
  { code: '500 ERR', state: 'danger', message: 'New customer', time: '2 hrs' },
  { code: '200 OK', state: 'success', message: 'Payment process', time: '5 hrs' },
  { code: '300 WRN', state: 'warning', message: 'Search query', time: '2 days' },
  { code: '200 OK', state: 'success', message: 'API connection', time: '1 week' },
  { code: '200 OK', state: 'success', message: 'Database restore', time: 'Mar 5' },
  { code: '300 WRN', state: 'warning', message: 'System update', time: 'May 15' },
  { code: '300 WRN', state: 'warning', message: 'Server OS update', time: 'Apr 3' },
  { code: '300 WRN', state: 'warning', message: 'API rollback', time: 'Jun 30' },
  { code: '500 ERR', state: 'danger', message: 'Refund process', time: 'Jul 10' },
  { code: '500 ERR', state: 'danger', message: 'Withdrawal process', time: 'Sep 10' },
  { code: '500 ERR', state: 'danger', message: 'Mail tasks', time: 'Dec 10' },
]

export interface NoteModel {
  id: string,
  uuid: string,
  text: string,
  createdAt: string,
  lastUpdated: string
}

export interface ImageModel {
  id: string,
  ownerId: string,
  photoURL: string,
  uploadedAt: string,
  reference: string,
  type: "public" | "private"
  size?: number,
  width?: number,
  height?: number
}

export interface ProfileImageModel {
  info: ImageModel,
  receivedBy?: ProfileImageReceiverModel[]
}

export interface UserImageModel {
  info: ImageModel
}

export interface ProfileImageReceiverModel {
  id: string,
  receiver_id: string,
  receivedAt: string
}

export class CursorNode {
  nodeId: string = ""
  visibleStart: QueryDocumentSnapshot<DocumentData> = null as any;
};

export class PaginatedUserModels {
  node: CursorNode = new CursorNode();
  nextNodeExists: boolean = false;
  userModels: UserModel[] = [];
}

export class PaginatedChatterUserHistoryModels {
  node: CursorNode = new CursorNode();
  nextNodeExists: boolean = false;
  chatterUserHistoryModels: ChatterUserHistoryModel[] = [];
}

export class PaginatedProfileModels {
  node: CursorNode = new CursorNode();
  nextNodeExists: boolean = false;
  profileModels: ProfileModel[] = [];
}

export class PaginatedFeedEntries {
  node: CursorNode = new CursorNode();
  nextNodeExists: boolean = false;
  feedEntries: FeedEntryModel[] = [];
}

export class PurchaseLogModel {
  discount: any
  product: any
  currency: any
  id: string = "";
  userId: string = "";
  lastProfileChatted: string = "";
  priceId: string = ""
  isDiscount: boolean = false;
  createdAt: any = null as any;
  hasMessages: boolean = true;
  discountArr: any[]
  productArr: any[]
  totalPurchase: number
  product_total_amount: number
  product_average: number
  formattedDate: any
  last_chatter: string
  success: boolean

  constructor(params: any) {
    this.id = params.id
    this.userId = params.userId;
    this.lastProfileChatted = params.lastProfileChatted;
    this.priceId = params.priceId;
    this.isDiscount = params.isDiscount;
    this.createdAt = params.createdAt;
    this.hasMessages = params.hasMessages;
    this.discountArr = []
    this.productArr = []
    this.totalPurchase = 0
    this.product_total_amount = 0;
    this.product_average = 0;
    this.formattedDate = params.formattedDate;
    this.last_chatter = params.last_chatter;
    this.success = params.success;
  }
}

export class PaginatedPurchaseLogs {
  node: CursorNode = new CursorNode();
  nextNodeExists: boolean = false;
  purchaseLogs: PurchaseLogModel[] = [];
}

export class InfoModel {
  displayName: string = "";
  photoURL: string = "";
  birthday: Date = new Date();
  age: number = 18;
  gender: string = "";
  weight: number = 0;
  height: number = 0;
  maritalStatus: string = "";
  bodyType: string = "";
  ethnicity: string = "";
  hairColor: string = "";
  eyeColor: string = "";
  sexualOrientation: string = "";
  country: string = "";
  language: string = "";
  aboutMe: string = "";
  lookingFor: string = "";
  tags: string[] = [];
  chattingStatus: string = '';
  myInterests: string[] = []
  sexualInterests: string[] = []
  createdAt: any = "";
  authProvider: string = "";
  lastActive: any = "";

  constructor(params: any) {
    this.displayName = params.displayName;
    this.photoURL = params.photoURL;
    this.birthday = params.birthday;
    this.age = params.age;
    this.gender = params.gender;
    this.weight = params.weight;
    this.height = params.height;
    this.maritalStatus = params.maritalStatus;
    this.bodyType = params.bodyType;
    this.ethnicity = params.ethnicity;
    this.hairColor = params.hairColor;
    this.eyeColor = params.eyeColor;
    this.sexualOrientation = params.sexualOrientation;
    this.country = params.country;
    this.language = params.language;
    this.aboutMe = params.aboutMe;
    this.lookingFor = params.lookingFor;
    this.tags = params.tags;
    this.myInterests = params.myInterests
    this.sexualInterests = params.sexualInterests
    this.createdAt = params.createdAt
    this.authProvider = params.authProvider;
    this.lastActive = params.lastActive;
  }
}

export { defaultAlerts, defaultLogs }
