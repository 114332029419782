import { Services } from "./Services";
export class CustomStyles
{
    static InterestBadge = {
        height: "25px",
        width: "fit-content",
        cursor: "pointer",
        borderRadius: "30px",
    };

    static PopupStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 350,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        py: 4,
        borderRadius: 3,
    }
}