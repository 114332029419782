import React from 'react'
import clsx from 'clsx'
import { useLocation } from 'react-router'
import { checkIsActive, KTSVG } from '../../../helpers'
import { useLayout } from '../../core'
import { Link } from 'react-router-dom'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
}

const AsideMenuItemWithSub: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
}) => {
  const { pathname } = useLocation()
  const isActive = checkIsActive(pathname, to)
  const { config } = useLayout()
  const { aside } = config

  return (
    <>
      <div
        className={clsx('menu-item', { 'here show': isActive }, 'menu-accordion')}
        data-kt-menu-trigger='click'
      >
        <span className='menu-link'>
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}
          {icon && aside.menuIcon === 'svg' && (
            <span className='menu-icon'>
              <KTSVG path={icon} className='svg-icon-2' />
            </span>
          )}
          {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
          {
            title.includes('-') ?
            <>
            <span className='font-400'>{title.split('-')[0]}</span>
            <span className='notification-red'>{title.split('-')[1]}</span>
            </> :
            <span className='menu-title'>{title.split('-')[0]}</span>
          }
          <span className='menu-arrow'></span>
        </span>
        <div className={clsx('menu-sub menu-sub-accordion', { 'menu-active-bg': isActive })}>
          {children}
        </div>
      </div>

      {/* <div className='menu-item'>
        <Link to="/profile-creation">
          <span className='menu-link'>
            {hasBullet && (
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
            )}
            {icon && aside.menuIcon === 'svg' && (
              <span className='menu-icon'>
                <KTSVG path={icon} className='svg-icon-2' />
              </span>
            )}
            {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
            <span className='menu-title'>Profile Creation</span>
          </span>
        </Link>
      </div>

      <div className='menu-item'>
        <Link to="/profile-push">
          <span className='menu-link'>
            {hasBullet && (
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
            )}
            {icon && aside.menuIcon === 'svg' && (
              <span className='menu-icon'>
                <KTSVG path={icon} className='svg-icon-2' />
              </span>
            )}
            {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
            <span className='menu-title'>Profile Push</span>
          </span>
        </Link>
      </div>

      <div className='menu-item'>
        <Link to="/profile-approval">
          <span className='menu-link'>
            {hasBullet && (
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
            )}
            {icon && aside.menuIcon === 'svg' && (
              <span className='menu-icon'>
                <KTSVG path={icon} className='svg-icon-2' />
              </span>
            )}
            {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
            <span className='menu-title'>Profile Approval</span>
          </span>
        </Link>
      </div> */}
    </>
  )
}

export { AsideMenuItemWithSub }
