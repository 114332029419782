import { collection, doc, getDoc, getDocs, getFirestore, orderBy, query, where } from "firebase/firestore";
import { Utils } from "./Utils";

export class ProductModel
{
    id: string = "";
    productName: string = "";
    priceId: string = "";
    priceIdList: string[] = [];
    priceList: Map<string, string> = null as any;
    priceValue: number = 0;
    value: number = 0;
    discountRef: string = "";
    testPriceId: string="";

    constructor(params: any)
    {
        this.productName = params.productName;
        this.priceId = params.priceId;
        this.priceValue = params.priceValue;
        this.value = params.value;
        this.discountRef = params.discountRef;
        this.testPriceId = params.testPriceId;
        this.priceIdList = params.priceIdList;
        this.priceList = params.priceList;
    }
}

export class DiscountModel
{
    id: string = "";
    priceId: string = "";
    priceIdList: string[] = [];
    priceList: Map<string, string> = null as any;
    description: string = "";
    name: string = "";
    value: number = 0;
    testPriceId: string="";
    priceValue: number = 0;
    
    constructor(params: any)
    {
        this.id = params.id;
        this.priceId = params.priceId
        this.description = params.description;
        this.name = params.name;
        this.value = params.value;
        this.testPriceId = params.testPriceId;
        this.priceValue = params.priceValue
        this.priceIdList = params.priceIdList;
        this.priceList = params.priceList;
    }
}

export class ProductType
{
    public static get PLANS(): string { return "plans"; }
    public static get UNLIMITED(): string { return "unlimited"; }
}

export interface AllProducts {
    productIds:string[],
    amount: number
}

export class Products
{
    public static async GetProductPlanByPriceId(priceId: string): Promise<ProductModel>
    {
        const firestore = getFirestore();
        const productsRef = collection(firestore, "products/plans/offers");
        const productsQuery = query(productsRef, where("price_id_list", "array-contains-any", priceId));

        let productModel: ProductModel = null as any;
        const productsSnap = await getDocs(productsQuery);
        if (productsSnap.size > 0)
        {
            //let data = productsSnap.docs[0].data();
            let doc = productsSnap.docs[0];
            let model = Utils.ParseDataToProductModel(doc);
            productModel = model;
        }

        return Promise.resolve(productModel);
    }

    public static async GetAllProducts(): Promise<any>
    {
        const products = await Products.GetAllProductsByType(ProductType.PLANS);
        const discounts = await Products.GetAllDiscounts();
        const AllProductWithDiscount:AllProducts[] = [...products,...discounts].map((product)=>{
            return {
                productIds : product.priceIdList,
                amount : product.priceValue,
                productList :  Array.from(product.priceList).map(([name, value]) => ({name, value}))
            }
        })
        return Promise.resolve(AllProductWithDiscount);
    }

    public static async GetAllProductsByType(type: ProductType): Promise<ProductModel[]>
    {
        const firestore = getFirestore();
        const productsRef = collection(firestore, `products/${type}/offers`);
        const productsQuery = query(productsRef, orderBy('value', 'asc'));

        let productModels: ProductModel[] = [];
        const productsSnap = await getDocs(productsQuery);
        productsSnap.forEach((doc) => {
            //let data = doc.data();
            let model = Utils.ParseDataToProductModel(doc);
            productModels.push(model);
        })

        return Promise.resolve(productModels);
    }

    //#region Discount Related
    public static async GetAllDiscounts(): Promise<DiscountModel[]> {
        const firestore = getFirestore();
        const discountsRef = collection(firestore, `products/discounts/offers`);

        let discountModels: DiscountModel[] = [];
        const discountsSnap = await getDocs(discountsRef);
        discountsSnap.forEach((doc) => {
            //let data = doc.data();
            let model = Utils.ParseDataToDiscountModel(doc.id, doc.data());
            discountModels.push(model);
        })

        return Promise.resolve(discountModels);
    }

    public static async GetDiscountById(discountId: string): Promise<DiscountModel>
    {
        const firestore = getFirestore();
        const discountsRef = collection(firestore, "products/discounts/offers");
        const discountDoc = doc(discountsRef, discountId);

        const discountSnap = await getDoc(discountDoc);
        const data = discountSnap.data();
        let discountModel: DiscountModel = null as any;
        if (data)
        {
            discountModel = Utils.ParseDataToDiscountModel(discountSnap.id, data);
            discountModel.id = discountSnap.id;
        }

        return Promise.resolve(discountModel);
    }
    //#endregion
}