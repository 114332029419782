import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Chat } from '../../../client/chat/Chat'
import { Products } from '../../../client/system/Products'
import * as _ from 'lodash';
import { SubTypes, User, UserModel, UserTypes } from '../../../client/user/User'
import { Utils } from '../../../client/system/Utils'
import MessageList from '../../pages/dashboard/_modals/MessageList'
import { EnvironmentType, FirebaseApp } from '../../../client/FirebaseApp'
import { ChatRoomModel, DetailedChatLogModel } from '../../../_metronic/helpers'
import HandOverPopup from '../HandOverPopUp/HandOverPopUp'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { CustomStyles } from '../../../client/system/CustomStyles'
import { Profile, ProfileModel } from '../../../client/user/Profile'
import { ReactReduxContext } from 'react-redux'

type Params = {
   uid : string
}

const style = {
   position: 'absolute' as 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   width: 350,
   bgcolor: 'background.paper',
   boxShadow: 24,
   p: 2,
   py: 4,
   borderRadius: 3,
};

const IndividualUserInformation = () => {
   const siteRef = FirebaseApp.environment === EnvironmentType.STAGING ? 'localhost' : 'flirtybum';
   const { uid } = useParams<Params>();
   const history = useHistory();

   const [userModel, setUserModel] = useState<UserModel>(null as any);
   const [allowGiveCredits, setAllowGiveCredits] = useState<boolean>(false);
   const [authProvider, setAuthProvider] = useState<string>("Loading...");
   const [sentMessages, setSentMessages] = useState<string>("Loading...");
   const [receivedMessages, setReceivedMessages] = useState<string>("Loading...");
   const [sentMessagesByTime, setSentMessagesByTime] = useState<string>("Loading...");
   const [sessionTime, setSessionTime] = useState<string>("Loading...");
   const [lastActive, setLastActive] = useState<string>("Loading...");
   const [userPurchaseData, setUserPurchaseData] = useState<any>([]);
   const [allPurchase, setAllPurchase] = useState(false);
   const [filteredPurchaseData, setfilteredPurchaseData] = useState<any>([]);
   const [lifeTimeValue, setLifeTimeValue] = useState<string>();
   const [totalEarnings, setTotalEarnings] = useState<string>("Loading...");
   const [timeGap, setTimeGap] = useState('');
   const [isNoDataForPurchase, setIsNoDataForPurchase] = useState(false);
   const [isNoDataForChat, setIsNoDataForChat] = useState(false);
   const [showModal, setShowModal] = useState(false);
   const [roomId, setRoomId] = useState('');
   const [creditsToAdd, setCreditsToAdd] = useState<number>(0);

   //const [lastMessagesData, setLastMessagesData] = useState<DetailedChatLogModel[]>([]);
   const [chatterModels, setChatterModels] = useState<UserModel[]>([]);
   const [profileModels, setProfileModels] = useState<ProfileModel[]>([]);
   const [chatroomModels, setChatroomModels] = useState<ChatRoomModel[]>([]);
   // const [filteredData, setFilteredData] = useState('');

   // Popup Variables
   const [open, setOpen] = React.useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);

   const [popupTitle, setPopupTitle] = useState<string>("");
   const [popupMessage, setPopupMessage] = useState<string>("");
   const [hasChoices, setHasChoices] = useState<boolean>(false);
   const [confirmText, setConfirmText] = useState<string>("");
   const [cancelText, setCancelText] = useState<string>("");

   const getChatterModel = (chatterId: string) => {
      return chatterModels.find((x) => { return x.uuid === chatterId; });
   };

   const getProfileModel = (profileId: string) => {
      return profileModels.find((x) => { return x.id === profileId; })
   };
 
   const credits:any = {
      '2.99': 70,
      '14.99': 70,
      '34.99': 175,
      '64.99': 399,
      '119.99': 700,
      '199.99': 1400,
    }

    useEffect(() => {
      if(userPurchaseData){
         let purchaseData = userPurchaseData.filter((item:any,idx:number) => idx < 5)
         setfilteredPurchaseData(purchaseData)
      }
    },[userPurchaseData])
   
  const getIndividualPurchaseLogs = async (userId:string, user:UserModel) => {
      const purchases:any = await Chat.RetrievePurchaseLogsByUserId(userId, null as any, null as any, user?.siteOfOrigin);
      console.log(purchases, 'purchases');
      const products = await Products.GetAllProducts();
      const purchaseWithAmount = purchases.map((purchaseLog:any)=>{
         const findAmount = products.find((product:any)=>product.productIds?.includes(purchaseLog.priceId));
         const localCurrency = findAmount?.productList.find((data:any)=>data.value === purchaseLog.priceId)
         return {
            ...purchaseLog,
            time: moment(purchaseLog.formattedDate).format('YYYY-MM-DD HH:mm:ss'),
            amount : findAmount?.amount,
            payment_gateway : 'Stripe',
            localCurrency : localCurrency?.name,
            credits : credits[findAmount?.amount.toString()]
         }
      })
      let totalAmount:number = _.sumBy(purchaseWithAmount,(purchaseDetail:any)=>purchaseDetail.amount); 
      setUserPurchaseData(purchaseWithAmount);
      !purchaseWithAmount.length && setIsNoDataForPurchase(true);
      setLifeTimeValue(totalAmount.toFixed(2));
      const filteredData:any = userPurchaseData.filter((data:any)=> 
      moment().startOf('D').toDate() <= data.formattedDate && data.formattedDate <= moment().endOf('D').toDate());
      const totalEarnings:number = _.sumBy(filteredData, (purchase:any)=> purchase.amount);
      setTotalEarnings(totalEarnings.toFixed(2));
  }

  const openPopup = (title: string, message: string, hasChoices: boolean, confirmText: string, cancelText: string) => {
      setPopupTitle(title);
      setPopupMessage(message);
      setHasChoices(hasChoices);
      setConfirmText(confirmText);
      setCancelText(cancelText);
      handleOpen();
   }

  const addCreditsToUser = async () => {
      await User.AddUserCredits(userModel?.uuid, creditsToAdd).then((credits) => {
         openPopup("Notice", "Successfully Added Credits!", false, "Okay", "Okay");
         userModel.credits = credits;
         setCreditsToAdd(0);
      }).catch(() => {
         openPopup("Notice", "Failed to Add Credits", false, "Okay", "Okay");
      });
   }

  const handleMessagesTimeChange = (time:string) =>{
   setSentMessagesByTime("Loading...");
   let startDate:any;
   let endDate:any;
   if(time==="1d"){
      startDate= moment().startOf('D').toDate();
      endDate= moment().endOf('D').toDate();
   }
   else if(time==="1w"){
      startDate= moment().subtract(1, 'week').startOf('week').toDate();
      endDate= moment().subtract(1, 'week').endOf('week').toDate();
   }
   else if(time==="1m"){
      startDate = moment().subtract(1, 'month').startOf('month').toDate();
      endDate = moment().subtract(1, 'month').endOf('month').toDate();
   }
   else {
      startDate = moment().subtract(3, 'month').startOf('month').toDate();
      endDate = moment().subtract(1, 'month').endOf('month').toDate();
   }

   const startD: Date = startDate as Date;
   const endD: Date = endDate as Date;

   const start = `${startD.getFullYear()}${("0" + (startD.getMonth() + 1)).slice(-2)}${("0" + (startD.getDate())).slice(-2)}`;
   const end = `${endD.getFullYear()}${("0" + (endD.getMonth() + 1)).slice(-2)}${("0" + (endD.getDate())).slice(-2)}`;

   User.GetMessagesCountByTime(uid, start, end).then((count) => {
      setSentMessagesByTime(count.toString());
   });
  }

  const handleTimeChange = (time:string) =>{
   setTotalEarnings("Loading...")
   setTimeGap(time);
   let startDate:any;
   let endDate:any;
   if(time==="1d"){
      startDate= moment().startOf('D').toDate();
      endDate= moment().endOf('D').toDate();
   }
   else if(time==="1w"){
      startDate= moment().subtract(1, 'week').startOf('week').toDate();
      endDate= moment().subtract(1, 'week').endOf('week').toDate();
   }
   else if(time==="1m"){
      startDate = moment().subtract(1, 'month').startOf('month').toDate();
      endDate = moment().subtract(1, 'month').endOf('month').toDate();
   }
   else {
      startDate = moment().subtract(3, 'month').startOf('month').toDate();
      endDate = moment().subtract(1, 'month').endOf('month').toDate();
   }
   
   const filteredData:any = userPurchaseData.filter((data:any)=>startDate <= data.formattedDate && data.formattedDate <= endDate);

   const totalEarnings:number = _.sumBy(filteredData, (purchase:any)=> purchase.amount);
   setTotalEarnings(totalEarnings.toFixed(2));
  }

  const viewChat = (site: string, profileId: string, userId: string) => {
      history.push(`${Chat.CHAT_CHATTER_ROOM}/${site}/${profileId}/${userId}`);
  };

   useEffect(()=>{
      if(uid){
         // getIndividualPurchaseLogs(uid);
         if (User.Model?.userType === UserTypes.TYPE_CHATTER)
         {
            if (User.Model?.subType === SubTypes.SUB_TYPE_CHATTER_MANAGER)
               setAllowGiveCredits(true);
         } else 
         {
            setAllowGiveCredits(true);
         }
         
         User.GetUserAccount(uid).then((user) => {
            getIndividualPurchaseLogs(uid, user);
            setUserModel(user);

            // Get Latest Chat Interactons
            // Chat.GetLastChatInteractions(uid, user.siteOfOrigin, 5, false).then((chatLogs) => {
            //    !chatLogs?.length && setIsNoDataForChat(true);
            //    if (chatLogs)
            //    {
            //       setLastMessagesData(chatLogs);
            //       const chatterIds: string[] = [];
            //       chatLogs.forEach((log: DetailedChatLogModel) => {
            //          chatterIds.push(log.chatter_id);
            //       })
            //       User.GetUsersByIds(chatterIds).then((chatterModels) => {
            //          setChatterModels(chatterModels);
            //       });
            //    }
            // });

            // Get Latest Chat Rooms
            Chat.GetLatestChatRooms(user.siteOfOrigin, user.uuid).then((chatrooms) => {
               if (chatrooms)
               {
                  setChatroomModels(chatrooms.reverse());
                  const profileIds: string[] = [];
                  chatrooms.forEach((room: ChatRoomModel) => {
                     console.log(`Room Id: ${room.id} | Profile Id: ${room.profileId}`);
                     profileIds.push(room.profileId);
                  });
                  
                  Profile.GetProfilesByIds(profileIds).then((models) => {
                     setProfileModels(models);
                  });
               }
            });

            if (user.onlineStatus)
            {
               setLastActive("Online");
            } else 
            {
               let lastActiveTime = "Offline";
               if (user.lastActive)
                  lastActiveTime = moment(user.lastActive.toDate()).format("MM/DD/YYYY - HH:mm a").toString();

               setLastActive(lastActiveTime);
            }

            if (!user.authProvider)
            {
               console.log(`Provider Does not Exist`)
               User.GetUserProviderId(uid).then((provider) => {
                  User.RegisterAuthProvider(uid, provider);
                  setAuthProvider(Utils.DetermineAuth(provider));
               })
            } else 
            {
               console.log(`Provider exists`);
               setAuthProvider(user.authProvider);
            }
            if (!user.clientId)
            {
               setSessionTime("N/A");
            } else 
            {
               User.GetUserEngagementByUserId(uid).then((time) => {                  
                  setSessionTime(Utils.MsToTime(time))
               })
            }
         })
         
         User.GetMessagesCountByUser(uid).then((counts) => {
            if (counts)
            {
               setSentMessages(counts.sent.toString());
               setReceivedMessages(counts.received.toString());
            }
         });

         // handleTimeChange("1d");
         // handleMessagesTimeChange("1d");
      }

   },[])

   return (
      <>
         <div className='container-fluid'>
            <div className='row gy-4'>
               <div className='col-lg-12'>
                  <div className='card'>
                     <div className='card-body p-4'>
                        <div className='userInformation-block'>
                           <div className='row gy-3'>
                              <div className='col-lg-12'>
                                 <div className='userThumbnail'>
                                    <img src={userModel?.photoURL} alt="" />
                                 </div>
                              </div>

                              <div className='col-lg-6 col-md-6 col-12'>
                                 <div className='card'>
                                    <div className='card-body p-3'>
                                       <h5 className='detailsTitle'>User Information</h5>
                                       <div className='userInformation-dataList'>
                                          <div className='userInformation-grid'>
                                             <h6>User ID</h6>
                                             <p>{userModel?.uuid}</p>
                                          </div>

                                          <div className='userInformation-grid'>
                                             <h6>Username</h6>
                                             <p>{userModel?.displayName}</p>
                                          </div>

                                          <div className='userInformation-grid'>
                                             <h6>Country</h6>
                                             <p>{userModel?.geolocation?.get("countryName")}</p>
                                          </div>

                                          {/* <div className='userInformation-grid'>
                                             <h6>Type of user</h6>
                                             <p>{userModel?.userType.toUpperCase()}</p>
                                          </div> */}

                                          <div className='userInformation-grid'>
                                             <h6>Age</h6>
                                             <p>{userModel?.age}</p>
                                          </div>

                                          <div className='userInformation-grid'>
                                             <h6>Birthday</h6>
                                             <p>{moment(userModel?.birthday).format("MM/DD/YYYY").toString()}</p>
                                          </div>

                                          <div className='userInformation-grid'>
                                             <h6>Email</h6>
                                             <p>{userModel?.email}</p>
                                          </div>

                                          <div className='userInformation-grid'>
                                             <h6>Credits</h6>
                                             <p>{userModel?.credits}</p>
                                          </div>


                                          <div className='userInformation-grid'>
                                             <h6>About me</h6>
                                             <p>{userModel?.aboutMe}</p>
                                          </div>

                                          <div className='userInformation-grid'>
                                             <h6>Looking for</h6>
                                             <p>{userModel?.lookingFor}</p>
                                          </div>

                                          <div className='userInformation-grid'>
                                             <h6>Site signed up for</h6>
                                             <p>{userModel?.siteOfOrigin}</p>
                                          </div>

                                          <div className='userInformation-grid'>
                                             <h6>Landing page signed up on</h6>
                                             <p>{userModel?.pageLocation}</p>
                                          </div>

                                          <div className='userInformation-grid'>
                                             <h6>Signup date</h6>
                                             <p>{moment(userModel?.createdAt.toDate()).format("MM/DD/YYYY - HH:mm a").toString()} UTC +7</p>
                                          </div>

                                          <div className='userInformation-grid'>
                                             <h6>Firebase auth</h6>
                                             <p>{authProvider}</p>
                                          </div>

                                          <div className='userInformation-grid'>
                                             <h6>Is Auto Generated</h6>
                                             <p>{userModel?.isAutoGenerated ? "Yes" : "No"}</p>
                                          </div>

                                          <div className='userInformation-grid'>
                                             <h6>Lifetime value</h6>
                                             <p>{lifeTimeValue}</p>
                                          </div>

                                          <div className='userInformation-grid'>
                                             <h6>Marketing channel origin</h6>
                                             <p>Email</p>
                                          </div>

                                          {
                                             (allowGiveCredits) && (
                                             <div className='userInformation-grid'>
                                                <div className="form-group my-7">
                                                   <label className='mb-2'>Enter the amount of credits to be added</label>
                                                   <div className="input-group">
                                                         <input type="number" min="0" className="form-control" value={creditsToAdd} placeholder="Credit Count" onChange={(e) => {
                                                         setCreditsToAdd(parseInt(e.target.value));
                                                         }} />
                                                         <div className="input-group-append">
                                                            <button className="btn btn-secondary customrounds" type="button" onClick={addCreditsToUser}>Add Credits</button>
                                                         </div>
                                                   </div>
                                                </div>
                                             </div>)
                                          }
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <div className='col-lg-6 col-md-6 col-12'>
                                 <div className='card'>
                                    <div className='card-body p-3'>
                                       <h5 className='detailsTitle'>Site Statistics</h5>
                                       <div className='userInformation-dataList'>
                                          <div className='userInformation-grid'>
                                             <h6>Last active</h6>
                                             <p>{lastActive}</p>
                                          </div>

                                          <div className='userInformation-grid'>
                                             <h6>Total session engagement time</h6>
                                             <p>{sessionTime}</p>
                                          </div>

                                          <div className='userInformation-grid'>
                                             <h6>Messages sent</h6>
                                             <p>{userModel?.message_sent}</p>
                                          </div>

                                          <div className='userInformation-grid'>
                                             <h6>Messages received</h6>
                                             <p>{userModel?.message_received}</p>
                                          </div>

                                          <div className='userInformation-grid'>
                                             <div className='selectFilter'>
                                                <h6>Total messages</h6>
                                                {/* <select className="form-select" aria-label="Default select example" onChange={(e) => handleMessagesTimeChange(e.target.value)}>
                                                   <option selected value="1d">Today</option>
                                                   <option value="1w">Last Week</option>
                                                   <option value="1m">Last Month</option>
                                                   <option value="3m">Last 3 Months</option>
                                                </select> */}
                                             </div>
                                                <p>{userModel?.message_sent + userModel?.message_received}</p>
                                          </div>

                                          <div className='userInformation-grid'>
                                             <h6>Total time online</h6>
                                             <p>{Utils.MsToTime(userModel?.totalTimeOnline * 1000)}</p>
                                          </div>

                                          <div className='userInformation-grid'>
                                             <div className='selectFilter'>
                                                <h6>Total deposited</h6>
                                                {/* <select className="form-select" aria-label="Default select example" onChange={(e)=>handleTimeChange(e.target.value)}>
                                                   <option selected value="1d">Today</option>
                                                   <option value="1w">Last Week</option>
                                                   <option value="1m">Last Month</option>
                                                   <option value="3m">Last 3 Months</option>
                                                </select> */}
                                             </div>
                                             <p>{totalEarnings} EUR</p>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div className='col-lg-12'>
                  <div className='card'>
                     <div className='card-body p-4'>
                        <div className='userInformation-table'>
                           <h5 className='detailsTitle'>Purchase Statistics</h5>
                           <div className='table-responsive'>
                              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                 <thead>
                                    <tr>
                                       <th className='min-w-150px'>Time</th>
                                       <th className='min-w-150px'>Amount in EUR</th>
                                       <th className='min-w-150px'>Local Currency</th>
                                       <th className='min-w-150px'>Credits Gained</th>
                                       <th className='min-w-150px'>Payment Gateway</th>
                                    </tr>
                                 </thead>

                                 <tbody>
                                    {
                                       isNoDataForPurchase ? 
                                       <tr>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                             </tr>
                                       :
                                       allPurchase ? userPurchaseData?.map((purchase:any, index:number)=>{
                                          return (
                                             <tr key={index}>
                                                <td>{purchase.time}</td>
                                                <td>{purchase.amount}</td>
                                                <td>{purchase.localCurrency}</td>
                                                <td>{purchase.credits}</td>
                                                <td>{purchase.payment_gateway}</td>
                                             </tr>
                                          )
                                       }) :
                                       filteredPurchaseData?.map((purchase:any, index:number)=>{
                                          return (
                                             <tr key={index}>
                                                <td>{purchase.time}</td>
                                                <td>{purchase.amount}</td>
                                                <td>{purchase.localCurrency}</td>
                                                <td>{purchase.credits}</td>
                                                <td>{purchase.payment_gateway}</td>
                                             </tr>
                                          )
                                       })
                                    }
                                 </tbody>
                              </table>
                           </div>
                           <div className='btns-group mt-3'>
                              <button className='btn btn-primary' type='button' onClick={()=> setAllPurchase(true)}>All Purchase</button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div className='col-lg-12'>
                  <div className='card'>
                     <div className='card-body p-4'>
                        <div className='userInformation-table'>
                           <h5 className='detailsTitle'>Latest Chat Interactions</h5>
                           <div className='table-responsive'>
                              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                 <thead>
                                    <tr>
                                       <th className='min-w-150px'>Profile</th>
                                       <th className='min-w-150px'>Last Updated</th>
                                       <th className='min-w-150px'>Read Status</th>
                                       {/* <th className='min-w-150px'>Time for Chatter to Respond (in Minutes)</th> */}
                                       <th className='min-w-150px'>View Chat</th>
                                    </tr>
                                 </thead>

                                 <tbody>
                                    {
                                       isNoDataForChat ? 
                                          <tr>
                                             <td>-</td>
                                             <td>-</td>
                                             <td>-</td>
                                             {/* <td>-</td> */}
                                             <td>-</td>
                                          </tr>
                                       :
                                          chatroomModels?.map((room: ChatRoomModel) => {
                                             const profile: ProfileModel | undefined = getProfileModel(room.profileId);

                                             return (
                                                <tr key={`chat-interaction@${Utils.GenerateRandomID()}`}>
                                                   <td>{profile ? profile?.displayName : "-"}</td>
                                                   <td>{moment(room.lastUpdated.toDate()).format("MM/DD/YYYY - HH:mm a").toString()}</td>
                                                   <td>{room.readStatus}</td>
                                                   <td>
                                                      <button className='btn btn-dark btn-sm' onClick={() => { viewChat(userModel?.siteOfOrigin, room.profileId, userModel?.uuid) }}>View</button>
                                                   </td>
                                                </tr>
                                             )
                                          })
                                       // lastMessagesData?.map((log:DetailedChatLogModel, index:number)=>{
                                       //    const chatter: UserModel | undefined = getChatterModel(log.chatter_id);
                                       //    console.log(log.timeToRespond);
                                       //    var timeInMinutes = Math.floor(log.timeToRespond / 60);
                                       //    return (
                                       //       <tr key={`chat-interaction@${Utils.GenerateRandomID()}`}>
                                       //          <td>{chatter ? chatter?.displayName : "-"}</td>
                                       //          <td>{log.messageType === "text" ? log.message : "Chatter sent an image"}</td>
                                       //          <td>{moment(log.last_updated.toDate()).format("MM/DD/YYYY - HH:mm a").toString()}</td>
                                       //          <td>{timeInMinutes !== -1 ? timeInMinutes : "Chatter is initial message"}</td>
                                       //          <td>
                                       //          <button className='btn btn-dark btn-sm' onClick={()=>{  setShowModal(true); setRoomId(log?.room_id)}}>Load More</button>
                                       //          </td>
                                       //          {/* <td>View More</td> */}
                                       //       </tr>
                                       //    )
                                       // })
                                    }
                                 </tbody>
                              </table>
                              {/* Here just need pass the roomId so it will open the modal and display the messages of particular room */}
                              {showModal && <MessageList show={showModal} onHide={() => setShowModal(false)} room={roomId}/>}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={CustomStyles.PopupStyle}>
                        <HandOverPopup title={popupTitle} message={popupMessage} hasChoice={hasChoices} confirmText={confirmText} cancelText={cancelText} onConfirm={() => {
                            handleClose();
                        }} onCancel={() => {
                            handleClose();
                        }} />
                    </Box>
                </Fade>
            </Modal>
      </>
   )
}

export default IndividualUserInformation