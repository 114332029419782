import { CircularProgress } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker';
// import DataTable, { TableColumn } from 'react-data-table-component';
import DataTable, {IDataTableColumn}  from 'react-data-table-component-footer';
import { HttpsActionNames, HttpsHandler } from '../../../client/system/HttpsHandler';
import { User, UserTypes } from '../../../client/user/User';
import purchaseData from './PurchaseData.json';
import { useQuery, useQueryClient, } from '@tanstack/react-query'
import { start } from '@popperjs/core';
import { Utils } from '../../../client/system/Utils';

type Props = {
  tagName?: string
}

const LoadingComponent:React.FC<Props> = ({tagName}) => {
  return(
    <div className='loading-table'>
    <h4>{`Loading ${tagName} Data.....`}</h4>
    <div>
      <CircularProgress />
      </div>
  </div>
  )
}

interface DataRowForReport {
  EventDate: string,
  StandardTotal: number,
  AutoGenTotal: number,
  SingleOptInTotal: number,
  DoubleOptInTotal: number,
  RevenueTotal: number,
  TotalTransactions: number,
  UniquePurchasers: number,
  Ftd: number,
  UserMessages: number,
  ChatterMessages: number
}

interface DataRowForReportTotal {
  Label: string,
  StandardTotal: number,
  AutoGenTotal: number,
  SingleOptInTotal: number,
  DoubleOptInTotal: number,
  RevenueTotal: number,
  TotalTransactions: number,
  UniquePurchasers: number,
  Ftd: number,
  UserMessages: number,
  ChatterMessages: number
}

interface DataRowForSignUp {
  Label: string,
  TotalUsers: number,
  AutoGenUsers: number,
  StandardUsers: number,
  DoubleOptInUsers: number
}

interface DataRowForPurchase {
  Total: number,
  TotalTransactions: number,
  UniquePurchasers: number,
  Ftd: number
}

interface DataRowForRevenue {
  Country: string,
  TotalRevenue: string,
  FTD_Revenue: string,
  FTD_Num_Users: string,
  FTD_Name_Users: string,
  Returning_Num_Users:string,
  Returning_Name_Users:string,
  Amount_order:string,
}

interface DataRowForChatterStats {
  Chatter: string,
  Messages_Sent: string,
  Response_Rate: string,
  Response_Percentage: string,
  // Time_To_Respond: string,
  Earnings:string,
}

interface countryWiseRevenue {
  country: string, 
  revenue: number
}

interface Revenue{
  totalRevenue: number,
  countryWiseRevenue: countryWiseRevenue[]
}

const columnsForReport: IDataTableColumn<DataRowForReport>[] = [
  {
      name: 'Date',
      selector: row => row.EventDate,
      sortable: true
  },
  {
      name: 'Standard Users',
      selector: row => row.StandardTotal,
      sortable: true
  },
  {
      name: 'Auto-Generated Users',
      selector: row => row.AutoGenTotal,
      sortable: true
  },
  {
      name: 'Single Opt-In Users',
      selector: row => row.SingleOptInTotal,
      sortable: true
  },
  {
      name: 'Double Opt-In Users',
      selector: row => row.DoubleOptInTotal,
      sortable: true
  },
  {
      name: 'Revenue',
      selector: row => row.RevenueTotal,
      sortable: true
  },
  {
      name: 'Total Transactions',
      selector: row => row.TotalTransactions,
      sortable: true
  },
  {
      name: 'Unique Purchasers',
      selector: row => row.UniquePurchasers,
      sortable: true
  },
  {
      name: 'First Time Depositors (FTD)',
      selector: row => row.Ftd,
      sortable: true
  },
  {
      name: 'User to Chatter Messages',
      selector: row => row.UserMessages,
      sortable: true
  },
  {
      name: 'Chatter to User Messages',
      selector: row => row.ChatterMessages,
      sortable: true
  }
];

const columnsForReportTotal: IDataTableColumn<DataRowForReportTotal>[] = [
  {
      name: 'Label',
      selector: row => "Total",
      sortable: true
  },
  {
      name: 'Standard Users',
      selector: row => row.StandardTotal,
      sortable: true
  },
  {
      name: 'Auto-Generated Users',
      selector: row => row.AutoGenTotal,
      sortable: true
  },
  {
      name: 'Single Opt-In Users',
      selector: row => row.SingleOptInTotal,
      sortable: true
  },
  {
      name: 'Double Opt-In Users',
      selector: row => row.DoubleOptInTotal,
      sortable: true
  },
  {
      name: 'Revenue',
      selector: row => row.RevenueTotal,
      sortable: true
  },
  {
      name: 'Total Transactions',
      selector: row => row.TotalTransactions,
      sortable: true
  },
  {
      name: 'Unique Purchasers',
      selector: row => row.UniquePurchasers,
      sortable: true
  },
  {
      name: 'First Time Depositors (FTD)',
      selector: row => row.Ftd,
      sortable: true
  },
  {
      name: 'User to Chatter Messages',
      selector: row => row.UserMessages,
      sortable: true
  },
  {
      name: 'Chatter to User Messages',
      selector: row => row.ChatterMessages,
      sortable: true
  }
];

const columnsForSignUp: IDataTableColumn<DataRowForSignUp>[] = [
  {
      name: 'Label',
      selector: row => row.Label,
      sortable: true
  },
  {
      name: 'Total Users',
      selector: row => row.TotalUsers,
      sortable: true
  },
  {
      name: 'Auto-Generated Users',
      selector: row => row.AutoGenUsers,
      sortable: true
  },
  {
      name: 'Single Opt-In Users',
      selector: row => row.StandardUsers,
      sortable: true
  },
  {
      name: 'Double Opt-In Users',
      selector: row => row.DoubleOptInUsers,
      sortable: true
  },
];

const columnsForPurchase: IDataTableColumn<DataRowForPurchase>[] = [
  {
      name: 'Total Revenue',
      selector: row => row.Total,
      sortable: true
  },
  {
      name: 'Total Transactions',
      selector: row => row.TotalTransactions,
      sortable: true
  },
  {
      name: 'Unique Purchasers',
      selector: row => row.UniquePurchasers,
      sortable: true
  },
  {
      name: 'First Time Depositors (FTD)',
      selector: row => row.Ftd,
      sortable: true
  }
];

const columnsForChatterStats: IDataTableColumn<DataRowForChatterStats>[] = [
  {
      name: 'Chatter',
      selector: row => row.Chatter,
      sortable: true
  },
  {
      name: 'Messages Sent',
      selector: row => row.Messages_Sent,
      sortable: true
  },
  {
      name: 'Replies',
      selector: row => row.Response_Rate,
      sortable: true
  },
  {
      name: 'Response %',
      selector: row => row.Response_Percentage,
      sortable: true
  },
  // {
  //     name: 'Time To Respond',
  //     selector: row => row.Time_To_Respond,
  //     sortable: true
  // },
  {
    name: 'Earnings',
    selector: row => row.Earnings,
    sortable: true
  }
];

const columnsForRevenue: IDataTableColumn<DataRowForRevenue>[] = [
  {
      name: 'Country',
      selector: row => row.Country,
      sortable: true
  },
  {
      name: 'FTD Revenue',
      selector: row => row.FTD_Revenue,
      sortable: true
  },
  {
      name: 'FTD Num Users',
      selector: row => row.FTD_Num_Users,
      sortable: true
  },
  {
      name: 'FTD Name Users',
      selector: row => row.FTD_Name_Users,
      sortable: true
  },
  {
    name: 'Returning Num Users',
    selector: row => row.Returning_Num_Users,
    sortable: true
  },
  {
    name: 'Returning Name Users',
    selector: row => row.Returning_Name_Users,
    sortable: true
  },
  {
    name: 'Order Amount',
    selector: row => row.Amount_order,
    sortable: true
  },
  {
    name: 'Total Revenue',
    selector: row => row.TotalRevenue,
    sortable: true
  }
];

const GeneralAnalytics = () => {
  // const [time, setTime] = useState<number>(-1);
  const [isDatePickerTouched, setIsDatePickerTouched] = useState(false);
  const [rowDataForSignUp, setRowDataForSignUp] = useState<DataRowForSignUp[]>([])
  const [rowDataForPurchase, setRowDataForPurchase] = useState<DataRowForPurchase[]>([])
  const [rowDataForReport, setRowDataForReport] = useState<DataRowForReport[]>([]);
  const [rowDataForReportTotal, setRowDataForReportTotal] = useState<DataRowForReportTotal[]>([]);
  // const [rowDataForChatterStats, setRowDataForChatterStats] = useState<DataRowForChatterStats[]>([])
  // const [rowDataForRevenue, setRowDataForRevenue] = useState<DataRowForRevenue[]>([])
  // const [LastRow, setLastRow] = useState<any>();
  const [chatters, setChatters] = useState<any>([]);
  // const [isLoadingRevenue, setIsLoadingRevenue] = useState(true);
  const [isLoadingSignup, setIsLoadingSignup] = useState(true);
  const [isLoadingPurchase, setIsLoadingPurchase] = useState(true);
  const [isLoadingReport, setIsLoadingReport] = useState(true);
  const [isLoadingReportTotal, setIsLoadingReportTotal] = useState(true);
  // const [isLoadingChatterStats, setIsLoadingChatterStats] = useState(true);

  // Date Variables
  const [startingDate, setStartingDate] = useState<any>(moment().startOf('month').toDate());
  const [endingDate, setEndingDate] = useState<Date>(new Date()); 

  const currencyWithCountry:any = {
    aud: 'Australia',
    gbp: 'United Kingdom',
    cad: 'Canada',
    usd: 'Vietnam'
  }

  const changeDateRange = (event: any, picker: any) => {
    setStartingDate(new Date(picker.startDate));
    setEndingDate(new Date(picker.endDate));
    // setIsDatePickerTouched(true);
    // setState({ startDate:moment(picker.startDate), endDate: moment(picker.endDate) });
    // setLabel(moment(picker.startDate).format('DD/MM/YYYY') + ' - ' + moment(picker.endDate).format('DD/MM/YYYY'))
  }

  // const getFilteredData = (startDate?:any, endDate?:any) =>{
  //   const filterByDate = _.filter(purchaseData, (data)=> {
  //     let currDate = moment(new Date(data['Created (UTC)'])).format('YYYY-MM-DD');
  //     return new Date(currDate) >= new Date(startDate) && new Date(currDate) <= new Date(endDate)
  //   })

  //   const countryWithRev = _.map(filterByDate, (data)=>{
  //     return {
  //       country: currencyWithCountry[data.Currency],
  //       revenue:data.Amount
  //     }
  //   })

  //   const groupedDataByCountry = _.groupBy(countryWithRev, (data)=>data.country);
  //   const data:any = _.map(Object.keys(groupedDataByCountry), (country) => {
  //     return {
  //       country: country,
  //       revenue: _.sumBy(groupedDataByCountry[country], (data)=>data.revenue),
  //       numberOfOrder: groupedDataByCountry[country].length
  //     }
  //   });

  //   const totalData = _.sumBy(data, (val:any)=>val.revenue)
  //   return {
  //     countryWiseRevenue: data,
  //     totalRevenue: totalData
  //   }
  // }

const getReport = useCallback((startDate: Date, endDate: Date) => {
    let start = "";
    let end = "";
    console.log()
    console.log(`startingDate: ${startingDate} | endingDate: ${endingDate}`)
    if (startDate && endDate) 
    {
        start = Utils.yyyymmdd(startDate);
        end = Utils.yyyymmdd(endDate);
    }

    console.log(`Start Date: ${start} | End Date: ${end}`);
    setIsLoadingReport(true);
    setIsLoadingReportTotal(true);
    let url = `${HttpsHandler.BASE_URL}/${HttpsActionNames.GET_DAILY_REPORT}?startDate=${start}&endDate=${end}`;
    let rowsForReport: DataRowForReport[] = [];
    let rowsForReportTotal: DataRowForReportTotal[] = [];

    let rowReportTotal: DataRowForReportTotal = {
      Label: "Total",
      StandardTotal: 0,
      AutoGenTotal: 0,
      SingleOptInTotal: 0,
      DoubleOptInTotal: 0,
      RevenueTotal: 0,
      TotalTransactions: 0,
      UniquePurchasers: 0,
      Ftd: 0,
      UserMessages: 0,
      ChatterMessages: 0
    };

    rowsForReportTotal.push(rowReportTotal);

    HttpsHandler.SendGetRequest(url, false, (success, data, message) => {
        if (success) {
          for (let i = 0; i < data.length; i ++)
          {
            let rowReport: DataRowForReport = {
              EventDate: "",
              StandardTotal: 0,
              AutoGenTotal: 0,
              SingleOptInTotal: 0,
              DoubleOptInTotal: 0,
              RevenueTotal: 0,
              TotalTransactions: 0,
              UniquePurchasers: 0,
              Ftd: 0,
              UserMessages: 0,
              ChatterMessages: 0
            };
        
            rowReport.EventDate = Utils.ConvertYYYYMMDDDate(data[i].EventDate);
            rowReport.StandardTotal = data[i].StandardTotal;
            rowReport.AutoGenTotal = data[i].AutoGenTotal;
            rowReport.SingleOptInTotal = data[i].SingleOptInTotal;
            rowReport.DoubleOptInTotal = data[i].DoubleOptInTotal;
            rowReport.RevenueTotal = data[i].RevenueTotal;
            rowReport.TotalTransactions = data[i].TotalTransactions;
            rowReport.UniquePurchasers = data[i].UniquePurchasers;
            rowReport.Ftd = data[i].Ftd;
            rowReport.UserMessages = data[i].UserMessages;
            rowReport.ChatterMessages = data[i].ChatterMessages;

            rowReportTotal.StandardTotal += rowReport.StandardTotal;
            rowReportTotal.AutoGenTotal += rowReport.AutoGenTotal;
            rowReportTotal.SingleOptInTotal += rowReport.SingleOptInTotal;
            rowReportTotal.DoubleOptInTotal += rowReport.DoubleOptInTotal;
            rowReportTotal.RevenueTotal += rowReport.RevenueTotal;
            rowReportTotal.TotalTransactions += rowReport.TotalTransactions;
            rowReportTotal.UniquePurchasers += rowReport.UniquePurchasers;
            rowReportTotal.Ftd += rowReport.Ftd;
            rowReportTotal.UserMessages += rowReport.UserMessages;
            rowReportTotal.ChatterMessages += rowReport.ChatterMessages;

            rowsForReport.push(rowReport);
          }
        }
        setRowDataForReport(rowsForReport);
        setRowDataForReportTotal(rowsForReportTotal);
        setIsLoadingReport(false);
        setIsLoadingReportTotal(false);
    }, (success, message) => {
      setIsLoadingReport(false);
      setIsLoadingReportTotal(false);
    });
}, [startingDate, endingDate]);

  const getSignUpAnalytics = useCallback((startDate: Date, endDate: Date) => {
    let start = "";
    let end = "";
    console.log()
    console.log(`startingDate: ${startingDate} | endingDate: ${endingDate}`)
    if (startDate && endDate) 
    {
        start = Utils.yyyymmdd(startDate);
        end = Utils.yyyymmdd(endDate);
    }

    console.log(`Start Date: ${start} | End Date: ${end}`);
    setIsLoadingSignup(true);
    let url = `${HttpsHandler.BASE_URL}/${HttpsActionNames.GET_SIGN_UP_ANALYTICS}?startDate=${start}&endDate=${end}`;
    let rowsForSignUp: DataRowForSignUp[] = [];
    let rowSignup: DataRowForSignUp = {
        Label: "Overall",
        TotalUsers: 0,
        AutoGenUsers: 0,
        StandardUsers: 0,
        DoubleOptInUsers: 0
    };

    rowsForSignUp.push(rowSignup);

    HttpsHandler.SendGetRequest(url, false, (success, data, message) => {
        if (success) {
            console.log(`Data: ${JSON.stringify(data)}`);
            rowSignup.TotalUsers = data.totalSignUps ? data.totalSignUps : 0;
            rowSignup.AutoGenUsers = data.autoGenUsers ? data.autoGenUsers : 0;
            rowSignup.StandardUsers = data.standardUsers ? data.standardUsers : 0;
            rowSignup.DoubleOptInUsers = data.doubleOptInUsers ? data.doubleOptInUsers : 0;
        }
        setRowDataForSignUp(rowsForSignUp);
        setIsLoadingSignup(false);
    }, (success, message) => {
      setIsLoadingSignup(false);
    });
}, [startingDate, endingDate]);

const getPurchaseAnalytics = useCallback((startDate: Date, endDate: Date) => {
  let start = "";
  let end = "";
  console.log()
  console.log(`startingDate: ${startingDate} | endingDate: ${endingDate}`)
  if (startDate && endDate) 
  {
      start = Utils.yyyymmdd(startDate);
      end = Utils.yyyymmdd(endDate);
  }

  console.log(`Start Date: ${start} | End Date: ${end}`);
  setIsLoadingPurchase(true);
  let url = `${HttpsHandler.BASE_URL}/${HttpsActionNames.GET_PURCHASE_ANALYTICS}?startDate=${start}&endDate=${end}`;
  let rowsForPurchase: DataRowForPurchase[] = [];
  let rowPurchase: DataRowForPurchase = {
      Total: 0,
      TotalTransactions: 0,
      UniquePurchasers: 0,
      Ftd: 0,
  };

  rowsForPurchase.push(rowPurchase);

  HttpsHandler.SendGetRequest(url, false, (success, data, message) => {
      if (success) {
          console.log(`Data: ${JSON.stringify(data)}`);
          rowPurchase.Total = data.total ? data.total : 0;
          rowPurchase.TotalTransactions = data.totalTransactions ? data.totalTransactions : 0;
          rowPurchase.UniquePurchasers = data.uniquePurchasers ? data.uniquePurchasers : 0;
          rowPurchase.Ftd = data.ftd ? data.ftd : 0
      }
      setRowDataForPurchase(rowsForPurchase);
      setIsLoadingPurchase(false);
  }, (success, message) => {
    setIsLoadingPurchase(false);
  });
}, [startingDate, endingDate]);

// const getChatterStats = useCallback((startDate: any, endDate: any, chatters?: any) => {
//   let start = "";
//   let end = "";
//   if (startDate && endDate) 
//   {
//       start = startDate.format('YYYYMMDD');
//       end = endDate.format('YYYYMMDD');
//   }

//   console.log(`Start Date: ${start} | End Date: ${end}`);

//   let url = `${HttpsHandler.BASE_URL}/${HttpsActionNames.GET_CHATTER_STATS}?startDate=${start}&endDate=${end}`;
    
//     HttpsHandler.SendGetRequest(url, false, (success, data, message) => {
//     let rowsForChatterStats: DataRowForChatterStats[] = [];

//       if (success) {
//         for (const value of data) {
//           if(!['3tQzOYbEU9eXbmFHeohyjVU42tx1','6eBMp0J172WMUcvJR1PuBEl33rm1'].includes(value.chatterId))
//           {
//           let chatterData = chatters.find((chatter:any)=>chatter.uuid === value.chatterId);
//           let rowChatterStats: DataRowForChatterStats = {
//             Chatter: _.get(chatterData, 'displayName','Chatter'),
//             Messages_Sent: _.get(value,'message','0'),
//             Response_Rate: _.get(value,'responseRate','0'),
//             Response_Percentage: _.get(value,'responsePercentage','0'),
//             Time_To_Respond: _.get(value,'time_to_respond','0'),
//             Earnings:_.get(value,'earnings','0'),
//           }
//           rowsForChatterStats.push(rowChatterStats);
//         }
//         }
//         setRowDataForChatterStats(rowsForChatterStats);
//       }
//       setIsLoadingChatterStats(false);
//   }, (success, message) => {
//     setIsLoadingChatterStats(false);
//   });
// }, []);

// Commented this because data for revenue is coming from purchaseData.json file
// const getRevenueAnalytics = useCallback((startDate?: any, endDate?: any) => {
//   let start = "";
//   let end = "";
//   if (startDate && endDate) 
//   {
//     start = startDate.format('YYYYMMDD');
//     end = endDate.format('YYYYMMDD');
//   }
//   let rowsForRevenue: DataRowForRevenue[] = [];
//   console.log(`Start Date: ${start} | End Date: ${end}`);
//   let url = `${HttpsHandler.BASE_URL}/${HttpsActionNames.GET_REVENUE}?start=${start}&end=${end}`;
//   HttpsHandler.SendGetRequest(url, false,   (success, data, message) => {
//     if (success) { 
//       let rowRevenueLastCol: DataRowForRevenue = {
//         Country: "Overall",
//         TotalRevenue: data?.totalRevenue ? `€ ${(data.totalRevenue).toFixed(2)}` : `€0`,
//         FTD_Revenue: data?.totalFTD ? `€ ${(data.totalFTD).toFixed(2)}` : `€0`,
//         FTD_Num_Users: data?.totalNumUsersFTD ? `€ ${(data.totalNumUsersFTD).toFixed(2)}` : `€0`,
//         FTD_Name_Users: data?.totalNameUsersFTD ? `€ ${(data.totalNameUsersFTD).toFixed(2)}` : `€0`,
//         Returning_Num_Users:`${data.total_num_users_revenue ? '€'+(data.total_num_users_revenue).toFixed(2) : '€'+0 }`,
//         Returning_Name_Users:`${data.total_name_users_revenue ? '€'+(data.total_name_users_revenue).toFixed(2) : '€'+0 }`,
//         Amount_order:`${data.totalOrderAmount ? data.totalOrderAmount : 0 }`
//       }

//       data?.countryWiseRevenue.forEach((element:any)=>{
//         let rowRevenue: DataRowForRevenue = {
//           Country: element.country,
//           TotalRevenue: `€ ${(element.revenue).toFixed(2)}`,
//           FTD_Revenue: `${element.countryWiseFTD ? '€'+(element.countryWiseFTD).toFixed(2) : '€'+0 }`,
//           FTD_Num_Users: `${element.numberedUsersRevenueFTD ? '€'+(element.numberedUsersRevenueFTD).toFixed(2) : '€'+0 }`,
//           FTD_Name_Users:`${element.nameUsersRevenueFTD ? '€'+(element.nameUsersRevenueFTD).toFixed(2) : '€'+0 }`,
//           Returning_Num_Users:`${element.numberedUsersRevenue ? '€'+(element.numberedUsersRevenue).toFixed(2) : '€'+0 }`,
//           Returning_Name_Users:`${element.nameUsersRevenue ? '€'+(element.nameUsersRevenue).toFixed(2) : '€'+0 }`,
//           Amount_order:`${element.orderAmount ? element.orderAmount : 0 }`
//         }
    
//        rowsForRevenue.push(rowRevenue);
//       });
//       setLastRow(rowRevenueLastCol);
//       setRowDataForRevenue(rowsForRevenue);
//       setIsLoadingRevenue(false);
//     }
//   }, (success, message) => {
//     setIsLoadingRevenue(false);
//     console.log("Fail while fetching revenue......")
//   });
// }, []);

// const getRevenueAnalytics = useCallback((startDate?: any, endDate?: any) => {

//   let rowsForRevenue: DataRowForRevenue[] = [];
//   const response = getFilteredData(startDate, endDate);
//   let rowRevenueLastCol: DataRowForRevenue = {
//       Country: "Overall",
//       TotalRevenue: response?.totalRevenue ? `€ ${(response.totalRevenue).toFixed(2)}` : `€0`,
//       FTD_Revenue: `€0`,
//       FTD_Num_Users: `€0`,
//       FTD_Name_Users: `€0`,
//       Returning_Num_Users: `€0`,
//       Returning_Name_Users: `€0`,
//       Amount_order: _.sumBy(response.countryWiseRevenue, (data:any)=> data.numberOfOrder).toString()
//   }
//   response?.countryWiseRevenue.forEach((element: any) => {
//       let rowRevenue: DataRowForRevenue = {
//           Country: element.country,
//           TotalRevenue: `€ ${(element.revenue).toFixed(2)}`,
//           FTD_Revenue: `€0`,
//           FTD_Num_Users: `€0`,
//           FTD_Name_Users: `€0`,
//           Returning_Num_Users: `€0`,
//           Returning_Name_Users: `€0`,
//           Amount_order: element.numberOfOrder
//       }
//       rowsForRevenue.push(rowRevenue);
//     });
//       setLastRow(rowRevenueLastCol);
//       setRowDataForRevenue(rowsForRevenue);
//       setIsLoadingRevenue(false);

//   },[])



  // const handleFilter = () => {
  //   setIsLoadingRevenue(true);
  //   setIsLoadingSignup(true);
  //   setRowDataForSignUp([]);
  //   setRowDataForRevenue([]);
  //   let start = null as any;
  //   let end = null as any;

  //   if (startDate && endDate) {
  //       start = startDate;
  //       end = endDate;
  //   }

  //   if (time !== -1)
  //   {
  //       start = moment();
  //       end = moment();

  //       start.setHours(end.getHours() - time);
  //   }
  //   const newStartDate = moment(start).format('YYYY-MM-DD');
  //   const newEndDate = moment(end).format('YYYY-MM-DD');
  //   // getRevenueAnalytics(newStartDate, newEndDate);
  //   // getChatterStats(start, end, chatters);
  //   // getRevenueAnalytics(start, end);
  //   // getSignUpAnalytics(start, end);
  // };

  useEffect(() => {
    User.GetAllUsers(UserTypes.TYPE_CHATTER).then((chatters) => {
      setChatters(chatters);
      // getRevenueAnalytics('2022-10-1', moment(new Date()).format('YYYY-MM-DD'));
      //getSignUpAnalytics(startingDate, endingDate);
      //getPurchaseAnalytics(startingDate, endingDate);
      getReport(startingDate, endingDate);
      // getChatterStats(moment(moment().startOf('month').toDate()), moment(moment().endOf('month').toDate()), chatters);
      // getRevenueAnalytics(moment(moment().startOf('month').toDate()), moment(moment().endOf('month').toDate()));
  });
  }, [startingDate, endingDate, getSignUpAnalytics, getPurchaseAnalytics, getReport]);
  // }, [getSignUpAnalytics, getRevenueAnalytics, getChatterStats]);

  const fetchSignUpAnalytics = async(start:any, end:any) => {
    // let data = await fetch(`${HttpsHandler.BASE_URL}/${HttpsActionNames.GET_SIGN_UP_ANALYTICS}?startDate=${start}&endDate=${end}&token=6d41ca31-971f-46b2-8017-1d72b7699d20`)
    // console.log(`Sign Up Data: ${JSON.stringify(data.json)}`)
    // return data.json()
  }

  const fetchChatterStatsAnalytics = async(start:any, end:any) => {
    let data = await fetch(`${HttpsHandler.BASE_URL}/${HttpsActionNames.GET_CHATTER_STATS}?startDate=${start}&endDate=${end}&token=6d41ca31-971f-46b2-8017-1d72b7699d20`)
    return data.json()
  }

  const fetchRevenueAnalytics = async(start:any, end:any) => {
    let data = await fetch(`${HttpsHandler.BASE_URL}/${HttpsActionNames.GET_REVENUE}?start=${start}&end=${end}&token=6d41ca31-971f-46b2-8017-1d72b7699d20`)
    return data.json()
  }

  // const useSignUpAnalytics = (start:any, end:any) => {
  //   start = moment(start).format('YYYYMMDD');
  //   end = moment(end).format('YYYYMMDD');
  //   console.log(`Start: ${start} | End: ${end}`);
  //   return useQuery([`sign-up-analytics${start}${end}`],()=> fetchSignUpAnalytics(start,end), {
  //     staleTime: Infinity,
  //     select: (data) => {
  //       return data.data
  //     }
  //   })
  // } 
  
  const useChatterStatsAnalytics = (start:any, end:any) => {
    start = moment(start).format('YYYYMMDD');
    end = moment(end).format('YYYYMMDD');
    return useQuery([`chatter-analytics${start}${end}`],()=> fetchChatterStatsAnalytics(start,end), {
      staleTime: Infinity,
      select: (data) => {
        return data.data
      }
    })
  }
  
  const useRevenueAnalytics = (start:any, end:any) => {
    start = moment(start).format('YYYYMMDD');
    end = moment(end).format('YYYYMMDD');
    return useQuery([`revenue-analytics${start}${end}`],()=> fetchRevenueAnalytics(start,end), {
      staleTime: Infinity,
      select: (data) => {
        return data.data
      }
    })
  }

  // const signUpAnalyticsResponse = useSignUpAnalytics(startingDate, endingDate);
  // useSignUpAnalytics(new Date(), new Date());
  // useSignUpAnalytics(moment().subtract(1, 'days').toDate(),moment().subtract(1, 'days').toDate());

  const chatterStatsAnalyticsResponse = useChatterStatsAnalytics(startingDate, endingDate);
  // useChatterStatsAnalytics(new Date(), new Date());
  // useChatterStatsAnalytics(moment().subtract(1, 'days').toDate(),moment().subtract(1, 'days').toDate());

  const revenueAnalyticsResponse = useRevenueAnalytics(startingDate, endingDate);
  // useRevenueAnalytics(new Date(), new Date());
  // useRevenueAnalytics(moment().subtract(1, 'days').toDate(),moment().subtract(1, 'days').toDate());

  // let rowsForSignUp: DataRowForSignUp[] = [];
  // let rowSignup: DataRowForSignUp = {
  //   Label: "Overall",
  //   TotalUsers: 0,
  //   AutoGenUsers: 0,
  //   StandardUsers: 0,
  //   DoubleOptInUsers: 0
  // };
  // rowsForSignUp.push(rowSignup);

  // if(!signUpAnalyticsResponse?.isLoading && !signUpAnalyticsResponse?.isFetching && signUpAnalyticsResponse?.status==='success'){

  //   rowSignup.TotalUsers = signUpAnalyticsResponse?.data?.totalSignUps;
  //   rowSignup.AutoGenUsers = signUpAnalyticsResponse?.data?.autoGenUsers;
  //   rowSignup.StandardUsers = signUpAnalyticsResponse?.data?.standardUsers;
  //   rowSignup.DoubleOptInUsers = signUpAnalyticsResponse?.data?.doubleOptInUsers;
  // }

  // let rowsForChatterStats: DataRowForChatterStats[] = [];
  
  // if(!chatterStatsAnalyticsResponse?.isLoading && !chatterStatsAnalyticsResponse?.isFetching && chatterStatsAnalyticsResponse?.status==='success'){
  //   for (const value of chatterStatsAnalyticsResponse?.data) {
  //     if(!['3tQzOYbEU9eXbmFHeohyjVU42tx1','6eBMp0J172WMUcvJR1PuBEl33rm1'].includes(value.chatterId))
  //     {
  //       let chatterData = chatters.find((chatter:any)=>chatter.uuid === value.chatterId);
  //       let rowChatterStats: DataRowForChatterStats = {
  //         Chatter: _.get(chatterData, 'displayName','Chatter'),
  //         Messages_Sent: _.get(value,'message','0'),
  //         Response_Rate: _.get(value,'responseRate','0'),
  //         Response_Percentage: _.get(value,'responsePercentage','0'),
  //         // Time_To_Respond: _.get(value,'time_to_respond','0'),
  //         Earnings:_.get(value,'earnings','0'),
  //       }
  //       rowsForChatterStats.push(rowChatterStats);
  //     }
  //   }
  // }

  // let rowsForRevenue: DataRowForRevenue[] = [];
  // let rowRevenueLastCol: DataRowForRevenue = {
  //   Country: "Overall",
  //   TotalRevenue: revenueAnalyticsResponse?.data?.totalRevenue ? `€ ${(revenueAnalyticsResponse?.data?.totalRevenue).toFixed(2)}` : `€0`,
  //   FTD_Revenue: revenueAnalyticsResponse?.data?.totalFTD ? `€ ${(revenueAnalyticsResponse?.data?.totalFTD).toFixed(2)}` : `€0`,
  //   FTD_Num_Users: revenueAnalyticsResponse?.data?.totalNumUsersFTD ? `€ ${(revenueAnalyticsResponse?.data?.totalNumUsersFTD).toFixed(2)}` : `€0`,
  //   FTD_Name_Users: revenueAnalyticsResponse?.data?.totalNameUsersFTD ? `€ ${(revenueAnalyticsResponse?.data?.totalNameUsersFTD).toFixed(2)}` : `€0`,
  //   Returning_Num_Users:`${revenueAnalyticsResponse?.data?.total_num_users_revenue ? '€'+(revenueAnalyticsResponse?.data?.total_num_users_revenue).toFixed(2) : '€'+0 }`,
  //   Returning_Name_Users:`${revenueAnalyticsResponse?.data?.total_name_users_revenue ? '€'+(revenueAnalyticsResponse?.data?.total_name_users_revenue).toFixed(2) : '€'+0 }`,
  //   Amount_order:`${revenueAnalyticsResponse?.data?.totalOrderAmount ? revenueAnalyticsResponse?.data?.totalOrderAmount : 0 }`
  // }

  // revenueAnalyticsResponse?.data?.countryWiseRevenue.forEach((element:any)=>{
  //   let rowRevenue: DataRowForRevenue = {
  //     Country: element.country,
  //     TotalRevenue: `€ ${(element.revenue).toFixed(2)}`,
  //     FTD_Revenue: `${element.countryWiseFTD ? '€'+(element.countryWiseFTD).toFixed(2) : '€'+0 }`,
  //     FTD_Num_Users: `${element.numberedUsersRevenueFTD ? '€'+(element.numberedUsersRevenueFTD).toFixed(2) : '€'+0 }`,
  //     FTD_Name_Users:`${element.nameUsersRevenueFTD ? '€'+(element.nameUsersRevenueFTD).toFixed(2) : '€'+0 }`,
  //     Returning_Num_Users:`${element.numberedUsersRevenue ? '€'+(element.numberedUsersRevenue).toFixed(2) : '€'+0 }`,
  //     Returning_Name_Users:`${element.nameUsersRevenue ? '€'+(element.nameUsersRevenue).toFixed(2) : '€'+0 }`,
  //     Amount_order:`${element.orderAmount ? element.orderAmount : 0 }`
  //   }

  //  rowsForRevenue.push(rowRevenue);
  // });

  return (
    <>
         <div className='card-menus' >
            <div className='adminFeed-filters' style={{justifyContent:'flex-end', paddingRight:'2%'}}>

              <div className='form-group'>
                <DateRangePicker
                  initialSettings={{
                    startDate: moment().startOf('month').toDate(),
                    endDate:moment().endOf('month').toDate(),
                    maxDate: moment().subtract(1, 'days'),
                    ranges: {
                        Today: [moment().toDate(), moment().toDate()],
                        Yesterday: [
                        moment().subtract(1, 'days').toDate(),
                        moment().subtract(1, 'days').toDate(),
                        ],
                        'This Week': [
                        moment().subtract(6, 'days').toDate(),
                        moment().toDate(),
                        ],
                        'This Month': [
                        moment().startOf('month').toDate(),
                        moment().endOf('month').toDate(),
                        ],
                        'Last Week': [
                          moment().subtract(12, 'days').toDate(),
                          moment().subtract(6,'days').toDate(),
                          ],
                        'Last Month': [
                        moment().subtract(1, 'month').startOf('month').toDate(),
                        moment().subtract(1, 'month').endOf('month').toDate(),
                        ],
                    },
                    }}
                    onEvent = {changeDateRange}
                  >
                  <input type="text" className="form-control bg-light" />
                </DateRangePicker>
              </div>
          
              {/* <div className='btns-group'>
                <button className='btn btn-primary' type='button' onClick={handleFilter}>Apply Filter</button>
              </div> */}
            </div>
          </div>

         {/* {!isLoadingSignup ? <div style={{border:'1px solid black', marginTop:'2%'}}>
            <DataTable 
            title="SignUp Count"
            customStyles={{
              headCells: {
                style: {
                  fontWeight: "bolder",
                  fontSize:'13px'
                }
              }
            }}
            highlightOnHover={true}
            columns={columnsForSignUp} 
            // data={rowDataForSignUp} />
            data={rowsForSignUp} />
          </div> : 
          <LoadingComponent tagName='Signup' />
          } */}

          {!isLoadingReport ? <div style={{border:'1px solid black', marginTop:'2%'}}>
            <DataTable 
            title="Report"
            customStyles={{
              headCells: {
                style: {
                  fontWeight: "bolder",
                  fontSize:'13px',
                  width: '150px'
                }
              }
            }}
            highlightOnHover={true}
            columns={columnsForReport} 
            // data={rowDataForSignUp} />
            data={rowDataForReport} />
          </div> : 
          <LoadingComponent tagName='Report' />
          } 

          {!isLoadingReportTotal ? <div style={{border:'1px solid black', marginTop:'2%'}}>
            <DataTable 
            title="Report Total"
            customStyles={{
              headCells: {
                style: {
                  fontWeight: "bolder",
                  fontSize:'13px',
                  width: '150px'
                }
              }
            }}
            highlightOnHover={true}
            columns={columnsForReportTotal} 
            // data={rowDataForSignUp} />
            data={rowDataForReportTotal} />
          </div> : 
          <LoadingComponent tagName='Report Total' />
          } 
          
          {/* {!isLoadingSignup ? <div style={{border:'1px solid black', marginTop:'2%'}}>
            <DataTable 
            title="SignUp Count"
            customStyles={{
              headCells: {
                style: {
                  fontWeight: "bolder",
                  fontSize:'13px'
                }
              }
            }}
            highlightOnHover={true}
            columns={columnsForSignUp} 
            // data={rowDataForSignUp} />
            data={rowDataForSignUp} />
          </div> : 
          <LoadingComponent tagName='Signup' />
          }

          {!isLoadingPurchase ? <div style={{border:'1px solid black', marginTop:'2%'}}>
            <DataTable 
            title="Purchase Analytics"
            customStyles={{
              headCells: {
                style: {
                  fontWeight: "bolder",
                  fontSize:'13px'
                }
              }
            }}
            highlightOnHover={true}
            columns={columnsForPurchase} 
            // data={rowDataForSignUp} />
            data={rowDataForPurchase} />
          </div> : 
          <LoadingComponent tagName='Purchase' />
          } */}

          {/* {
            !isLoadingChatterStats ? <div style={{border:'1px solid black', marginTop:'2%'}}>
            <DataTable 
            title="Chatter Stats" 
            customStyles={{
              headCells: {
                style: {
                  fontWeight: "bolder",
                  fontSize:'13px'
                }
              }
            }}
            highlightOnHover={true}
            columns={columnsForChatterStats} 
            data={rowDataForChatterStats} />
          </div> : <LoadingComponent tagName={'Chatter'} />
          } */}
          
          {/* {
            !chatterStatsAnalyticsResponse?.isLoading ? <div style={{border:'1px solid black', marginTop:'2%'}}>
            <DataTable 
            title="Chatter Stats" 
            customStyles={{
              headCells: {
                style: {
                  fontWeight: "bolder",
                  fontSize:'13px'
                }
              }
            }}
            highlightOnHover={true}
            columns={columnsForChatterStats} 
            data={rowsForChatterStats} />
          </div> : <LoadingComponent tagName={'Chatter'} />
          } */}

         {/* {!isLoadingRevenue ? <div style={{border:'1px solid black', marginTop:'2%'}}>
            <DataTable 
            title="Revenue" 
            customStyles={{
              headCells: {
                style: {
                  fontWeight: "bolder",
                  fontSize:'13px'
                }
              }
            }}
            highlightOnHover={true}
            footer={LastRow}
            columns={columnsForRevenue} 
            data={rowDataForRevenue} />
          </div> :
          <LoadingComponent tagName={'Revenue'} />
          } */}
          
          {/* {!revenueAnalyticsResponse?.isLoading ? <div style={{border:'1px solid black', marginTop:'2%'}}>
            <DataTable 
            title="Revenue" 
            customStyles={{
              headCells: {
                style: {
                  fontWeight: "bolder",
                  fontSize:'13px'
                }
              }
            }}
            highlightOnHover={true}
            footer={rowRevenueLastCol}
            columns={columnsForRevenue} 
            data={rowsForRevenue} />
          </div> :
          <LoadingComponent tagName={'Revenue'} />
          } */}
    </>
  )
}

export default GeneralAnalytics;