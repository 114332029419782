import { Auth, getAuth, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "@firebase/auth";
import { addDoc, arrayRemove, arrayUnion, collection, deleteDoc, getDoc, getFirestore, limit, serverTimestamp, setDoc, updateDoc } from "@firebase/firestore";
import { doc, DocumentData, endBefore, getDocs, increment, orderBy, query, QueryConstraint, QueryDocumentSnapshot, startAt, where } from "firebase/firestore";
import { InfoModel, NoteModel, PaginatedChatterUserHistoryModels, PaginatedUserModels, UserImageModel } from "../../_metronic/helpers";
import { EnvironmentType, FirebaseApp } from "../FirebaseApp";
import { FirestoreManager } from "../system/FirestoreManager";
import { HttpsActionNames, HttpsHandler } from "../system/HttpsHandler";

import { SessionHandler, SessionKeys } from '../system/SessionHandler';
import { Utils } from "../system/Utils";
import { Count, CountTypes } from "./Count";
import { ProfileModel } from "./Profile";

export class UserModel extends InfoModel {
  uuid: string = "";
  token: string = "";
  email: string = "";
  publicPhotos: UserImageModel[] = [];
  privatePhotos: UserImageModel[] = [];
  userType: 'user' | 'chatter' | 'admin' | 'profile_creator' | 'moderator' = 'chatter';
  subType: string = "";
  profile: ProfileModel = null as any;
  siteOfOrigin: string = "";
  likedProfiles: string[];
  profilesLikedYou: string[];
  lastLoggedIn: any = "";
  createdProfiles: string[];
  credits: number = 0;
  geolocation: Map<string, string> = null as any;
  ageOfChattedProfiles: number[] = [];
  raceOfChattedProfiles: string[] = [];
  isPaidUser: boolean = false;
  isTestAccount: boolean = false;
  isAutoGenerated: boolean = false;
  isDeleted: boolean = false;
  chattingStatus:string = '';
  profileReference:string = '';
  isProfileCompleted:boolean = false;
  verificationRewardsReceived: boolean = false;
  pageLocation: string = "";
  clientId: string = "";
  onlineStatus: boolean = false;
  totalTimeOnline: number = 0;
  product_total_amount: number;
  message_received: number;
  message_sent: number;
  hasVisitedSite: boolean;
  countOfUserMessage: number;
  timeDiffOfChatterReply: number;
  optInStatus: string;
  userChatHistory: string[] = [];

  constructor(params: any) {
    super(params);
    this.uuid = params.uuid;
    this.token = params.token;
    this.email = params.email;
    this.publicPhotos = params.publicPhotos;
    this.privatePhotos = params.privatePhotos;
    this.profile = params.profile;
    this.userType = params.userType;
    this.subType = params.subType;
    this.siteOfOrigin = params.siteOfOrigin;
    this.likedProfiles = params.likedProfiles;
    this.profilesLikedYou = params.profilesLikedYou;
    this.lastLoggedIn = params.lastLoggedIn;
    this.createdProfiles = params.createdProfiles;
    this.credits = params.credits;
    this.geolocation = params.geolocation;
    this.ageOfChattedProfiles = params.ageOfChattedProfiles;
    this.raceOfChattedProfiles = params.raceOfChattedProfiles;
    this.isPaidUser = params.isPaidUser;
    this.isTestAccount = params.isTestAccount;
    this.isAutoGenerated = params.isAutoGenerated;
    this.isDeleted = params.isDeleted;
    this.chattingStatus = params.chattingStatus;
    this.profileReference = params.profileReference;
    this.isProfileCompleted = params.isProfileCompleted;
    this.verificationRewardsReceived = params.verificationRewardsReceived;
    this.pageLocation = params.pageLocation;
    this.clientId = params.clientId;
    this.onlineStatus = params.onlineStatus;
    this.totalTimeOnline = params.totalTimeOnline;
    this.product_total_amount = params.product_total_amount;
    this.message_received = params.message_received;
    this.message_sent = params.message_sent
    this.hasVisitedSite = params.hasVisitedSite;
    this.countOfUserMessage = params.countOfUserMessage;
    this.timeDiffOfChatterReply = params.timeDiffOfChatterReply;
    this.optInStatus = params.optInStatus;
    this.userChatHistory = params.userChatHistory;
  }
}

export class UserTypes
{
  public static get TYPE_USER(): string { return "user"; }
  public static get TYPE_CHATTER(): string { return "chatter"; }
  public static get TYPE_ADMIN(): string { return "admin"; }
  public static get TYPE_PROFILE_CREATOR(): string { return "profile_creator"; }
  public static get TYPE_MODERATOR(): string { return "moderator"; }
}

export class SubTypes
{
  public static get SUB_TYPE_CHATTER_MANAGER(): string { return "chatter_manager"; }
}

export class UserTag 
{
  public static get NEW_MESSAGE(): string { return "New Message"; }
  public static get LIKED_PROFILE(): string { return "Liked Profile"; }
  public static get POKED_PROFILE(): string { return "Poked Profile"; }
  public static get LOGGED_IN(): string { return "Logged In"; }
  public static get LOGGED_OUT(): string { return "Logged Out"; }
  public static get NEW_USER(): string { return "New User"; }
  public static get CLICKED_GET_CREDITS(): string { return "Clicked Get Credits"; }
  public static get COMPLETED_PROFILE() : string { return "Completed Profile"; }
  public static get UPLOADED_NEW_PROFILE_PIC() : string { return "Uploaded New Profile Pic"; }
  public static get SUPPORT() : string { return "SUPPORT"; }
  public static get REMAINING_CREDITS(): string { return "Remaining Credits"; }
  public static get PREVIOUS_MESSAGE(): string { return "Previous Message"; }
  public static get PURCHASED_CREDITS(): string { return "Purchased Credits"; }
  public static get UNLOCKED_ROOM(): string { return "Unlocked Room"; }
  public static get LATE_MESSAGE(): string { return "Late Message"; }
}

export class User
{
  //private static auth = null;//getAuth();
  private static userModel: UserModel = null as any;
  private static isLoggedIn = false;

  private static userCache = new Map<string, UserModel>();

  public static Initialize(): Auth {
    const auth = getAuth();
    let sessionModel: UserModel = SessionHandler.GetItem<UserModel>(SessionKeys.SESSION_USER_MODEL, null as any);
    //auth.signOut();
    if (sessionModel) {
      // if (sessionModel.userType === UserTypes.TYPE_CHATTER || sessionModel.userType === UserTypes.TYPE_ADMIN) {
      //   let sessionProfile = SessionHandler.GetItem(SessionKeys.SESSION_PROFILE_MODEL, null as any)
      //   sessionModel.profile = sessionProfile;
      // }
      this.userModel = sessionModel;
      this.isLoggedIn = true;
    } else {
      const user = auth.currentUser;

      if (user) {
        //this.uuid = user.uid;
        this.userModel = new UserModel(
          {
            uuid: user.uid,
            //token : user.accessToken,
            displayName: user.displayName,
            photoURL: user.photoURL,
            email: user.email
          }
        );
        this.isLoggedIn = true;
        this.RegisterUser(this.userModel.uuid, {
          displayName: this.userModel.displayName,
          photoURL: this.userModel.photoURL,
          email: this.userModel.email
        }).then(storedModel => {
          if (storedModel) {
            // if (storedModel.userType === UserTypes.TYPE_CHATTER || storedModel.userType === UserTypes.TYPE_ADMIN) {
            //   let sessionProfile = SessionHandler.GetItem(SessionKeys.SESSION_PROFILE_MODEL, null as any)
            //   storedModel.profile = sessionProfile;
            // }
            this.userModel = storedModel;
          }
        });
      } else {
        this.SignOut();
      }
    }

    return auth;
  }

  static get Model(): UserModel {
    return this.userModel
  }

  public static SetChatterProfile(profileModel: ProfileModel) {
    this.userModel.profile = profileModel;
    //SessionHandler.SetItem(SessionKeys.SESSION_PROFILE_MODEL, this.userModel.profile);
  }

  public static get IsLoggedIn(): boolean {
    return this.isLoggedIn && this.userModel != null && this.userModel.uuid !== "";
  }

  public static async SignIn(loginByEmail: boolean, onSuccess: () => void | null, onFail: () => void | null, email: string = "", password: string = ""): Promise<void> {
    const auth = getAuth();
    if (loginByEmail) {
      await this.SignInWithEmailAndPassword(auth, email, password, onSuccess, onFail);
    } else {
      await this.SignInWithGoogle(auth, onSuccess, onFail);
    }
  }

  private static async SignInWithEmailAndPassword(auth: Auth, email: string, password: string, onSuccess: () => void | null, onFail: () => void | null): Promise<void> {
    await signInWithEmailAndPassword(auth, email, password).then(async (userCredential) => {
      // Signed in 
      const user:any = userCredential.user;
      await this.GetUserAccount(user.uid).then((result) => {
        let resultWithAccessToken = {
          ...result,
          token : user?.accessToken,
        }
        this.userModel = resultWithAccessToken;
      })

      this.isLoggedIn = true;
      console.log(`User Model: ${this.userModel.userType}`);
      SessionHandler.SetItem(SessionKeys.SESSION_USER_MODEL, this.userModel);
      onSuccess();
    })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("Error Code: " + errorCode + " | Message: " + errorMessage);

        this.isLoggedIn = false;
        onFail();
      });
  }

  private static async SignInWithGoogle(auth: Auth, onSuccess: () => void | null, onFail: () => void | null): Promise<void> {
    const provider = new GoogleAuthProvider();
    await signInWithPopup(auth, provider).then(async (result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      //const credential = GoogleAuthProvider.credentialFromResult(result);
      // The signed-in user info.
      const user = result.user;

      // this.userModel = new UserModel(
      //   {
      //     uuid: user.uid,
      //     //token : user.accessToken,
      //     displayName: user.displayName,
      //     photoURL: user.photoURL,
      //     email: user.email
      //   }
      // );

      await this.GetUserAccount(user.uid).then((result) => {
        this.userModel = result;
      })

      SessionHandler.SetItem(SessionKeys.SESSION_USER_MODEL, this.userModel);
      this.isLoggedIn = true;
      onSuccess();
      // ...
    }).catch((error) => {
      // Handle Errors here.
      //const errorCode = error.code;
      //const errorMessage = error.message;
      // The email of the user's account used.
      //const email = error.email;
      // The AuthCredential type that was used.
      //const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
      this.userModel = null as any;
      this.isLoggedIn = false;
      onFail();
    });

    if (this.userModel) {
      await this.RegisterUser(this.userModel.uuid,
        {
          displayName: this.userModel.displayName,
          photoURL: this.userModel.photoURL,
          email: this.userModel.email
        }).then(result => {
          if (result) {
            this.userModel = result;
          }
        });
    }
  }

  public static async SignOutOfProfile(): Promise<void> {
    if (this.userModel?.profile) 
    {
      this.userModel.profile = null as any;
      SessionHandler.DeleteItem(SessionKeys.SESSION_PROFILE_MODEL);
    }
  }

  public static async SignOut(): Promise<void> {
    const auth = getAuth();
    await this.SignOutOfProfile().then(() => {
      
    });
    SessionHandler.DeleteItem(SessionKeys.SESSION_USER_MODEL);
    auth.signOut()
    this.userModel = null as any;
    this.isLoggedIn = false;
  }

  public static async GetUsers(uuidList: string[]): Promise<Map<string, UserModel>> {
    let cache: Map<string, UserModel> = new Map<string, UserModel>();
    for (let i = 0; i < uuidList.length; i++) {
      let uuid = uuidList[i];
      await this.GetUserAccount(uuid).then((result) => {
        if (result)
          cache?.set(result.uuid, result);
      })
      // if (!this.userCache.has(uuid)) {
      //   cache = await this.GetUserAccount(uuid).then(result => {
      //     return this.userCache;
      //   });
      // }
    }

    return Promise.resolve(cache);
    //return cache!;
  }

  public static async GetUsersByIds(user_ids: string[]): Promise<UserModel[]>
  {
    let userModels: UserModel[] = [];
    for (let i = 0; i < user_ids.length; i++)
    {
      await this.GetUserAccount(user_ids[i]).then((model) => {
        if (model)
          userModels.push(model);
      })
    }

    return Promise.resolve(userModels);
  }

  public static async SignUp(email: string, password: string, displayName: string, fn: () => void | null): Promise<void> {
    const auth = getAuth();
    displayName = displayName.replace(/\s+/g, ' ');
    await createUserWithEmailAndPassword(auth, email, password).then(async (userCredential) => {
      const user = userCredential.user;

      this.userModel = new UserModel(
        {
          uuid: user.uid,
          //token : user.accessToken,
          displayName: displayName,
          photoURL: "",
          email: user.email
        }
      );

      if (this.userModel) {
        await this.RegisterUser(this.userModel.uuid,
          {
            displayName: this.userModel.displayName,
            photoURL: this.userModel.photoURL,
            email: this.userModel.email
          }).then(result => {
            if (result) {
              this.userModel = result;
            }
          });
      }
      this.isLoggedIn = true;
      fn();
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      this.isLoggedIn = false;
      console.log("Error | Code: " + errorCode + " | Message: " + errorMessage);
    });
  }

  private static async RegisterUser(uuid: string, params: any): Promise<UserModel> {
    let userModel: UserModel = null as any;
    await this.GetUserAccount(uuid).then((result) => {
      if (result)
        userModel = result;
    });

    if (userModel) {
      let timeStamp = serverTimestamp();
      const updateParam = {
        lastLoggedIn: timeStamp
      };
      await this.UpdateUserAccount(uuid, updateParam);
    }
    else {
      await this.CreateUserAccount(uuid, params);
    }
    return Promise.resolve(userModel);
  }

  public static async RegisterAuthProvider(uuid: string, provider: string): Promise<void> {
    const params = {
      authProvider: provider,
    }

    await this.UpdateUserAccount(uuid, params).then(() => {
      console.log(`Success Updating User Account: ${provider}`);
      this.userModel.authProvider = Utils.DetermineAuth(provider);
    }); 

    return Promise.resolve();
  };

  public static async UpdateUserAccount(uuid: string, params: any): Promise<void> {
    const firestore = getFirestore();
    const usersRef = collection(firestore, "users");
    const usersDoc = doc(usersRef, uuid);
    //params = this.CheckValidDataInput(params);
    await updateDoc(usersDoc, params);

    return Promise.resolve();
  }

  public static async UpdateChatterLanguage(uuid:string, params: any): Promise<void>{
    const firestore = getFirestore();
    const usersRef = collection(firestore, "users");
    const usersDoc = doc(usersRef, uuid);
    if('language' in params){
      await updateDoc(usersDoc, params);
    }
  }
  public static async CreateChatter(email: string, password: string, params: any): Promise<void> {
    const auth = getAuth();
    params.displayName = params.displayName.replace(/\s+/g, ' ');
    let success = false;
    let errorMessage = "";
    await createUserWithEmailAndPassword(auth, email, password).then(async (userCredential) => {
      const user = userCredential.user;

      await this.CreateUserAccount(user.uid, params).then(async () => {
        await Count.IncrementCount(CountTypes.CHATTER, 1).then(() => {
          success = true
        });
      })
      
    }).catch((error) => {
      const errorCode =!error ? "400" : error.code;
      if (errorCode && errorCode.includes("auth/invalid-email"))
        errorMessage = "Invalid Email Address!";
      errorMessage = "Error while creating an account";
      console.log("Error | Code: " + errorCode + " | Message: " + errorMessage);
    });

    if (success)
      return Promise.resolve();
    else
      return Promise.reject(errorMessage);
  }

  public static async AddToCreatedProfiles(user_id: string, profile_id: string): Promise<void>
  {
    const firestore = getFirestore();
    let usersRef = collection(firestore, "users");
    let usersDoc = doc(usersRef, user_id);
    
    let params = {
      createdProfiles: arrayUnion(profile_id)
    }

    let success = false;
    await updateDoc(usersDoc, params).then(() => {
      success = true;
    });

    if (success)
      return Promise.resolve();
    else
      return Promise.reject();
  }

  public static async AddToChatterUserHistory(uuid: string, profileId: string): Promise<void> {
    const firestore = getFirestore();
    let currTime = serverTimestamp();
    let historyData = {
      uuid: uuid,
      profileId: profileId,
      createdAt: currTime,
      lastUpdated: currTime
    }
    let historyRef = collection(firestore, `users/${User.Model?.uuid}/chatter_history`);

    // Check if history already exists
    let queries: QueryConstraint[] = [];
    queries.push(where("uuid", "==", uuid));
    queries.push(where("profileId", "==", profileId));
    let historyQuery = query(historyRef, ...queries);

    const historySnap = await getDocs(historyQuery);

    // If history data exists, overwrite the lastUpdated time
    if (historySnap.docs.length > 0)
    {
      let docId = historySnap.docs[0].id;
      let historyDoc = doc(historyRef, docId);

      console.log("updating new entry")
      await updateDoc(historyDoc, {
        lastUpdated: currTime,
      });
    } else 
    {
      // If history data does not exist, create new entry
      console.log("Creating new entry")
      await addDoc(historyRef, historyData);
    }
  }

  public static async ArchiveCreatedProfiles(user_id: string, created_profiles: string[]): Promise<void>
  {
    const firestore = getFirestore();
    let usersRef = collection(firestore, "users");
    let usersDoc = doc(usersRef, user_id);

    let params = {
      archivedCreatedProfiles: arrayUnion(...created_profiles),
      createdProfiles: arrayRemove(...created_profiles)
    }

    let success = false;
    await updateDoc(usersDoc, params).then(() => {
      success = true;
    });

    if (success)
      return Promise.resolve();
    else
      return Promise.reject();
  }

  private static async CreateUserAccount(uuid: string, params: any): Promise<void> {
    let timeStamp = serverTimestamp();
    params = this.CheckValidDataInput(params);
    params["uuid"] = uuid;
    params["createdAt"] = timeStamp;
    params["lastLoggedIn"] = timeStamp;
    const firestore = getFirestore();
    const usersRef = collection(firestore, "users");
    const usersDoc = doc(usersRef, uuid);
    let success = false;
    await setDoc(usersDoc, params).then(() => {
      success = true;
    });

    if (success)
      return Promise.resolve();
    else
      return Promise.reject();
  }

  public static async GetUserAccount(uuid: string): Promise<UserModel> {
    const firestore = getFirestore();
    const userDoc = doc(firestore, "users", uuid);
    const userSnap = await getDoc(userDoc);
    if (userSnap.exists() && !userSnap.data().isDeleted) {
      let data = userSnap.data();

      let userModel = Utils.ParseDataToUserModel(data);

      this.userCache.set(userModel.uuid, userModel);

      return Promise.resolve(userModel);
    }
    else {
      return Promise.resolve(null as any);
    }
  }

  public static async GetDeletedUserAccount(): Promise<UserModel[]> {
    const firestore = getFirestore();
    let usersRef = collection(firestore, "users");
    let usersQuery = query(usersRef, where("isDeleted", "==", true));

    let userModels: UserModel[] = [];
    const usersSnap = await getDocs(usersQuery);
    usersSnap.forEach((doc) => {
        let model = Utils.ParseDataToUserModel(doc.data());
        userModels.push(model);
    });

    return Promise.resolve(userModels);
  }

  public static async AddToProfilesThatLikedYou(userId: string, profile_id: string)
  {
    const firestore = getFirestore();
    let usersRef = collection(firestore, "users");
    let userDoc = doc(usersRef, userId);

    let params = {
      profilesLikedYou: arrayUnion(profile_id)
    }

    let success = false;
    await updateDoc(userDoc, params).then(() => {
      success = true;
    });

    if (success)
      return Promise.resolve();
    else
      return Promise.reject();
  }

  public static async GetAllUsers(userType: string = null as any): Promise<UserModel[]> {
    const firestore = getFirestore();
    let usersRef = collection(firestore, "users");
    let usersQuery = userType? query(usersRef, where("userType", "==", userType)) : query(usersRef);

    let userModels: UserModel[] = [];
    const usersSnap = await getDocs(usersQuery);
    usersSnap.forEach((doc) => {
      if(!doc.data().isDeleted) {
        let model = Utils.ParseDataToUserModel(doc.data());
        userModels.push(model);
      }
    });

    return Promise.resolve(userModels);
  }

  public static async GetAllUsersMultipleTypes(userTypes: string[] = []): Promise<UserModel[]> {
    const firestore = getFirestore();
    let usersRef = collection(firestore, "users");

    const queries: QueryConstraint[] = [];
    if (userTypes.length > 0)
    {
      queries.push(where("userType", "in", userTypes));
    }
    let usersQuery = query(usersRef, ...queries);

    let userModels: UserModel[] = [];
    const usersSnap = await getDocs(usersQuery);
    usersSnap.forEach((doc) => {
      if(!doc.data().isDeleted) {
        let model = Utils.ParseDataToUserModel(doc.data());
        userModels.push(model);
      }
    });

    return Promise.resolve(userModels);
  }

  public static async GetAllUsersPaginated(visibleStart: QueryDocumentSnapshot<DocumentData> = null as any, userType: string = UserTypes.TYPE_USER, searchedUser: string = "", entry_limit: number = 20, filterBySoiDoi: string = '', field: string = '', sort: "desc" | "asc" = "desc"): Promise<PaginatedUserModels>
  {
    console.log(filterBySoiDoi)
    let paginatedUserModels: PaginatedUserModels = new PaginatedUserModels();
    let userModels = [];

    const firestore = getFirestore();
    const userModelsRef = collection(firestore, `users`);

    console.log(`Last Visible: ${visibleStart?.id}`);
    const queries: QueryConstraint[] = [];
    if(field && sort){
      queries.push(orderBy(`${field}`, `${sort}`));
    } 
    else{
      queries.push(orderBy("createdAt", "desc"));
    }
    queries.push(limit(entry_limit + 1));
    if (visibleStart){
      // When Sorting by Descending AND there is an existing Visible Cursor, the query needs Start At
      queries.push(startAt(visibleStart));
    } else {
      // When Sorting by Descending and there is no existing Visible Cursor the query needs endBefore
      if (sort === "desc"){
        queries.push(endBefore(null));
      }
    }
    queries.push(where("userType", "==", userType));
    queries.push(where("hasVisitedSite", "==", true));
    // if(userType === "user"){
    queries.push(where("isTestAccount", "==", FirebaseApp.environment === EnvironmentType.STAGING));
    // }

    if (searchedUser)
    {
      console.log(`Searched User: ${searchedUser}`);
      queries.push(where("displayName", "==", searchedUser));
    }

    if (filterBySoiDoi === 'SOI')
    {
      console.log(`filterBySoiDoi: ${filterBySoiDoi}`);
      // queries.push(where("hasVisitedSite", "==", false))
      queries.push(where("optInStatus", "==", "soi"))
    }

    if (filterBySoiDoi === 'DOI')
    {
      console.log(`filterBySoiDoi: ${filterBySoiDoi}`);
      // queries.push(where("hasVisitedSite", "==", true));
      queries.push(where("optInStatus", "==", "doi"))

    }

    if(filterBySoiDoi === 'All'){
      queries.push(where("userType", "==", userType));
    }

    const userModelsQuery = query(userModelsRef, ...queries);
    
    const userModelsSnap = await getDocs(userModelsQuery);
    if (userModelsSnap.docs)
    { 
      if (userModelsSnap.docs.length === entry_limit + 1)
      {
        paginatedUserModels.node.visibleStart = userModelsSnap.docs[userModelsSnap.docs.length - 1];
        paginatedUserModels.node.nodeId = paginatedUserModels.node.visibleStart.id.toString();
        paginatedUserModels.nextNodeExists = true;
      }
    }

    console.log(`Snap length: ${userModelsSnap.docs.length}`);
    
    for (let i = 0; i < userModelsSnap.docs.length; i++)
    {
      console.log("Try");
      if (i === entry_limit)
        continue;

      console.log("Proceed");
      const doc = userModelsSnap.docs[i];
      const model = Utils.ParseDataToUserModel(doc.data());

      userModels.push(model);
    }

    // purchaseLogs = purchaseLogs.reverse();
    paginatedUserModels.userModels = userModels;

    return Promise.resolve(paginatedUserModels);
  }

  public static async GetAllChatterUserHistoryPaginated(visibleStart: QueryDocumentSnapshot<DocumentData> = null as any, entry_limit: number = 20): Promise<PaginatedChatterUserHistoryModels>
  {
    //console.log(filterBySoiDoi)
    let paginatedChatterUserHistoryModels: PaginatedChatterUserHistoryModels = new PaginatedChatterUserHistoryModels();
    let chatterUserHistoryModels = [];

    const firestore = getFirestore();
    const chatterUserHistoryModelsRef = collection(firestore, `users/${User.Model?.uuid}/chatter_history`);

    console.log(`Last Visible: ${visibleStart?.id}`);
    const queries: QueryConstraint[] = [];
    // if(field && sort){
    //   queries.push(orderBy(`${field}`, `${sort}`));
    // } 
    // else{
    //   queries.push(orderBy("createdAt", "desc"));
    // }

    queries.push(orderBy("lastUpdated", "desc"));
    queries.push(limit(entry_limit + 1));
    if (visibleStart){
      // When Sorting by Descending AND there is an existing Visible Cursor, the query needs Start At
      queries.push(startAt(visibleStart));
    } else {
      // When Sorting by Descending and there is no existing Visible Cursor the query needs endBefore
      //if (sort === "desc"){
        queries.push(endBefore(null));
      //}
    }
    // queries.push(where("userType", "==", UserTypes.TYPE_USER));
    // if(userType === "user"){
    // queries.push(where("isTestAccount", "==", FirebaseApp.environment === EnvironmentType.STAGING));
    // }

    // if (searchedUser)
    // {
    //   console.log(`Searched User: ${searchedUser}`);
    //   queries.push(where("displayName", "==", searchedUser));
    // }

    // if (filterBySoiDoi === 'SOI')
    // {
    //   console.log(`filterBySoiDoi: ${filterBySoiDoi}`);
    //   // queries.push(where("hasVisitedSite", "==", false))
    //   queries.push(where("optInStatus", "==", "soi"))
    // }

    // if (filterBySoiDoi === 'DOI')
    // {
    //   console.log(`filterBySoiDoi: ${filterBySoiDoi}`);
    //   // queries.push(where("hasVisitedSite", "==", true));
    //   queries.push(where("optInStatus", "==", "doi"))

    // }

    const userModelsQuery = query(chatterUserHistoryModelsRef, ...queries);
    
    const userModelsSnap = await getDocs(userModelsQuery);
    if (userModelsSnap.docs)
    { 
      if (userModelsSnap.docs.length === entry_limit + 1)
      {
        paginatedChatterUserHistoryModels.node.visibleStart = userModelsSnap.docs[userModelsSnap.docs.length - 1];
        paginatedChatterUserHistoryModels.node.nodeId = paginatedChatterUserHistoryModels.node.visibleStart.id.toString();
        paginatedChatterUserHistoryModels.nextNodeExists = true;
      }
    }

    console.log(`Snap length: ${userModelsSnap.docs.length}`);
    
    for (let i = 0; i < userModelsSnap.docs.length; i++)
    {
      console.log("Try");
      if (i === entry_limit)
        continue;

      console.log("Proceed");
      const doc = userModelsSnap.docs[i];
      const model = Utils.ParseDataToChatterUserHistoryModel(doc);

      chatterUserHistoryModels.push(model);
    }

    // purchaseLogs = purchaseLogs.reverse();
    paginatedChatterUserHistoryModels.chatterUserHistoryModels = chatterUserHistoryModels;

    return Promise.resolve(paginatedChatterUserHistoryModels);
  }

  // public static async GetChattersPaginated(lastVisible: QueryDocumentSnapshot<DocumentData> = null as any, entry_limit: number): Promise<PaginatedUserModels> 
  // {
  //   let paginatedChatterModels: PaginatedUserModels = new PaginatedUserModels();
  //   let chatterModels: UserModel[] = [];

  //   const firestore = getFirestore();
  //   let usersRef = collection(firestore, "users");
  //   let usersQuery = query(usersRef, limit(entry_limit + 1), where("userType", "==", UserTypes.TYPE_CHATTER), orderBy("createdAt"), startAt(lastVisible));

  //   let chattersSnap = await getDocs(usersQuery);
  //   paginatedChatterModels.lastVisiblePrev = chattersSnap.docs[0];
  //   if (chattersSnap.docs.length === entry_limit + 1)
  //   {
  //     paginatedChatterModels.nextPageExists = true;
  //     paginatedChatterModels.lastVisibleNext = chattersSnap.docs[chattersSnap.docs.length - 1];
  //   }

  //   for (let i = 0; i < chattersSnap.docs.length; i++)
  //   {
  //     if (i === entry_limit)
  //       continue;

  //     let doc = chattersSnap.docs[i];

  //     let model = Utils.ParseDataToUserModel(doc.data());

  //     chatterModels.push(model);
  //   }

  //   paginatedChatterModels.userModels = chatterModels;

  //   return Promise.resolve(paginatedChatterModels);
  // }

  public static async GetUserByName(name: string): Promise<UserModel[]> {
    const firestore = getFirestore();
    let usersRef = collection(firestore, "users");
    // let usersQuery = query(usersRef, where("displayName", "==", name), where("isDeleted", "==", false));
    let usersQuery = query(usersRef, where("displayName", "==", name));

    let userModels: UserModel[] = [];
    const userSnap = await getDocs(usersQuery);
    userSnap.forEach((doc) => {
      if(!doc.data().isDeleted) {
        let model = Utils.ParseDataToUserModel(doc.data());
        userModels.push(model);
      } 
    });
    
    return Promise.resolve(userModels);
  }

  public static async GetUserByNameForUserSearch(name: string): Promise<UserModel[]> {
    const firestore = getFirestore();
    let usersRef = collection(firestore, "users");
    // function getNextLetter( letter:string ) {
    //   if (letter === "z") {
    //     return "a";
    //   } else if (letter === "Z") {
    //     return "A";
    //   } else {
    //     return String.fromCharCode(letter.charCodeAt(letter.length-1) + 1);
    //   }
    // }
    // const nextLetter = getNextLetter(name);
    let usersQuery = query(usersRef, where("displayName", "==", name));

    let userModels: UserModel[] = [];
    const userSnap = await getDocs(usersQuery);
    
    userSnap.forEach((doc) => {
      console.log(`Doc Id: ${doc.id}`);
      let model = Utils.ParseDataToUserModel(doc.data());
      userModels.push(model);
    });
    
    return Promise.resolve(userModels);
  }
  public static async GetAllUserIDs(): Promise<string[]> {
    const firestore = getFirestore();
    let usersRef = collection(firestore, "users");
    let usersQuery = query(usersRef);

    let ids: string[] = [];
    const usersSnap = await getDocs(usersQuery);
    usersSnap.forEach((user) => {
      ids.push(user.id);
    });
    return Promise.resolve(ids);
  }

  //#region HTTP Requests
  public static async GetUserProviderId(uuid: string): Promise<string> {
    const url = `${HttpsHandler.BASE_URL}/${HttpsActionNames.RETRIEVE_USER_PROVIDER_DATA}?uuid=${uuid}`;

    let providerName = "";

    await HttpsHandler.SendGetRequest(url, false, (success, data, message) => {
      if (success)
      {
        providerName = data;
      }
    }, (success, message) => {

    });

    return Promise.resolve(providerName);
  }

  public static async GetUserEngagementByClientId(cid: string): Promise<number> {
    const pseudo_id = cid.split('.').slice(2).join('.');
    const url = `${HttpsHandler.BASE_URL}/${HttpsActionNames.RETRIEVE_USER_ENGAGEMENT_TIME_BY_PSEUDO_ID}?pseudoId=${pseudo_id}`;
  
    let engagementTime = 0;

    await HttpsHandler.SendGetRequest(url, false, (success, data, message) => {
      if (success)
      {
        engagementTime = data;
      }
    }, (success, message) => {

    });

    return Promise.resolve(engagementTime);
  }

  public static async GetUserEngagementByUserId(uuid: string): Promise<number> {
    const url = `${HttpsHandler.BASE_URL}/${HttpsActionNames.RETRIEVE_USER_ENGAGEMENT_TIME_BY_USER_ID}?userId=${uuid}`;
  
    let engagementTime = 0;

    await HttpsHandler.SendGetRequest(url, false, (success, data, message) => {
      if (success)
      {
        engagementTime = data;
      }
    }, (success, message) => {

    });

    return Promise.resolve(engagementTime);
  }

  public static async GetMessagesCountByUser(uuid: string, isChatter: boolean = false): Promise<any> {
    const url = `${HttpsHandler.BASE_URL}/${HttpsActionNames.RETRIEVE_USER_MESSAGES_COUNT}?uuid=${uuid}&isChatter=${isChatter}`;

    const messageCounts = {
      sent: 0,
      received: 0,
    };

    await HttpsHandler.SendGetRequest(url, false, (success, data, message) => {
      if (success)
      {
        messageCounts.sent = data.sent;
        messageCounts.received = data.received;
      }
    }, (success, message) => {

    });

    return Promise.resolve(messageCounts);
  }

  public static async GetMessagesCountByTime(uuid: string, startDate: string, endDate: string): Promise<number> {
    const url = `${HttpsHandler.BASE_URL}/${HttpsActionNames.RETRIEVE_USER_MESSAGES_COUNT_BY_TIME}?uuid=${uuid}&startDate=${startDate}&endDate=${endDate}`;

    let count = 0;
    await HttpsHandler.SendGetRequest(url, false, (success, data, message) => {
      if (success)
      {
        count = data as number;
      }
    }, (success, message) => {

    });

    return Promise.resolve(count);
  }
  //#endregion

  //#region User Notes
  public static async CreateUserNote(uuid: string, note: string) {
    const firestore = getFirestore();
    let currTime = serverTimestamp();
    let noteData = {
      uuid: uuid,
      text: note,
      createdAt: currTime,
      lastUpdated: currTime
    }
    let notesRef = collection(firestore, `users/${uuid}/notes`);
    await addDoc(notesRef, noteData);
  }

  public static async CreateProfileUserNote(profileId: string, uuid: string, note: string) {
    const firestore = getFirestore();
    let currTime = serverTimestamp();
    let noteData = {
      uuid: uuid,
      text: note,
      createdAt: currTime,
      lastUpdated: currTime
    }
    let notesRef = collection(firestore, `users/${uuid}/profile-notes/${profileId}/notes`);
    await addDoc(notesRef, noteData);
  }

  public static async DeleteUserNote(uuid: string, noteId: string) {
    let path = `users/${uuid}/notes`;
    const firestore = getFirestore();
    const notesRef = collection(firestore, path);
    const notesDoc = doc(notesRef, noteId);

    await deleteDoc(notesDoc);
  }

  public static async DeleteProfileUserNote(profileId: string, uuid: string, noteId: string) {
    let path = `users/${uuid}/profile-notes/${profileId}/notes`;
    const firestore = getFirestore();
    const notesRef = collection(firestore, path);
    const notesDoc = doc(notesRef, noteId);

    await deleteDoc(notesDoc);
  }

  public static async SoftDeleteUser(uuid: string) {
    let success = false;
    const firestore = getFirestore();
    const usersRef = collection(firestore, 'users');

    const usersDoc = doc(usersRef, uuid)
    
    const updateParams = {
      isDeleted: true
    }

    await updateDoc(usersDoc, updateParams).then(() => {
      success = true
    })    

    if (success)
      return Promise.resolve();
    else
      return Promise.reject();
  }

  public static async RestoreDeletedUser(uuid: string) {
    let success = false;
    const firestore = getFirestore();
    const usersRef = collection(firestore, 'users');

    const usersDoc = doc(usersRef, uuid)
    
    const updateParams = {
      isDeleted: false
    }

    await updateDoc(usersDoc, updateParams).then(() => {
      success = true
    })    

    if (success)
      return Promise.resolve();
    else
      return Promise.reject();
  }

  public static async DeleteUserPermanently(uuid: string) {
    let success = false;
    const firestore = getFirestore()
    const usersRef = collection(firestore, 'users');
    const usersDoc = doc(usersRef, uuid);
    await deleteDoc(usersDoc).then(() => {
      success = true
    })

    if(success) 
      return Promise.resolve()
    else
      return Promise.reject()
  }

  public static async EditUserNote(uuid: string, noteId: string, newText: string) {
    let path = `users/${uuid}/notes`;
    const firestore = getFirestore();
    const notesRef = collection(firestore, path);
    const notesDoc = doc(notesRef, noteId);

    let updateParams = {
      text: newText,
      lastUpdated: serverTimestamp()
    }

    await updateDoc(notesDoc, updateParams)
  }

  public static ListenForUserNotes(uuid: string, onUpdate: (notes: NoteModel[] | undefined) => void | null) {
    const firestore = getFirestore();
    const notesRef = collection(firestore, `users/${uuid}/notes`);
    const notesQuery = query(notesRef, orderBy(`lastUpdated`));
    const key = `${uuid}@notes`;
    FirestoreManager.AttachFirestoreListenerWithQuery(notesQuery, key, (snapshot) => {
      let notes: NoteModel[] = [];
      if (snapshot) {
        snapshot.forEach((doc) => {
          const data = doc.data();
          if (data) {
            var timestamp: Date = data.lastUpdated !== null ? data.lastUpdated.toDate() : undefined;
            let lastUpdated = timestamp !== undefined ? timestamp.toLocaleDateString() : "Loading";
            let notesData: NoteModel = {
              id: doc.id,
              uuid: data.uuid,
              text: data.text,
              createdAt: data.createdAt,
              lastUpdated: lastUpdated
            }
            notes.push(notesData);
          }
        })
      }

      onUpdate(notes);
    })
  }

  public static ListenForProfileUserNotes(profileId: string, uuid: string, onUpdate: (notes: NoteModel[] | undefined) => void | null) {
    const firestore = getFirestore();
    const notesRef = collection(firestore, `users/${uuid}/profile-notes/${profileId}/notes`);
    const notesQuery = query(notesRef, orderBy(`lastUpdated`));
    const key = `${uuid}@notes`;
    FirestoreManager.AttachFirestoreListenerWithQuery(notesQuery, key, (snapshot) => {
      let notes: NoteModel[] = [];
      if (snapshot) {
        snapshot.forEach((doc) => {
          const data = doc.data();
          if (data) {
            var timestamp: Date = data.lastUpdated !== null ? data.lastUpdated.toDate() : undefined;
            let lastUpdated = timestamp !== undefined ? timestamp.toLocaleDateString() : "Loading";
            let notesData: NoteModel = {
              id: doc.id,
              uuid: data.uuid,
              text: data.text,
              createdAt: data.createdAt,
              lastUpdated: lastUpdated
            }
            notes.push(notesData);
          }
        })
      }

      onUpdate(notes);
    })
  }

  public static StopListeningForUserNotes(uuid: string) {
    const key = `${uuid}@notes`;
    FirestoreManager.DetachFirestoreListener(key);
  }
  //#endregion

  public static async GetUserCredits(userId: string): Promise<number> {
    const firestore = getFirestore();
    const usersRef = collection(firestore, "users");
    const userDoc = doc(usersRef, userId);

    const userSnap = await getDoc(userDoc);
    let credits = -1;
    const data = userSnap.data();
    if (data) {
      if (data.credits)
        credits = data.credits;
      else
        credits = 0;
    }

    console.log(`Credits: ${credits}`);
    if (credits !== -1)
      return Promise.resolve(credits);
    else
      return Promise.reject();
  }

  public static async AddUserCredits(userId: string, creditsToAdd: number): Promise<number> {
    const firestore = getFirestore();
    const usersRef = collection(firestore, "users");
    const userDoc = doc(usersRef, userId);

    let success = false;
    let newCredits = 0
    // await this.GetUserCredits(userId).then(async (credits) => {
    //   newCredits = credits + creditsToAdd;
    //   let params = {
    //     credits: newCredits
    //   }

    //   await updateDoc(userDoc, params).then(() => {
    //     success = true;
    //   });
    // })
    await updateDoc(userDoc, {
      credits: increment(creditsToAdd),
    }).then(async () => {
      await this.GetUserCredits(userId).then((credits) => {
        newCredits = credits;
        success = true;
      })
    });

    if (success)
      return Promise.resolve(newCredits);
    else
      return Promise.reject();
  }

  public static async ListenForUserOnlineStatus(uuid: string, onUpdate: (status: boolean) => void | null)
  {
    const key = `${uuid}@online-status`;
    FirestoreManager.AttachFirestoreListener("users", uuid, key, (doc) => {
      let onlineStatus: boolean = false;
      if (doc)
      {
        const data = doc.data();
        if (data) {
          onlineStatus = data.onlineStatus ? data.onlineStatus as boolean : false;
        }
      }

      onUpdate(onlineStatus);
    });
  }

  public static async StopListeningForUserOnlineStatus(uuid: string)
  {
    const key = `${uuid}@online-status`;
    FirestoreManager.DetachFirestoreListener(key);
  }

  public static async ListenForUserCredits(uuid: string, onUpdate: (credits: number) => void | null)
  {
    const key = `${uuid}@credits`;
    FirestoreManager.AttachFirestoreListener("users", uuid, key, (doc) => {
      let credits: number = 0;
      if (doc) {
        const data = doc.data();
        if (data) {
          credits = data.credits ? data.credits : 0;
        }
      }

      onUpdate(credits);
    });
  }

  public static async RemoveProfilePhoto(uuid: string, params: any): Promise<void> {
    const firestore = getFirestore();
    const usersRef = collection(firestore, "users");
    const usersDoc = doc(usersRef, uuid);
    params = this.CheckValidDataInput(params);
    await updateDoc(usersDoc, params);
  }

  public static async StopListeningForUserCredits(uuid: string)
  {
    const key = `${uuid}@credits`;
    FirestoreManager.DetachFirestoreListener(key);
  }

  private static CheckValidDataInput(params: any): any {
    const allowedData: string[] =
      [
        "uuid",
        "createdAt",
        "displayName",
        "email",
        "lastLoggedIn",
        "photoURL",
        "gender",
        "country",
        "language",
        "userType",
        "profileReference"
      ]
    let filteredData: any = {};

    allowedData.forEach(element => {
      if (element in params) {
        filteredData[element] = params[element];
      }
    });

    return filteredData;
  }
}