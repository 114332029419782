import React, { Suspense, lazy } from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import GeneralAnalytics from '../modules/ChatProfile/GeneralAnalytics'
import DeletedUsers from '../modules/DeletedUsers/DeletedUsers'
import IndividualUserInformation from '../modules/individual-userInformation/IndividualUserInformation'
import SupportLogs from '../modules/Support/SupportLog'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const LiveChat = lazy(() => import('../modules/LiveChatProfile/LiveChat'))
  const ChatProfile = lazy(() => import('../modules/ChatProfile/ProfileCreation'))
  const ProfilePush = lazy(() => import('../modules/ChatProfile/ProfilePush'))
  const ProfileSelection = lazy(() => import('../modules/ChatProfile/ProfileSelection'))
  const ProfileApproval = lazy(() => import('../modules/ChatProfile/ProfileApproval'))
  const ProfileApprovalList = lazy(() => import('../modules/ChatProfile/ProfileApprovalList'))
  const ProfileDeclinedList = lazy(() => import('../modules/ChatProfile/ProfileDeclinedList'));
  const ProfileEdit = lazy(() => import('../modules/ChatProfile/ProfileEdit'))
  const ProfileCreatorList = lazy(() => import('../modules/ChatProfile/ProfileCreatorList'))
  const UserList = lazy(() => import('../modules/UserList/UserList'))
  const GiveCredits = lazy(() => import('../modules/GiveCredits/GiveCredits'))
  const CreateChatter = lazy(() => import('../modules/Misc/CreateChatter'))
  const ChatterList = lazy(() => import('../modules/Chat/ChattersList'))
  const ChattersRevenue = lazy(() => import('../modules/Chat/ChattersRevenue'))
  const UserSearch = lazy(() => import('../modules/UserSearch/UserSearch'))
  const SignupCount = lazy(() => import('../modules/ChatProfile/SignupCount'))
  const ChatLogs = lazy(() => import('../pages/dashboard/ChatLogs'))
  const Purchases = lazy(() => import('../pages/dashboard/Purchases'))
  const UsersList = lazy(() => import('../pages/dashboard/UsersList'))
  const UserCountByGenerationStatus = lazy(() => import('../modules/ChatProfile/UserCountByGenerationStatus'))
  const DoubleOptInCount = lazy(() => import('../modules/ChatProfile/DoubleOptInCount'))
  const LockedChatRooms = lazy(() => import('../pages/dashboard/LockedChatRooms'))
  const ProfilePicLogs = lazy(()=> import('../modules/Misc/ProfilePicLogs'))
  const Revenue = lazy(()=> import('../modules/ChatProfile/Revenue'))
  const Chatters = lazy(()=> import('../pages/dashboard/Chatters'));
  const IndividualChatterInformation = lazy(()=> import('../modules/individual-userInformation/IndividualChatterInformation'))
  const LPAnalytics = lazy(() => import('../modules/ChatProfile/LPAnalytics'))
  const Profile = lazy(() => import('../pages/dashboard/Profiles'));
  const IndividualChatterTriggers = lazy(() => import('../modules/individual-userInformation/IndividualChatterTriggers'));
  const ChatterUserHistory = lazy(() => import('../pages/dashboard/ChatterUserHistory'));
  const TriggerAnalytics = lazy(() => import('../modules/ChatProfile/TriggerAnalytics'));

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />
        <Route path='/live-chat/:site/:profile_id/:id' component={LiveChat} />
        <Route path='/profile-creation' component={ChatProfile} />
        <Route path='/profiles' component={ProfilePush} />
        <Route path='/profile-selection/:site/:id' component={ProfileSelection} />
        <Route path='/profile-approval/:id' component={ProfileApproval} />
        <Route path='/profile-approval-list' component={ProfileApprovalList} />
        <Route path='/profile-declined-list' component={ProfileDeclinedList} />
        <Route path='/profile-edit/:id' component={ProfileEdit} />
        <Route path='/profile-creator-list' component={ProfileCreatorList} />
        <Route path='/user-list' component={UserList} />
        <Route path='/give-credits' component={GiveCredits} />
        <Route path='/create-chatter' component={CreateChatter} />
        <Route path='/chatter-list' component={ChatterList} />
        <Route path='/chatters-revenue' component={ChattersRevenue} />
        <Route path ='/purchases' component={Purchases}/>
        <Route path ='/users-list' component={UsersList} />
        {/* <Route path ='/profiles' component={Profile} /> */}
        <Route path ='/individual-chatter-triggers' component={IndividualChatterTriggers} />
        <Route path ='/chatter-user-history' component={ChatterUserHistory} />

        <Route path='/user-search' component={UserSearch} />
        <Route path='/individual-user-information/:uid' component={IndividualUserInformation} />
        <Route path='/individual-chatter-information/:cid' component={IndividualChatterInformation} />

        <Route path='/signup-count' component={SignupCount} />
        <Route path='/general-analytics' component={GeneralAnalytics} />
        <Route path='/revenue' component={Revenue} />
        <Route path='/chatters' component={Chatters} />
        <Route path='/landing-page-analytics' component={LPAnalytics} />
        <Route path='/trigger-analytics' component={TriggerAnalytics} />

        <Route path='/users-by-status' component={UserCountByGenerationStatus} />
        
        <Route path='/double-opt-in-users' component={DoubleOptInCount} />

        <Route path='/chat-logs' component={ChatLogs} />
        <Route path='/locked-chat-rooms' component={LockedChatRooms} />
        <Route path='/support-logs' component={SupportLogs} />
        <Route path='/deleted-users' component={DeletedUsers} />
        <Route path='/profile-pic-logs' component={ProfilePicLogs} />
        <Route path='/auth' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}

// Definition of IProps
export interface IProps extends RouteComponentProps<{ id?: string, site?: string, profile_id?: string }> {
  // other properties
}
