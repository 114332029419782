/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Chat } from '../../../client/chat/Chat';
import { Utils } from '../../../client/system/Utils';
import { Profile, ProfileModel } from '../../../client/user/Profile';
import { User, UserModel, UserTag, UserTypes } from '../../../client/user/User';
import { MobileView, BrowserView } from 'react-device-detect';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import moment from 'moment';
import usePagination from '../../../hooks/usePagination';
import _ from 'lodash';
import { SessionHandler, SessionKeys } from '../../../client/system/SessionHandler';
import ReactCountryFlag from 'react-country-flag';
import { EnvironmentType, FirebaseApp } from '../../../client/FirebaseApp';
import { FirestoreManager } from '../../../client/system/FirestoreManager';
import { useSupportContext } from '../../../_metronic/layout/core/SupportProvider';

const SupportLogs: FC = () => {
    const chatRouteBase = Chat.CHAT_CHATTER_ROOM;
    const { supportLogs, isLoading } = useSupportContext();
    const history = useHistory();
    const [feedEntries, setFeedEntries] = useState<any[]>([]);
    const [pages, setPages] = useState<number>(0);

    const [userModels, setUserModels] = useState<UserModel[]>([]);
    const [profileModels, setProfileModels] = useState<ProfileModel[]>([]);

    const [userModelsLoaded, setUserModelsLoaded] = useState<boolean>(false);
    const [profileModelsLoaded, setProfileModelsLoaded] = useState<boolean>(false);

    const [isNoDataFound, setIsNoDataFound] = useState(false);

    const { jumpToPage, currentData } = usePagination(feedEntries, 10);

    const siteRef = FirebaseApp.environment === EnvironmentType.STAGING ? 'localhost' : 'flirtybum';

    moment.updateLocale('en', {
        relativeTime: {
            future: 'in %s',
            past: '%s ago',
            s: 'less than a minute',
            ss: 'leass than a minute',
            m: '1 minute',
            mm: '%d minutes',
            h: '1 hour', //this is the setting that you need to change
            hh: '%d hours',
            d: '1 day',
            dd: '%d days',
            w: '1 week',
            ww: '%d weeks',
            M: '1 month', //change this for month
            MM: '%d months',
            y: '1 year',
            yy: '%d years',
        },
    });

    const getUserModelById = (userId: string | undefined): UserModel | undefined => {
        return userModels.find((x) => {
            return x.uuid === userId;
        });
    };

    const getProfileModelById = (profileId: string | undefined): ProfileModel | undefined => {
        return profileModels.find((x) => {
            return x.id === profileId;
        });
    };

    useEffect(() => {
            if (supportLogs.length) {
                setFeedEntries(supportLogs);
                let length = supportLogs.length;
                const pageSize = 10;
                const pageCount = Math.ceil(length / pageSize);
                setPages(pageCount);
                GetProfileRecepients(supportLogs);
                GetUsers(supportLogs);
            }
            if (!isLoading && !supportLogs.length){
              setIsNoDataFound(true);
            }
        // jumpToPage(0);
    }, [siteRef, supportLogs, isLoading]);

    async function GetUsers(feedEntries: any) {
        let uuidList: string[] = [];
        if (feedEntries) {
            for (let i = 0; i < feedEntries.length; i++) {
                let id = feedEntries[i].user_id;
                if (
                    !uuidList.includes(id as string) &&
                    id !== undefined &&
                    id !== '' &&
                    id !== null
                ) {
                    uuidList.push(id as string);
                }
            }

            if (uuidList && uuidList.length > 0) {
                const res = [];
                for (let i = 0; i < uuidList.length; i += 10) {
                    const chunk = uuidList.slice(i, i + 10);
                    res.push(chunk);
                }
                let usersArr: UserModel[] = [];
                for (let i = 0; i < res.length; i++) {
                    let result = await User.GetUsersByIds(res[i]);
                    if (result) {
                        result.forEach((item) => {
                            usersArr.push(item);
                        });
                        setUserModels(usersArr);
                    }
                }
                setUserModelsLoaded(true);
            }
        }
    }

    async function GetProfileRecepients(feedEntries: any) {
        let uuidList: string[] = [];
        if (feedEntries) {
            for (let i = 0; i < feedEntries.length; i++) {
                let id = feedEntries[i].profile_id;
                if (!uuidList.includes(id as string)) {
                    uuidList.push(id as string);
                }
            }
            if (uuidList && uuidList.length > 0) {
                const res = [];
                for (let i = 0; i < uuidList.length; i += 10) {
                    const chunk = uuidList.slice(i, i + 10);
                    res.push(chunk);
                }
                let usersArr: ProfileModel[] = [];
                for (let i = 0; i < res.length; i++) {
                    let result = await Profile.GetProfilesByIds(res[i]);
                    if (result) {
                        result.forEach((item) => {
                            usersArr.push(item);
                        });
                        setProfileModels(usersArr);
                    }
                }
                setProfileModelsLoaded(true);
            }
        }
    }

    function GoToProfileSelection(user_id: string, siteOfOrigin: string, feedRoomId: string, tag: string) {
        SessionHandler.SetItem(SessionKeys.SESSION_FEED_ENTRY_ROOM_ID, feedRoomId);
        history.push({pathname: `/profile-selection/${siteOfOrigin}/${user_id}`, state: {tag: tag, append: ""}});
    }

    function ViewChatRoomAsAdmin(profile_id: string, user_id: string, siteOfOrigin: string, tag: string) {
        console.log('Try View Room as Admin');

        User.GetUserAccount(user_id).then((model) => {
            if (model) {
                const roomId = Chat.GetPrivateChatRoomId(profile_id, user_id);
                const key = `${roomId}@messages`;
                FirestoreManager.DetachFirestoreListener(key);
                User.SetChatterProfile(getProfileModelById(profile_id) as ProfileModel);

                history.push(`${chatRouteBase}/${siteOfOrigin}/${profile_id}/${user_id}?tag=${tag}&tagAppend=`);
            }
        });
    }

    function TryViewChatRoom(
        profile_id: string,
        user_id: string,
        siteOfOrigin: string,
        tag: string,
        roomId: string
    ) {
        switch (User.Model?.userType) {
            case UserTypes.TYPE_ADMIN:
                if (
                    tag === UserTag.NEW_MESSAGE ||
                    tag === UserTag.LIKED_PROFILE ||
                    tag === UserTag.SUPPORT
                ) {
                    ViewChatRoomAsAdmin(profile_id, user_id, siteOfOrigin, tag);
                } else if (
                    tag === UserTag.LOGGED_IN ||
                    tag === UserTag.LOGGED_OUT ||
                    tag === UserTag.NEW_USER ||
                    tag === UserTag.CLICKED_GET_CREDITS
                ) {
                    GoToProfileSelection(user_id, siteOfOrigin, roomId, tag );
                }
                break;
            case UserTypes.TYPE_MODERATOR:
                if (
                    tag === UserTag.NEW_MESSAGE ||
                    tag === UserTag.LIKED_PROFILE ||
                    tag === UserTag.SUPPORT
                ) {
                    ViewChatRoomAsAdmin(profile_id, user_id, siteOfOrigin, tag);
                } else if (
                    tag === UserTag.LOGGED_IN ||
                    tag === UserTag.LOGGED_OUT ||
                    tag === UserTag.NEW_USER ||
                    tag === UserTag.CLICKED_GET_CREDITS
                ) {
                    GoToProfileSelection(user_id, siteOfOrigin, roomId, tag);
                }
                break;
            case UserTypes.TYPE_CHATTER:
                if(tag === UserTag.SUPPORT){
                    ViewChatRoomAsAdmin(profile_id, user_id, siteOfOrigin, tag);
                }
                break;
        }
    }

    const handleChange = (event: any, value: any) => {
        jumpToPage(value - 1);
    };

    return (
        <>
            <div className={`card`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5 custom-card-header'>
                    {User.Model?.userType === UserTypes.TYPE_ADMIN && (
                        <div className='adminFeed-filters'>
                            <div className='form-group'></div>
                        </div>
                    )}
                </div>
                {/* Filter Collapse Data end */}

                <div className='card-body py-3 custom-card-body'>
                    <MobileView>
                        <div className='AdminFeed-container'>
                            {
                            isNoDataFound ?
                            <div>
                              No Data Found
                            </div>
                            :
                            currentData.length > 0 &&
                                currentData?.map((feedEntry: any) => {
                                    console.log(`Support Data: ${JSON.stringify(feedEntry)}`)
                                    let userId = feedEntry.user_id;
                                    let profileId = feedEntry.profile_id;
                                    let user = getUserModelById(userId) as UserModel;
                                    let profile = getProfileModelById(profileId);
                                    let senderPhoto = _.get(
                                        user,
                                        'photoURL',
                                        '/media/avatars/150-2.jpg'
                                    );
                                    let recepientPhoto = _.get(
                                        profile,
                                        'photoURL',
                                        '/media/avatars/150-2.jpg'
                                    );
                                    let recepientName = _.get(profile, 'displayName', 'Profile');
                                    let senderName = _.get(user, 'displayName', 'Profile');
                                    let countryCode = user
                                        ? user.geolocation?.get('countryCode')
                                        : ('Loading' as string);
                                    let date = feedEntry.last_updated.toDate();
                                    let formattedDate = moment(date, 'MM/DD/YYYY - hh:mm:ss A');
                                    let userLocation = user
                                        ? user.geolocation?.get('countryName')
                                        : 'Loading';
                                    let isNewTag = feedEntry.isNew;
                                    let key = `feed-row@${Utils.GenerateRandomID()}`;
                                    return (
                                        <div key={key} className='AdminFeed-grid'>
                                            <div className='Profile-info Recepient-info'>
                                                <div className='profileThumbnail'>
                                                    {<img src={recepientPhoto} alt='' />}
                                                </div>

                                                <div className='Thumbnail-details'>
                                                    <p>Supporter</p>
                                                    <h3>{recepientName}</h3>
                                                    <p>{recepientName}</p>
                                                </div>
                                            </div>

                                            <div className='Profile-info sender-info'>
                                                <div className='profileThumbnail'>
                                                    <img src={senderPhoto} alt='' />
                                                </div>

                                                <div className='Thumbnail-details'>
                                                    <p>User</p>
                                                    <h3>{senderName}</h3>
                                                </div>
                                            </div>

                                            <div className='infoInner'>
                                                <p>Received</p>
                                                <h3>{formattedDate.fromNow()}</h3>
                                            </div>

                                            <div className='infoInner'>
                                                <p>User Location</p>
                                                <h3>
                                                    {countryCode ? (
                                                        <ReactCountryFlag
                                                            countryCode={countryCode}
                                                            svg
                                                        />
                                                    ) : (
                                                        ''
                                                    )}{' '}
                                                    {userLocation}
                                                </h3>
                                            </div>

                                            <div className='infoBtns'>
                                                <button
                                                    className='btn btn-primary'
                                                    type='button'
                                                    disabled={!profile || !user}
                                                    onClick={() => {
                                                        TryViewChatRoom(
                                                            profileId as string,
                                                            userId as string,
                                                            siteRef,
                                                            'SUPPORT',
                                                            feedEntry.roomId
                                                        );
                                                    }}
                                                >
                                                    Reply
                                                </button>
                                            </div>

                                            {isNewTag && <div>
                                                <button className='btn btn-success btn-sm' style={{borderRadius:'10px'}}>
                                                    NEW
                                                </button>
                                            </div>}
                                        </div>
                                    );
                                })}
                        </div>
                    </MobileView>

                    <BrowserView>
                        {/* begin::Table container */}
                        <div className='table-responsive yy'>
                            {/* begin::Table */}
                            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='fw-bolder text-muted'>
                                        <th className='min-w-150px'>Supporter</th>
                                        <th className='min-w-140px'>User</th>
                                        <th className='min-w-100px'>Received</th>
                                        <th className='min-w-120px'>User Location</th>
                                        <th className='min-w-100px'>Action</th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    {
                                    isNoDataFound ?
                                    <div>
                                      No data found
                                    </div>
                                    :
                                    currentData.length > 0 &&
                                        currentData?.map((feedEntry: any) => {
                                            let userId = feedEntry.user_id;
                                            let profileId = feedEntry.profile_id;
                                            let user = getUserModelById(userId) as UserModel;
                                            let profile = getProfileModelById(profileId);
                                            let senderPhoto = _.get(
                                                user,
                                                'photoURL',
                                                '/media/avatars/150-2.jpg'
                                            );
                                            let recepientPhoto = _.get(
                                                profile,
                                                'photoURL',
                                                '/media/avatars/150-2.jpg'
                                            );
                                            let recepientName = _.get(
                                                profile,
                                                'displayName',
                                                'Profile'
                                            );
                                            let senderName = _.get(user, 'displayName', 'Profile');
                                            let countryCode = user
                                                ? user.geolocation?.get('countryCode')
                                                : ('Loading' as string);
                                            let date = feedEntry.last_updated.toDate();
                                            let formattedDate = moment(
                                                date,
                                                'MM/DD/YYYY - hh:mm:ss A'
                                            );
                                            let userLocation = user
                                                ? user.geolocation?.get('countryName')
                                                : 'Loading';
                                            let isNewTag = feedEntry.isNew;
                                            let key = `feed-row@${Utils.GenerateRandomID()}`;
                                            return (
                                                <tr key={key}>
                                                    <td className='text-dark fw-bolder text-hover-primary fs-6'>
                                                            <table>
                                                                <td>
                                                                    <img
                                                                        src={recepientPhoto}
                                                                        className='PushImageIcon'
                                                                        alt='Images'
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <tr>{recepientName}</tr>
                                                                    <tr className='text-muted'>
                                                                        {'recepientType'}
                                                                    </tr>
                                                                </td>
                                                            </table>
                                                    </td>
                                                    <td className='text-dark fw-bolder text-hover-primary fs-6'>
                                                        <table>
                                                            <td>
                                                                <img
                                                                    src={senderPhoto}
                                                                    className='PushImageIcon'
                                                                    alt='Images'
                                                                />
                                                            </td>
                                                            <td>
                                                                <tr>{senderName}</tr>
                                                                <tr className='text-muted'>
                                                                    {'senderType'}
                                                                </tr>
                                                            </td>
                                                        </table>
                                                    </td>
                                                    <td className='text-dark fw-bolder text-hover-primary fs-6'>
                                                        {formattedDate.fromNow()}
                                                    </td>
                                                    <td className='text-dark fw-bolder text-hover-primary fs-6'>
                                                        <div className='d-flex gap-2 align-items-center'>
                                                            {countryCode ? (
                                                                <ReactCountryFlag
                                                                    countryCode={countryCode}
                                                                    svg
                                                                />
                                                            ) : (
                                                                ''
                                                            )}{' '}
                                                            {userLocation}
                                                        </div>
                                                    </td>
                                                    <td className='text-dark fw-bolder text-hover-primary fs-6'>
                                                        <button
                                                            className='btn btn-primary'
                                                            type='button'
                                                            data-kt-element='send'
                                                            disabled={!profile || !user}
                                                            onClick={() => {
                                                                TryViewChatRoom(
                                                                    profileId as string,
                                                                    userId as string,
                                                                    siteRef,
                                                                    'SUPPORT',
                                                                    feedEntry.roomId
                                                                );
                                                            }}
                                                        >
                                                            Reply
                                                        </button>
                                                    </td>
                                                   {isNewTag && <td>
                                                            <button className='btn btn-success btn-sm' style={{borderRadius:'10px'}}>NEW</button>
                                                    </td>}
                                                </tr>
                                            );
                                        })}
                                </tbody>

                                {/* end::Table body */}
                            </table>

                            {!isNoDataFound  && userModels.length > 0 && (
                                <Stack
                                    spacing={2}
                                    sx={{
                                        width: '100%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        my: 5,
                                    }}
                                >
                                    <Pagination
                                        count={pages}
                                        onChange={handleChange}
                                        defaultPage={1}
                                        boundaryCount={2}
                                    />
                                </Stack>
                            )}
                            {/* end::Table */}
                        </div>
                        {/* end::Table container */}
                    </BrowserView>
                </div>
                {/* begin::Body */}
            </div>
        </>
    );
};

export default SupportLogs;
