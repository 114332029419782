/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from "react";
import { Chat } from "../../../client/chat/Chat";
import { Utils } from "../../../client/system/Utils";
import { Profile, ProfileModel } from "../../../client/user/Profile";
import { User, UserModel, UserTag, UserTypes } from "../../../client/user/User";
import {
  ChatRoomModel,
  toAbsoluteUrl,
} from "../../../_metronic/helpers";
import { MobileView, BrowserView } from "react-device-detect";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import usePagination from "../../../hooks/usePagination";
import { EnvironmentType, FirebaseApp } from "../../../client/FirebaseApp";

const LockedChatRooms: FC = () => {
  //const roomId = Chat.CHATTER_REQUEST_FEED;

  const [forceUpdate, setForceUpdate] = useState<boolean>(false);
  const [chatRooms, setChatRooms] = useState<ChatRoomModel[]>([]);
  const [pages, setPages] = useState<number>(0);

  const [userModels, setUserModels] = useState<UserModel[]>([]);
  const [profileModels, setProfileModels] = useState<ProfileModel[]>([]);

  const [userModelsLoaded, setUserModelsLoaded] = useState<boolean>(false);
  const [profileModelsLoaded, setProfileModelsLoaded] =
    useState<boolean>(false);

  const { jumpToPage, currentData } = usePagination(chatRooms, 10);

  const getUserModelById = (
    userId: string | undefined
  ): UserModel | undefined => {
    return userModels.find((x) => {
      return x.uuid === userId;
    });
  };

  const getProfileModelById = (
    profileId: string | undefined
  ): ProfileModel | undefined => {
    return profileModels.find((x) => {
      return x.id === profileId;
    });
  };

  useEffect(() => {
    jumpToPage(0);

    Chat.GetLockedChatRooms().then((data) => {
      if (data)
      {
        setChatRooms(data);
        GetUsers(data);
        GetProfileRecepients(data);
        let length = data.length;
        const pageSize = 10;
        const pageCount = Math.ceil(length / pageSize);
        setPages(pageCount);
      }
    });
  }, [forceUpdate]);

  async function GetUsers(chatRooms: ChatRoomModel[] | undefined) {
    //console.log(`Messages Length ${messages?.length}`);
    let uuidList: string[] = [];
    //   console.log(feedEntries);
    if (chatRooms) {
      for (let i = 0; i < chatRooms.length; i++) {
        let entry = chatRooms[i];
        let id = entry.userId;

        if (
          !uuidList.includes(id as string) &&
          id !== undefined &&
          id !== "" &&
          id !== null
        ) {
          uuidList.push(id as string);
        }
      }

      if (uuidList && uuidList.length > 0) {
        // console.log(uuidList, "users arr.................", feedEntries.length);
        const res = [];
        for (let i = 0; i < uuidList.length; i += 10) {
          const chunk = uuidList.slice(i, i + 10);
          res.push(chunk);
        }
        let usersArr: UserModel[] = [];
        for (let i = 0; i < res.length; i++) {
          let result = await User.GetUsersByIds(res[i]);
          if (result) {
            result.forEach((item) => {
              usersArr.push(item);
            });
            setUserModels(usersArr);
          }
        }
        setUserModelsLoaded(true);
      }
    }
  }

  async function GetProfileRecepients(
    chatRooms: ChatRoomModel[] | undefined
  ) {
    let uuidList: string[] = [];
    if (chatRooms) {
      for (let i = 0; i < chatRooms.length; i++) {
        let entry = chatRooms[i];
        let id = entry.profileId;
        
        if (!uuidList.includes(id as string)) {
          uuidList.push(id as string);
        }
      }

      if (uuidList && uuidList.length > 0) {
        const res = [];
        for (let i = 0; i < uuidList.length; i += 10) {
          const chunk = uuidList.slice(i, i + 10);
          res.push(chunk);
        }
        let usersArr: ProfileModel[] = [];
        for (let i = 0; i < res.length; i++) {
          let result = await Profile.GetProfilesByIds(res[i]);
          if (result) {
            result.forEach((item) => {
              usersArr.push(item);
            });
            setProfileModels(usersArr);
          }
        }
        setProfileModelsLoaded(true);
      }
    }
  }

  const unlockChatRoom = async (roomId: string) => {
    const siteRef = FirebaseApp.environment === EnvironmentType.STAGING ? "localhost" : "flirtybum";
    let lastMessage = await Chat.getLastMessage(roomId,siteRef);

    if (lastMessage && !lastMessage.isChatter){
      let feedEntryData = {
        message: lastMessage
      }
      const params = {
        siteOfOrigin: siteRef,
        roomId: roomId,
        userId: lastMessage.uuid,
        message: lastMessage,
      };
      await Chat.SendToAdminFeed(roomId, feedEntryData, siteRef, UserTag.UNLOCKED_ROOM);
      await Chat.SendToChatterRequestsFeed(params, true, siteRef, UserTag.UNLOCKED_ROOM);
    }
    await Chat.SetRoomStatus(roomId, false, siteRef).then(() => {
      setForceUpdate(!forceUpdate)
    });
  };

  const handleChange = (event: any, value: any) => {
    jumpToPage(value-1);
  };

  return (
    <>
      <div className={`card`}>
        {/* begin::Header */}
        {/* <div className="card-header border-0 pt-5 custom-card-header">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">Locked Chat Rooms</span>
          </h3>
        </div> */}
        {/* Filter Collapse Data end */}

        <div className="card-body py-3 custom-card-body">
          <MobileView>
            <div className="AdminFeed-container">
              {
                currentData.length && currentData?.map((chatRoom: ChatRoomModel, index: any) => {
                // console.log(feedEntry);
                let user = getUserModelById(chatRoom.userId) as UserModel;
                // console.log(user);
                let profile = getProfileModelById(chatRoom.profileId) as ProfileModel;
                // console.log(profile);

                let userPhoto = user
                  ? user.photoURL
                  : "/media/avatars/150-2.jpg";
                let profilePhoto = profile
                  ? profile.photoURL
                  : "/media/avatars/150-2.jpg";

                let userName = user ? user.displayName : "User";
                let profileName = profile ? profile.displayName : "Profile";


                let key = `chat-room-row@${Utils.GenerateRandomID()}`;
                if (user) {
                  return (
                    <div key={key} className="AdminFeed-grid">
                      <div className="Profile-info Recepient-info">
                        <div className="profileThumbnail">
                          <img src={profilePhoto} alt="" />
                        </div>

                        <div className="Thumbnail-details">
                          <p>Profile</p>
                          <h3>{profileName}</h3>
                        </div>
                      </div>

                      <div className="Profile-info sender-info">
                        <div className="profileThumbnail">
                          <img src={userPhoto} alt="" />
                        </div>

                        <div className="Thumbnail-details">
                          <p>User</p>
                          <h3>{userName}</h3>
                        </div>
                      </div>

                      <div className="infoBtns">
                        <button
                          className='btn btn-primary'
                          type="button"
                          disabled={!profile || !user}
                          onClick={() => {unlockChatRoom(chatRoom.id)}}
                        >
                          Unlock Room
                        </button>
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </MobileView>

          <BrowserView>
            {/* begin::Table container */}
            <div className="table-responsive yy">
              {/* begin::Table */}
              <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                {/* begin::Table head */}
                <thead>
                  <tr className="fw-bolder text-muted">
                    <th className="min-w-120px">Profile</th>
                    <th className="min-w-120px">User</th>
                    <th className="min-w-100px">Action</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {
                    currentData.length>0 && currentData?.map((chatRoom: ChatRoomModel) => {
                      // console.log(feedEntry);
                      let user = getUserModelById(chatRoom.userId) as UserModel;
                      // console.log(user);
                      let profile = getProfileModelById(chatRoom.profileId) as ProfileModel;
                      // console.log(profile);

                      let userPhoto = user
                        ? user.photoURL
                        : "/media/avatars/150-2.jpg";
                      let profilePhoto = profile
                        ? profile.photoURL
                        : "/media/avatars/150-2.jpg";

                      let userName = user ? user.displayName : "User";
                      let profileName = profile ? profile.displayName : "Profile";


                      let key = `chat-room-row@${Utils.GenerateRandomID()}`;
                    if (user)
                      return (
                        <tr key={key}>
                          <td className="text-dark fw-bolder text-hover-primary fs-6">
                            <table>
                              <td>
                                <img
                                  src={profilePhoto}
                                  className="PushImageIcon"
                                  alt="Images"
                                />
                              </td>
                              <td>
                                <tr>{profileName}</tr>
                              </td>
                            </table>
                          </td>
                          <td className="text-dark fw-bolder text-hover-primary fs-6">
                            <table>
                              <td>
                                <img
                                  src={userPhoto}
                                  className="PushImageIcon"
                                  alt="Images"
                                />
                              </td>
                              <td>
                                <tr>{userName}</tr>
                              </td>
                            </table>
                          </td>
                          <td className="text-dark fw-bolder text-hover-primary fs-6">
                            <button
                              className='btn btn-primary'
                              type="button"
                              data-kt-element="send"
                              disabled={!profile || !user}
                              onClick={() => { unlockChatRoom(chatRoom.id) }}
                            >
                              Unlock Room
                            </button>
                          </td>
                        </tr>
                      );
                  })}
                </tbody>

                {/* end::Table body */}
              </table>

              {/* <nav aria-label="Page navigation example" className='w-100 mb-5 mt-5'>
                      <ul className="pagination">
                      <li className={currentPage === 1 ? "page-item disabled": "page-item"}   onClick={() => goPrevious()} ><a className="page-link" href='javascript:void(0);'>Previous</a></li>
                      {pages.map((value) =>
                      { return <li className={currentPage===value ? "page-item active" : "page-item"}  ><a className="page-link" href='javascript:void(0);'  onClick={()=> goToPage(value)} >{value}</a></li>}
                       )}
                      <li className={currentPage === pages[pages.length-1]? "page-item disabled" : "page-item"}  onClick={() => goNext()}><a className="page-link" href='javascript:void(0);'>Next</a></li>
                    </ul>
                  </nav> */}
             {userModels.length>0 && <Stack
                spacing={2}
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  my: 5,
                }}
              >
                <Pagination
                  count={pages}
                  onChange={handleChange}
                  defaultPage={1}
                  boundaryCount={2}
                />
              </Stack>}
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </BrowserView>
        </div>
        {/* begin::Body */}
      </div>
    </>
  );
};

export default LockedChatRooms;