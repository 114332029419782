import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import { ChatterUserHistoryModel, PurchaseLogModel } from "../../_metronic/helpers/dataExamples";
import { ProfileModel } from "../user/Profile";
import ac from './../user/Permissions';
import {User, UserModel} from './../user/User'
import { ProductModel, DiscountModel } from "./Products";
import moment from 'moment';
// import { countries } from 'countries-list';

// interface countriesWithCurrency{
//   country_name: string[],
//   currency: string
// }

export class Utils
{
  static MyInterests = [
    "Reading",
    "Writing",
    "Painting",
    "Singing",
    "Dancing",
    "Guitar",
    "Piano",
    "Cycling",
    "Hiking",
    "Running",
    "Tennis",
    "Golf",
    "Exercising",
    "Computer Games",
    "Board Games",
    "Cooking",
    "Baking",
    "Photography",
    "Fishing",
    "Surfing",
    "Gardening",
    "Blogging",
    "Languages",
    "Arts and Crafts",
    "Knitting",
    "Drawing"
  ]

  static SexualInterests = [
    "BDSM",
    "Sadism",
    "Ropes and Bondage",
    "Voyeurism",
    "Exhibitionism",
    "Roleplay",
    "Dirty Talk",
    "Nipple Play",
    "69s",
    "Blow Jobs",
    "Eating Pussy",
    "Cuckolding",
    "Orgasm Control",
    "Humiliation",
    "Foot Fetishism",
    "Whips"
  ]

  static AllInterests = this.MyInterests.concat(this.SexualInterests);

  static ChitChatProfiles = [
    "BFWD2wuEU3yB0q1y6q1c",
    "cknVFe3dx0WpBNq2qnYR",
    "RbfH8HTxm9I7jhjXOvog",
    "4QKWn0SPFIy2s7xoJ1lH",
    "1kSRkum3PnwC7CVQltUA",
    "Zbn4Ej39Zrt82oHwU64W",
    "tCtoDYiE79xwPPSXPkB4",
    "7KZJ4Z7NYOTZgFPwqq4e"
  ]

  static ChatterUuids = [
    "uVuXxHrYE7bJIUeQ8Oizr9o6wgI3", // Carol
    "jUrOWjmxwCcVN38hQB6XvKUqDqT2", // Myra
    "BpCozLwn7YXgx11Ja8qQWgoxDhT2", // Brei
    "ojd32CrcGQMaz6qfbX20K7BwWsg1", // Andrea
    "3mfddsRKYXYn2EDGeQSKhIfWK102", // Abigale
  ]
  // public static GetCountryList(): string[] {
  //   const allCountries = this.GetCountriesWithCurrency();
  //   let countryList: string[] = [];
  //   for(let currency of allCountries){
  //     let countryData = currency.country_name;
  //     countryList.push(...countryData);
  //   }
  //   return countryList;
  // }

  // public static GetCountryListByCurrency(currency?:string): string[] {
  //   const allCountries = Object.entries(countries);
  //   const singleCurrencyWithCountries = _.map(_.filter(allCountries,(data:any)=>data[1].currency===currency), (country:any)=>country[1].name);
  //   return singleCurrencyWithCountries;
  // }

  // public static GetCountriesWithCurrency(): countriesWithCurrency[] {
  //   const eurCountries = this.GetCountryListByCurrency('EUR');
  //   const nzdCountries = this.GetCountryListByCurrency('NZD');
  //   const cadCountries = this.GetCountryListByCurrency('CAD');
  //   const audCountries = this.GetCountryListByCurrency('AUD');
  //   const gbpCountries = this.GetCountryListByCurrency('GBP');
  //   const usdCountries = this.GetCountryListByCurrency('USD');
  //   return [
  //     {
  //       country_name: eurCountries,
  //       currency:'EUR'
  //     },
  //     {
  //       country_name: gbpCountries,
  //       currency:'GBP'
  //     },
  //     {
  //       country_name: audCountries,
  //       currency:'AUD'
  //     },
  //     {
  //       country_name: nzdCountries,
  //       currency:'NZD'
  //     },
  //     {
  //       country_name:['South America','Central America','North America','United States', ...usdCountries],
  //       currency:'USD'
  //     },
  //     {
  //       country_name: cadCountries,
  //       currency:'CAD'
  //     }
  //   ]
  // }
  
  public static GetCurrencySymbolFromString(currency: string): string
  {
    const options = { style: 'currency', currency: currency };
    const numberFormat = new Intl.NumberFormat('EN-Us', options);
    const currency_symbol = numberFormat.formatToParts(0)? numberFormat.formatToParts(0)[0].value : '$';

    return currency_symbol;
  }

  // 0 Indexed. Exclusive of value of `max`
  public static GetRandomInt(max:number) {
    return Math.floor(Math.random() * max);
  }

  private static FourLengthRandomString(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  public static GenerateRandomID(): string {
    let first = this.FourLengthRandomString();
    let second = this.FourLengthRandomString();

    return first + "-" + second;
  }

  public static GetFirestoreImageRef(url: string): string 
  {

    //console.log(`Image Url: ${url}`);
    let temp = url.replace("https://firebasestorage.googleapis.com/v0/b/chat-hub-1.appspot.com/o/", "");
    //console.log(`Update: ${temp}`);
    temp = temp.replaceAll("%2F", "/");
    temp = temp.replaceAll("%20", " ")
    //console.log(`Update 2: ${temp}`);
    let tempArr = temp.split("?alt");
    //console.log(`Update 3: ${tempArr[0]}`);
    return tempArr[0];
  }

  public static ConvertBirthday(birthdate: any): Date
  {
    let birthDate = new Date();
    if (typeof birthdate === "string")
      birthDate = new Date(birthdate)
    else if (typeof birthdate === "object")
      birthDate = birthdate.toDate();

    return birthDate;
  }

  public static ConvertDate(convertDate: any): Date
  {
    let newDate = new Date();
    if (typeof convertDate === "string")
      newDate = new Date(convertDate)
    else if (typeof convertDate === "object")
      newDate = convertDate.toDate();

    return newDate;
  }

  public static ConvertYYYYMMDDDate(dateString: string): string
  {
    const newDate = moment(dateString, "YYYYMMDD");
    return newDate.format("LL");
  }

  public static ComputeAge(birthdate: any) : number
  {
    if (!birthdate)
      return -1;

    var today = new Date();
    let birthDate = new Date();
    if (typeof birthdate === "string")
      birthDate = new Date(birthdate)
    else if (typeof birthdate === "object")
      birthDate = birthdate.toDate();

    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    //console.log(`Age Now: ${age_now}`)
    return age_now;
  }

  public static DetermineAuth(auth: string): string 
  {
    let newAuth = auth;

    switch (auth)
    {
      case "password":
      case "":
        newAuth = "Email/Password";
        break;
    }

    return newAuth;
  }

  public static MsToTime(ms: number) {
    let seconds = (ms / 1000).toFixed(1);
    let minutes = (ms / (1000 * 60)).toFixed(1);
    let hours = (ms / (1000 * 60 * 60)).toFixed(1);
    let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
    if (Number(seconds) < 60) return seconds + " Sec";
    else if (Number(minutes) < 60) return minutes + " Min";
    else if (Number(hours) < 24) return hours + " Hrs";
    else return days + " Days"
  }

  public static yyyymmdd(thisDate: Date) {
    let y = thisDate.getFullYear();
    var m = thisDate.getMonth() + 1;
    var d = thisDate.getDate();
    var mm = m < 10 ? '0' + m : m;
    var dd = d < 10 ? '0' + d : d;
    return '' + y + mm + dd;
}

  public static ParseDataToUserModel(data: DocumentData): UserModel
  {
    let userModel = new UserModel(
      {
        uuid: data.uuid,
        displayName: data.displayName,
        email: data.email,
        photoURL: data.photoURL,
        profileReference: data.profileReference,
        birthday: this.ConvertBirthday(data.birthday),
        age: Utils.ComputeAge(data.birthday),
        gender: data.gender,
        weight: data.weight,
        height: data.height,
        maritalStatus: data.maritalStatus,
        bodyType: data.bodyType,
        ethnicity: data.ethnicity,
        hairColor: data.hairColor,
        eyeColor: data.eyeColor,
        sexualOrientation: data.sexualOrientation,
        country: data.country,
        language: data.language,
        aboutMe: data.aboutMe,
        lookingFor: data.lookingFor,
        tags: data.tags,
        myInterests: data.myInterests ? data.myInterests : [],
        sexualInterests: data.sexualInterests ? data.sexualInterests : [],
        userType: data.userType ? data.userType : '',
        subType: data.subType ? data.subType : "",
        siteOfOrigin: data.siteOfOrigin ? data.siteOfOrigin : '',
        likedProfiles: data.likedProfiles,
        profilesLikedYou: data.profilesLikedYou,
        lastLoggedIn: data.lastLoggedIn? data.lastLoggedIn : null,
        createdAt: data.createdAt,
        lastActive: data.lastActive ? data.lastActive : null,
        createdProfiles: data.createdProfiles,
        credits: data.credits,
        geolocation: data.geolocation ? new Map([
          ["countryCode", data.geolocation.countryCode],
          ["countryName", data.geolocation.countryName],
          ["city", data.geolocation.city],
          ["state", data.geolocation.state]
        ]) : null as any,
        ageOfChattedProfiles: data.ageOfChattedProfiles,
        raceOfChattedProfiles: data.raceOfChattedProfiles,
        isDeleted: data.isDeleted? data.isDeleted : false,
        isPaidUser: data.isPaidUser ? data.isPaidUser : false,
        isTestAccount: data.isTestAccount ? data.isTestAccount : false,
        isAutoGenerated: data.isAutoGenerated ? data.isAutoGenerated : false,
        hasVisitedSite: data.isAutoGenerated ? data.isAutoGenerated : false,
        chattingStatus : data.chattingStatus,
        profileRefrence: data.profileReference ? data.profileReference : '',
        isProfileCompleted: data.isProfileCompleted?data.isProfileCompleted:false,
        verificationRewardsReceived: data.verificationRewardsReceived ? data.verificationRewardsReceived : false,
        pageLocation: data.pageLocation ? data.pageLocation : "N/A",
        authProvider: this.DetermineAuth(data.authProvider),
        clientId: data.clientId ? data.clientId : "",
        onlineStatus: data.onlineStatus ? data.onlineStatus : false,
        totalTimeOnline: data.totalTimeOnline ? data.totalTimeOnline : 0,
        token: data.token,
        message_received: data.message_received ? data.message_received : 0,
        message_sent: data.message_sent ? data.message_sent : 0,
        countOfUserMessage: data.countOfUserMessage ? data.countOfUserMessage : 0,
        timeDiffOfChatterReply: data.timeDiffOfChatterReply ? data.timeDiffOfChatterReply : 0,
        optInStatus: data.optInStatus ? data.optInStatus : "soi",
        userChatHistory: data.userChatHistory ? data.userChatHistory : [],
      });
      
      return userModel;
  }

  public static ParseDataToProfileModel(doc: QueryDocumentSnapshot<DocumentData>): ProfileModel {
    let data = doc.data();
    let profileModel = new ProfileModel(
      {
        id: doc.id,
        displayName: data.displayName,
        photoURL: data.photoURL,
        controllerUuid: data.controllerUuid,
        birthday: this.ConvertBirthday(data.birthday),
        age: Utils.ComputeAge(data.birthday),
        gender: data.gender,
        weight: data.weight,
        height: data.height,
        maritalStatus: data.maritalStatus,
        bodyType: data.bodyType,
        ethnicity: data.ethnicity,
        hairColor: data.hairColor,
        eyeColor: data.eyeColor,
        sexualOrientation: data.sexualOrientation,
        city: data.city,
        country: data.country,
        language: data.language,
        aboutMe: data.aboutMe,
        lookingFor: data.lookingFor,
        tags: data.tags ? data.tags : [],
        myInterests: data.myInterests ? data.myInterests : [],
        sexualInterests: data.sexualInterests ? data.sexualInterests : [],
        noOfTimePrivatePicReq: data.noOfTimePrivatePicReq ? data.noOfTimePrivatePicReq : 0,
        firstMessageReceiveCount : data.firstMessageReceiveCount ? data.firstMessageReceiveCount : 0,
        noOfFirstRepliesCount : data.noOfFirstRepliesCount ? data.noOfFirstRepliesCount : 0,
        status: data.status,
        sites: data.sites,
        creator: data.creator
      }
    );
    return profileModel
  }

  public static ParseDataToChatterUserHistoryModel(doc: QueryDocumentSnapshot<DocumentData>): ChatterUserHistoryModel {
    let data = doc.data();
    let chatterUserHistoryModel = new ChatterUserHistoryModel(
      {
        id: doc.id,
        uuid: data.uuid,
        profileId: data.profileId,
        createdAt: this.ConvertDate(data.createdAt),
        lastUpdated: this.ConvertDate(data.lastUpdated)
      }
    );
    return chatterUserHistoryModel;
  }

  public static ParseDataToPurchaseLogModel(id: string, data: DocumentData): PurchaseLogModel {
    // console.log(`Data: ${JSON.stringify(data)}`);
    let purchaseLogModel = new PurchaseLogModel(
      {
        id: id,
        userId: data.userId,
        lastProfileChatted: data.lastProfileChatted,
        priceId: data.priceId,
        isDiscount: data.isDiscount,
        createdAt: data.createdAt,
        hasMessages: data.hasMessages,
        formattedDate: data.createdAt.toDate(),
        last_chatter: data.last_chatter,
        success: data.success !== undefined ? data.success : true,
      });

      // console.log(`Log Model: ${JSON.stringify(purchaseLogModel)}`);
    return purchaseLogModel;
  }

  public static ParseDataToProductModel(doc: QueryDocumentSnapshot<DocumentData>): ProductModel {
    let data = doc.data();
    let priceList: Map<string, string> = new Map<string, string>();
    for (let i = 0; i < data.price_list.length; i++)
    {
      let priceMap: Map<string, string> = new Map([
        ["currency", data.price_list[i].currency],
        ["priceId", data.price_list[i].priceId],
      ]);
      
      let currency = "";
      let priceId = "";
      priceMap.forEach((value, key, map) => {
        if (key === "currency")
          currency = value;
        else if (key === "priceId")
          priceId = value;
      });
      priceList.set(currency, priceId);
    };

    let productModel = new ProductModel(
      {
        id: doc.id,
        productName: data.product_name,
        priceId: data.price_id,
        value: data.value,
        priceValue: data.price_value,
        discountRef: data.discount_ref,
        testPriceId: data.test_price_id,
        priceIdList: data.price_id_list,
        priceList: priceList,
      }
    );
    
    // console.log(`Product Model: ${JSON.stringify(productModel)}`);
    // productModel.priceList.forEach((value, key) => {
    //   console.log(`Product Price List Element Key: ${key} | Value: ${value}`);
    // });
    return productModel;
  }

  public static ParseDataToDiscountModel(id: string, data: DocumentData): DiscountModel {
    let priceList: Map<string, string> = new Map<string, string>();
    for (let i = 0; i < data.price_list.length; i++)
    {
      let priceMap: Map<string, string> = new Map([
        ["currency", data.price_list[i].currency],
        ["priceId", data.price_list[i].priceId],
      ]);
      
      let currency = "";
      let priceId = "";
      priceMap.forEach((value, key, map) => {
        if (key === "currency")
          currency = value;
        else if (key === "priceId")
          priceId = value;
      });
      priceList.set(currency, priceId);
    };

    let discountModel = new DiscountModel(
      {
        id: id,
        priceId: data.price_id,
        descripttion: data.description,
        name: data.name,
        value: data.value,
        testPriceId: data.test_price_id,
        priceValue: data.price_value,
        priceIdList: data.price_id_list,
        priceList: priceList
      }
    );

    // console.log(`Discount Model: ${JSON.stringify(discountModel)}`);
    // discountModel.priceList.forEach((value, key) => {
    //   console.log(`Discount Price List Element Key: ${key} | Value: ${value}`);
    // });
    return discountModel;
  }

  public static RefreshPage()
  {
    window.location.reload();
  }

  public static checkAccess = (action: string,accessTo: string, checkSubType: boolean = false) => {

    const userRole = !checkSubType ? User.Model?.userType : User.Model?.subType;
    let permission = false;

    if (!userRole)
      return false;

    if(!permission){
      switch (action) {
        case "createAny" : {
          const perm = ac.can(String(userRole)).createAny(accessTo);
          if(perm.granted){
            permission = true;
          }
          break;
        }
        case "updateAny" : {
          const perm = ac.can(String(userRole)).updateAny(accessTo);
          if(perm.granted){
            permission = true;
          }
          break;
        }
        case "deleteAny" : {
          const perm = ac.can(String(userRole)).deleteAny(accessTo);
          if(perm.granted){
            permission = true;
          }
          break;
        }
        case "readAny" : {
          const perm = ac.can(String(userRole)).readAny(accessTo)
          if(perm.granted){
            permission = true;
          }
          break;
        }
        default : {
          const perm = ac.can(String(userRole)).readAny(accessTo)
          if(perm.granted){
            permission = true;
          }
          break;
        }
      }
      return permission;
    }
    return permission;
  }

  public static ConvertDateTimeFormat(date: string,isDate: Boolean) {
    let splitedFullDate = date.split(',')
    let timeSplit = splitedFullDate[1].split(' ')
    let splitTime = timeSplit[1].split(":")
    let splitdate = splitedFullDate[0].split("/")
    let h = parseInt(splitTime[0]);
    let m = parseInt(splitTime[1]);
    let s = parseInt(splitTime[2]);
    let y = parseInt(splitdate[2]);
    let month = parseInt(splitdate[0]) - 1;
    let d = parseInt(splitdate[1]);
    if (timeSplit[2] === "PM" && !isDate) {
      if(h < 12) h+=12;
      let date =  new Date(y,month,d,h,m,s)
      return date;
    } else {
      let date =  new Date(y,month,d,h,m,s)
      return date;
    }
  }
}