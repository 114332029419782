import {
  collection,
  DocumentData,
  query,
  serverTimestamp,
  addDoc,
  where,
  updateDoc,
  setDoc,
  getDoc,
  QuerySnapshot,
  QueryConstraint,
  Timestamp,
  increment,
} from "@firebase/firestore";
import {
  arrayUnion,
  deleteDoc,
  doc,
  endBefore,
  getDocs,
  getFirestore,
  limit,
  limitToLast,
  orderBy,
  QueryDocumentSnapshot,
  startAfter,
  startAt,
} from "firebase/firestore";
import {
  ChatLogModel,
  ChatRoomModel,
  DetailedChatLogModel,
  FeedEntryModel,
  MessageModel,
  MessageModelNew,
  PaginatedFeedEntries,
  PaginatedPurchaseLogs,
  ProfileAndLatestMessageModel,
  PurchaseLogModel,
  UserMessageTable,
} from "../../_metronic/helpers";
import { EnvironmentType, FirebaseApp } from "../FirebaseApp";
import { Analytics, AnalyticsId } from "../system/Analytics";
import { FirestoreManager } from "../system/FirestoreManager";
import { HttpsHandler, HttpsActionNames } from "../system/HttpsHandler";
import { User, UserTag, UserTypes } from "../user/User";

import moment from "moment";
import { Profile } from "../user/Profile";
import { Utils } from "../system/Utils";
import { limitToFirst } from "firebase/database";

export class Chat {
  static CHAT_ROUTE_MESSAGE_USER_BASE = "/apps/chat/message-user";
  static CHAT_ROUTE = "/apps/chat";
  static CHAT_CHATTER_REQUESTS_FEED_ROUTE = "/dashboard";
  static CHAT_CHATTER_ROOM = "/live-chat";

  static CHATTER_REQUEST_FEED = "chatter_requests";
  static ADMIN_FEED = "admin_feed";

  //static firestore = getFirestore();
  static Initialize() {
    //this.firestore = getFirestore();
  }

  static async SetUserSendLastMessage(
    roomId: string,
    active: boolean,
    siteOfOrigin: string
  ) {
    const firestore = getFirestore();
    const chatsRef = collection(firestore, `sites/${siteOfOrigin}/rooms`);
    const chatDoc = doc(chatsRef, roomId);
    let params = {
      user_send_last_message: active,
    };
    let success: boolean = false;
    await updateDoc(chatDoc, params).then(() => {
      success = true;
    });
    return Promise.resolve(success);
  }

  // #region Room Status
  static async SetRoomStatus(
    roomID: string,
    active: boolean,
    siteOfOrigin: string
  ): Promise<void> {
    const firestore = getFirestore();
    const chatsRef = collection(firestore, `sites/${siteOfOrigin}/rooms`);
    const chatDoc = doc(chatsRef, roomID);
    let params = {
      is_room_locked: active,
    };

    let roomExists = await this.DoesRoomExist(roomID, siteOfOrigin);

    let success: boolean = false;

    if (roomExists) {
      await updateDoc(chatDoc, params).then(() => {
        success = true;
      });
    } else {
      await setDoc(chatDoc, params).then(() => {
        success = true;
      });
    }

    if (success) return Promise.resolve();
    else return Promise.reject();
  }

  static async DoesRoomExist(
    roomId: string,
    siteOfOrigin: string
  ): Promise<boolean> {
    const firestore = getFirestore();
    const roomDoc = doc(firestore, `sites/${siteOfOrigin}/rooms`, roomId);
    const roomSnap = await getDoc(roomDoc);

    return Promise.resolve(roomSnap.exists());
  }

  static async GetRoomStatus(
    roomID: string,
    siteOfOrigin: string
  ): Promise<boolean> {
    const firestore = getFirestore();
    const roomDoc = doc(firestore, `sites/${siteOfOrigin}/rooms`, roomID);
    const roomSnap = await getDoc(roomDoc);
    let is_room_locked: boolean = false;
    if (roomSnap.exists()) {
      let data = roomSnap.data();

      is_room_locked = data.is_room_locked;
    }

    return Promise.resolve(is_room_locked);
  }
  // #endregion

  static async SetReadStatus(
    roomID: string,
    status: "read" | "unread",
    siteOfOrigin: string
  ): Promise<void> {
    const firestore = getFirestore();
    const roomDoc = doc(firestore, `sites/${siteOfOrigin}/rooms`, roomID);

    let params = {
      read_status: status,
    };

    await updateDoc(roomDoc, params);
  }

  static async SendMessage(
    roomID: string,
    params: any,
    siteOfOrigin: string,
    messageType: "message" | "profile_like" = "message",
    lastMsgTime: Date = null as any,
    trigger?: string,
    triggerAppend?: string,
  ): Promise<void> {
    const firestore = getFirestore();
    let timeStamp = serverTimestamp();
    if(!trigger){
      trigger = "New%20Message"
    }
    if(!triggerAppend){
      triggerAppend = "null"
    }
    trigger =trigger?.includes("%20") ? trigger?.replaceAll("%20","_") : trigger?.replaceAll(" ","_");
    triggerAppend =triggerAppend?.includes("%20") ? triggerAppend?.replaceAll("%20","_") : triggerAppend?.replaceAll(" ","_");

    trigger = trigger?.toLowerCase();
    triggerAppend = triggerAppend?.toLowerCase();

    params.createdAt = timeStamp;
    let messagesRef = collection(
      firestore,
      `sites/${siteOfOrigin}/rooms/${roomID}/messages`
    );
    const messagesQuery = query(messagesRef, where('uuid','==',params["uuid"]));
    const response =  await getDocs(messagesQuery);
    let length = response.docs.length;
    if(length === 0){
      let updatedData = {
        noOfFirstRepliesCount : increment(1),
      }
      Profile.UpdateProfile(params["uuid"], updatedData)
    }
    //params = this.CheckValidDataInput(params);
    let uuid = params["uuid"];
    let recepient_id = params["recepient_id"];
    let content_type = params["contentType"];
    let chatter_id = params["chatter_id"];
    let isChatterReplied = params["isChatterReplied"];

    await addDoc(messagesRef, {...params, tag: trigger, tagAppend: triggerAppend}).then(async (docRef) => {
      if (docRef) {
        let timeToReply = null as any;
        if (lastMsgTime) {
          let today = moment(new Date());
          let lastMsg = moment(lastMsgTime);
          timeToReply = today.diff(lastMsg, "seconds");
        }

        User.AddToChatterUserHistory(recepient_id, uuid);

        let payload: Map<string, any> = new Map<string, any>();
        payload.set("recepient_id", recepient_id);
        payload.set("message_type", messageType);
        payload.set("content_type", content_type);
        payload.set("is_chatter", true);
        payload.set("profile_id", uuid);
        payload.set("chatter_id", chatter_id);
        payload.set("time_to_reply", timeToReply);
        payload.set("isChatterReplied", isChatterReplied);
        Analytics.SendAnalyticsEvent(AnalyticsId.SEND_CHAT_MESSAGE, payload);
        let payloadTrigger: Map<string, any> = new Map<string, any>();
        payloadTrigger.set("name", trigger);
        payloadTrigger.set("appended_time", triggerAppend);
        payloadTrigger.set("action", "chatter_action");
        Analytics.SendAnalyticsEvent(AnalyticsId.TRIGGER_ACTION, payloadTrigger);

        const logsRef = collection(firestore, `sites/${siteOfOrigin}/logs`);
        await addDoc(logsRef, params);

        const chatsRef = collection(firestore, `sites/${siteOfOrigin}/rooms/`);
        const chatDoc = doc(chatsRef, roomID);

        let params2 = {
          last_updated: timeStamp,
          profile_id: uuid,
          user_id: recepient_id,
          read_status: "unread",
          chatters_involved: arrayUnion(chatter_id),
        };
        // await updateDoc(chatDoc, params2);
        await setDoc(chatDoc,params2,{
          merge: true,
        })
      }
    });
  }

  private static async GetLatestMessage(
    roomID: string,
    siteOfOrigin: string
  ): Promise<MessageModel[]> {
    const firestore = getFirestore();
    const messagesRef = collection(
      firestore,
      `sites/localhost/rooms/${roomID}/messages`
    );
    const messagesQuery = query(messagesRef, orderBy("createdAt"));

    const messageSnap = await getDocs(messagesQuery);

    let message: MessageModel[] = null as any;
    let docs: DocumentData[] = [];
    let messages: MessageModel[] = [];

    if (!messageSnap.empty) {
      docs.push(messageSnap.docs[0].data());
    }

    messages = await Chat.ExtractMessageQuery(docs, messageSnap);
    console.log("msg data", messages);
    message = messages;
    return Promise.resolve(message);
  }

  static async GetLastChatInteractions(uuid: string, site: string, entry_limit: number = 5, isChatterSearch = false): Promise<any>
  {
    const firestore = getFirestore();
    const lastRoomsRef = collection(firestore, `sites/${site}/rooms`);

    const queries: QueryConstraint[] = [];

    if(!isChatterSearch){
      queries.push(where("user_id", "==", uuid));
    }
    if(isChatterSearch){
      queries.push(where("chatters_involved", "array-contains", uuid));
    }
    queries.push(orderBy("last_updated", "desc"));
    queries.push(where("hasBackAndForth", "==", true));
    queries.push(limit(entry_limit));
    const lastRoomsQuery = query(lastRoomsRef, ...queries);

    const detailedChatLogs: DetailedChatLogModel[] = [];
    const lastRoomsSnap = await getDocs(lastRoomsQuery);
    if (lastRoomsSnap.docs)
    {
      for (let i = 0; i < lastRoomsSnap.docs.length; i++)
      {
        const doc = lastRoomsSnap.docs[i];
        const lastRoomsData = doc.data();
        const messagesRef = collection(firestore, `sites/${site}/rooms/${doc.id}/messages`);

        const queries2: QueryConstraint[] = [];

        queries2.push(orderBy("createdAt", "desc"));
        if(isChatterSearch){
          queries2.push(where("chatter_id", "==", uuid));
        }
        const messagesQuery = query(messagesRef, ...queries2);
        const messagesSnap = await getDocs(messagesQuery);
        let latestChatter = null as any;
        let timeByLatestChatter = null as any;
        let messageId = null as any;
        let message = null as any;
        let messageType = "";
        let isChatter = false;

        let timeByUser = null as any;
        console.log(`<---- Room Id: ${doc.id} ---->`);
        if (messagesSnap.docs)
        {
          for (let j = 0; j < messagesSnap.docs.length; j++) 
          {
            const messageDoc = messagesSnap.docs[j];
            const messageData = messageDoc.data();

            console.log(`Message Info: 
            is Chatter? ${messageData.isChatter}
            Time: ${messageData.createdAt.toDate()}`)

            if (messageData.isChatter)
            {
              // Grab Latest Chatter
              if (!latestChatter) 
              {
                latestChatter = messageData.chatter_id;
                timeByLatestChatter = messageData.createdAt.toDate();
                if (!message)
                {
                  messageId = messageDoc.id;
                  message = messageData.text;
                  messageType = messageData.contentType;
                  isChatter = messageData.isChatter;
                }
              }
            } else {
              if (latestChatter)
                timeByUser = messageData.createdAt.toDate();
            }

            if (message && latestChatter && timeByUser)
              break;
          }
        }

        // Compute Time For Reply
        
        let timeToReply = -1;
        if (timeByUser)
        {
          timeToReply = Math.abs((timeByLatestChatter.getTime() - timeByUser.getTime()) / 1000)
        }

        let detailedChatLog: DetailedChatLogModel = {
          id: messageId,
          room_id: doc.id,
          user_id: lastRoomsData.user_id,
          last_updated: lastRoomsData.last_updated,
          profile_id: lastRoomsData.profile_id,
          chatter_id: latestChatter,
          read_status: lastRoomsData.read_status,
          message: message,
          messageType: messageType,
          isChatter: isChatter,
          timeToRespond: timeToReply,
        }
        detailedChatLogs.push(detailedChatLog);
      }
    }

    return Promise.resolve(detailedChatLogs);
  }

  static async RetrieveChatLogs(siteOfOrigin: string, chatterId?: string, startDate?: Date, endDate?: Date, timeBefore?: string, isRepliedOnly?:boolean): Promise<ChatLogModel[]>
  {
    let url = `${HttpsHandler.BASE_URL}/${HttpsActionNames.RETRIEVE_CHAT_LOGS}?site=${siteOfOrigin}&chatterId=${chatterId}&startDate=${startDate}&endDate=${endDate}&timeBefore=${timeBefore}&replyOnly=${isRepliedOnly}`;
    // let url = `${HttpsHandler.BASE_URL}/${HttpsActionNames.RETRIEVE_CHAT_LOGS}?site=${siteOfOrigin}&chatterId=${chatterId}`;
  // private static async GetLengthOfMsg(
  //   roomID: string,
  //   siteOfOrigin: string
  // ): Promise<MessageModel[]> {
  //   const firestore = getFirestore();
  //   const messagesRef = collection(
  //     firestore,
  //     `sites/localhost/rooms/fQ6ULDqARuVWCC6FjtKTHpTgvqJ2-c5beOfXN6ySjp19InqJx
  //   /messages`
  //   );
  //   const messagesQuery = query(messagesRef);

  //   const messageSnap = await getDocs(messagesQuery);

  //   let message: MessageModel[] = null as any;
  //   let docs: DocumentData[] = [];
  //   let messages: MessageModel[] = [];

  //   // messageSnap.forEach((doc)=>{
  //   //   if(doc){
  //   //       docs.push(doc.docs.data())
  //   //   }
  //   // });

  //   //  messages = await Chat.ExtractMessageQuery(docs, messageSnap);
  //   console.log("msg data", messageSnap);
  //   message = messages;
  //   return Promise.resolve(message);
  // }

  // static async RetrieveChatLogs(
  //   siteOfOrigin: string,
  //   chatterId: string
  // ): Promise<ChatLogModel[]> {
  //   let url = `${HttpsHandler.BASE_URL}/${HttpsActionNames.RETRIEVE_CHAT_LOGS}?site=${siteOfOrigin}&chatterId=${chatterId}`;
    let chatLogs: ChatLogModel[] = [];
    await HttpsHandler.SendGetRequest(
      url,
      false,
      (success, data, message) => {
        if (success) {
          data.forEach((chatLog: ChatLogModel) => {
            chatLogs.push(chatLog);
          });
        }
      },
      (success, message) => {}
    );

    return Promise.resolve(chatLogs);
  }

  public static async RetrievePurchaseLogsPaginated(visibleStart: QueryDocumentSnapshot<DocumentData> = null as any, entry_limit: number): Promise<PaginatedPurchaseLogs>
  {
    let paginatedPurchaseLogs: PaginatedPurchaseLogs = new PaginatedPurchaseLogs();
    let purchaseLogs: PurchaseLogModel[] = [];

    const siteRef = FirebaseApp.environment === EnvironmentType.STAGING ? "localhost" : "flirtybum";;

    const firestore = getFirestore();
    const purchaseLogsRef = collection(firestore, `sites/${siteRef}/purchase_logs`);

    console.log(`Last Visible: ${visibleStart?.id}`);
    const queries: QueryConstraint[] = [];
    
    queries.push(orderBy("createdAt", "desc"));
    queries.push(limit(entry_limit + 1));

    if (visibleStart){
      // When Sorting by Descending AND there is an existing Visible Cursor, the query needs Start At
      queries.push(startAt(visibleStart));
    } else {
      // When Sorting by Descending and there is no existing Visible Cursor the query needs endBefore
      // if (sort === "desc")
        queries.push(endBefore(null));
    }

    const purchaseLogsQuery = query(purchaseLogsRef, ...queries);

    const purchaseLogsSnap = await getDocs(purchaseLogsQuery);
    if (purchaseLogsSnap.docs)
    { 
      if (purchaseLogsSnap.docs.length === entry_limit + 1)
      {
        paginatedPurchaseLogs.node.visibleStart = purchaseLogsSnap.docs[purchaseLogsSnap.docs.length - 1];
        paginatedPurchaseLogs.node.nodeId = paginatedPurchaseLogs.node.visibleStart.id.toString();
        paginatedPurchaseLogs.nextNodeExists = true;
      }
      // console.log(`First Entry: ${purchaseLogsSnap.docs[0].id}`);
      // paginatedPurchaseLogs.lastVisiblePrev = nextPage ? purchaseLogsSnap.docs[0] : purchaseLogsSnap.docs[purchaseLogsSnap.docs.length - 1];

      // if (purchaseLogsSnap.docs.length === entry_limit + 1)
      // {
      //   paginatedPurchaseLogs.lastVisibleNext = nextPage ? purchaseLogsSnap.docs[purchaseLogsSnap.docs.length - 1] : purchaseLogsSnap.docs[0];
      // }
    }

    console.log(`Snap length: ${purchaseLogsSnap.docs.length}`);

    for (let i = 0; i < purchaseLogsSnap.docs.length; i++)
    {
      // console.log("Try");
      if (i === entry_limit)
        continue;
      
      // console.log("Proceed");
      const doc = purchaseLogsSnap.docs[i];
      const model = Utils.ParseDataToPurchaseLogModel(doc.id, doc.data());

      purchaseLogs.push(model);
    }

    // purchaseLogs = purchaseLogs.reverse();
    paginatedPurchaseLogs.purchaseLogs = purchaseLogs;

    // console.log(`Last Visible Prev: ${paginatedPurchaseLogs.lastVisiblePrev?.id}`);
    // console.log(`Last Visible Next: ${paginatedPurchaseLogs.lastVisibleNext?.id}`);

    return Promise.resolve(paginatedPurchaseLogs);
  }

  public static async RetrievePurchaseLogsByCreatedAt(startDate:number): Promise<PurchaseLogModel | undefined>
  {
    const siteRef = FirebaseApp.environment === EnvironmentType.STAGING ? "localhost" : "flirtybum";

    const firestore = getFirestore();
    const purchaseLogsRef = collection(firestore, `sites/${siteRef}/purchase_logs`);

    const queries: QueryConstraint[] = [];
    let start = new Date(startDate*1000);
    let addMin = startDate+20;
    let end = new Date(addMin * 1000);
    
    // queries.push(where("userId", "==", uuid))
    // queries.push(where("isDiscount", "==", true))
    queries.push(where("createdAt", ">=", Timestamp.fromDate(start)));
    queries.push(where("createdAt", "<=", Timestamp.fromDate(end)));
   
    const purchaseLogsQuery = query(purchaseLogsRef, ...queries);

    const purchaseLogsSnap = await getDocs(purchaseLogsQuery);
    let model;
    if(purchaseLogsSnap.docs.length){
      const doc = purchaseLogsSnap.docs[0];
       model = Utils.ParseDataToPurchaseLogModel(doc.id, doc.data());
    }
    return Promise.resolve(model);
  }

  public static async RetrievePurchaseLogsByUserId(uid:string, startDate?:any, endDate?:any, siteOverride?: string): Promise<PurchaseLogModel[] | any>
  {
    let siteRef = "";
    if (siteOverride)
      siteRef = siteOverride
    else
      siteRef = FirebaseApp.environment === EnvironmentType.STAGING ? "localhost" : "flirtybum";

    const firestore = getFirestore();
    const purchaseLogsRef = collection(firestore, `sites/${siteRef}/purchase_logs`);

    const queries: QueryConstraint[] = [];
    
    queries.push(where("userId", "==", uid))

    if(startDate && endDate) {
      queries.push(where("createdAt", ">=", Timestamp.fromDate(startDate)));
      queries.push(where("createdAt", "<=", Timestamp.fromDate(endDate)));
    }
   
    const purchaseLogsQuery = query(purchaseLogsRef, ...queries);

    const purchaseLogsSnap = await getDocs(purchaseLogsQuery);
    let models = [];
    if(purchaseLogsSnap.docs.length){
      for (const purchase of purchaseLogsSnap.docs) {
       let model = Utils.ParseDataToPurchaseLogModel(purchase.id, purchase.data());
        models.push(model);
      }
    }
    return Promise.resolve(models);
  }
  public static async GetLastMessagesLeadToDeposite(uuid:string, entry_limit: number, isFTD: boolean): Promise<MessageModel[] | null>
  {
    let lastMessages: MessageModel[] = [];
    const firestore = getFirestore();
    const purchaseLogsRef = collection(firestore, `sites/flirtybum/purchase_logs`);
    const queries: QueryConstraint[] = [];
    queries.push(orderBy("createdAt",  "desc"));
    queries.push(where("isDiscount", "==", isFTD ? true : false));
    queries.push(where("last_chatter", "==", uuid));
    queries.push(limit(entry_limit + 1));
    const purchaseLogsQuery = query(purchaseLogsRef, ...queries);
    const purchaseLogsSnap = await getDocs(purchaseLogsQuery);
    if (purchaseLogsSnap.docs)
    {
      for (let i = 0; i < purchaseLogsSnap.docs.length; i++)
      {
        const doc1 = purchaseLogsSnap.docs[i];
        const messagesRef = collection(
          firestore,
          `sites/flirtybum/purchase_logs/${doc1.id}/messages`
        );
        const messagesQuery = query(
          messagesRef,
          where("isChatter", "==", true),
          orderBy("createdAt", "asc"),
          limitToLast(1)
        );
        const messageSnap = await getDocs(messagesQuery);
        if(messageSnap.docs){
          let temp = this.ExtractSingleMessageDoc(doc1.id,messageSnap.docs[0].data())
          lastMessages.push(temp);
        }
      }
    }
    return Promise.resolve(lastMessages);
  }

  public static async RetrievePurchaseLogsByCreatedAtList(startDateList:number[]): Promise<any>
  {
    let purchaseData:any = [];
   for (const startDate of startDateList) {
    const data = await this.RetrievePurchaseLogsByCreatedAt(startDate);
    purchaseData.push(data);
   }
   const idList = purchaseData.map((data:any)=>data?.id);
   const chatterData = new Promise(function(resolve, reject) {
    Chat.ListenForPurchaseRoomMessagesById(idList,(data:any)=>{
      resolve(data);
   });
 });
    return chatterData
  }

  public static async ListenForPurchaseRoomMessagesById(startDateList:string[],onUpdate: (
    messages: MessageModel[] | undefined
  ) => any): Promise<PurchaseLogModel | any>
  {
    let purchaseData:any = [];
    if(!startDateList.length){
      onUpdate(purchaseData);
    }
    for (const startDate of startDateList) {
      Chat.StopListeningForChatRoomMessages(startDate);
      Chat.ListenForPurchaseRoomMessages(startDate, 'flirtybum', 10, true, async (data: any) => {
        purchaseData.push(data[0]);
      if(startDateList[startDateList.length-1] === startDate){
        onUpdate(purchaseData)
      }
      });
  }
  }
  
  public static async GetLockedChatRooms(): Promise<ChatRoomModel[]>
  {
    const site = FirebaseApp.environment === EnvironmentType.STAGING ? "localhost" : "flirtybum"
    const firestore = getFirestore();
    const roomsRef = collection(firestore, `sites/${site}/rooms`);
    const roomsQuery = query(roomsRef, where("is_room_locked", "==", true), orderBy("last_updated", "desc"));

    const roomsSnap = await getDocs(roomsQuery);
    const lockedChatRooms: ChatRoomModel[] = [];
    roomsSnap.forEach((doc) => {
      if (doc)
      {
        const data = doc.data();
        if (data)
        {
          let chatRoom: ChatRoomModel = {
            id: doc.id,
            chattersInvolved: data.chatters_involved,
            isRoomLocked: data.is_room_locked,
            lastUpdated: data.last_updated,
            profileId: data.profile_id,
            readStatus: data.read_status,
            userId: data.user_id
          };

          lockedChatRooms.push(chatRoom);
        }
      }
    });

    return Promise.resolve(lockedChatRooms);
  }

  static async GetLatestChatRooms(site: string, userId: string, limit: number = 5): Promise<ChatRoomModel[]>
  {
    const firestore = getFirestore();
    const roomsRef = collection(firestore, `sites/${site}/rooms`);

    const queries: QueryConstraint[] = [];
    queries.push(orderBy("last_updated", "asc"));
    queries.push(where("user_id", "==", userId));
    queries.push(limitToLast(limit));
    
    const roomsQuery = query(roomsRef, ...queries);
    const roomsSnap = await getDocs(roomsQuery);

    const chatrooms: ChatRoomModel[] = [];
    roomsSnap.forEach((doc) => {
      const data = doc.data();
      let chatroom: ChatRoomModel = {
        id: doc.id,
        chattersInvolved: data.chatters_involved ? data.chatters_involved : [],
        isRoomLocked: data.is_room_locked ? data.is_room_locked : false,
        lastUpdated: data.last_updated,
        profileId: data.profile_id,
        readStatus: data.read_status,
        userId: data.user_id
      };

      chatrooms.push(chatroom);
    });

    return Promise.resolve(chatrooms);
    // for (let i = 0; i < roomsSnap.docs.length; i++)
    // {
    //   const roomId = roomsSnap.docs[i].id;
    //   const messagesRef = collection(roomsRef, `${roomId}/messages`);

    //   const msgQueries: QueryConstraint[] = [];
    //   msgQueries.push(orderBy("createdAt", "asc"));
    //   msgQueries.push(limitToLast(1));
    // }
  }

  /**
   * timeBefore arguments takes a time - how many previous hour data do we want (ex- 4 means we want last 4 hour data)
   */
  static async GetChatLogs(
    siteOfOrigin: string,
    userId: string = null as any,
    chatterId: string = null as any,
    startDate: Date,
    endDate: Date,
    timeBefore?: any
  ): Promise<MessageModel[]> {
    const firestore = getFirestore();
    const logsRef = collection(firestore, `sites/${siteOfOrigin}/logs`);

    const queries: QueryConstraint[] = [];
    queries.push(orderBy("createdAt", "desc"));

    // if (userId)
    // queries.push(where("uuid", "in", [userId]));

    if (chatterId) queries.push(where("chatter_id", "==", chatterId));

    if(startDate && endDate){
      if (timeBefore) {
        parseInt(timeBefore);
        startDate.setHours(startDate.getHours() - timeBefore);
      } else {
        startDate.setHours(0, 0, 0, 0);
      }
      console.log(`Start Date after set: ${startDate}`);
      // endDate.setHours(23, 59, 59, 999);
      console.log(`End Date after set: ${endDate}`);
      
      queries.push(where("createdAt", ">=", Timestamp.fromDate(startDate)));
      queries.push(where("createdAt", "<=", Timestamp.fromDate(endDate)));
    }

    const logsQuery = query(logsRef, ...queries);
    const logsSnap = await getDocs(logsQuery);

    let messages: MessageModel[] = [];
    let docData: DocumentData[] = [];

    /**
     * Old code makes page unresponsive because it goes to infinite loop
     */
    // logsSnap.forEach((doc) => {
    //   if (doc)
    //   {
    //     let docData: DocumentData[] = [];
    //     logsSnap.forEach((doc) => {
    //       if (doc) {
    //         const data = doc.data();
    //         if (data)
    //           docData.push(data);
    //       }
    //     })

    //     if (docData) {
    //       const temp = Chat.ExtractMessageQuery(docData, logsSnap);
    //       if (temp)
    //         messages = temp;
    //     }
    //   }
    // });

    /**
     * New code - which does not goes to infinite loop
     */
    logsSnap.forEach((doc) => {
      if (doc) {
        docData.push(doc.data());
      }
    });
    const temp = Chat.ExtractMessageQuery(docData, logsSnap);
    messages = temp;
    return Promise.resolve(messages);
  }

  static getSupportLogs =
         async function(siteOfOrigin: string,
      startDate?: Date, endDate?: Date, timeBefore?: any, replyOnly?:string): Promise<any[]> {
        const supportProfilers= ['v741ZIst2maec64wz3JE','LxsZwDHql2xvEofJztZz'];
        const firestore = getFirestore();
    const logsRef = collection(firestore, `sites/${siteOfOrigin}/rooms`);
    const queries: QueryConstraint[] = [];
    queries.push(orderBy("last_updated", "desc"));

    queries.push(where("profile_id", "in", supportProfilers ))

    const logsQuery = query(logsRef, ...queries);
    const logsSnap = await getDocs(logsQuery);
    let docData:any = [];
    logsSnap.forEach((doc) => {
      if (doc) {
        const roomId = Chat.GetPrivateChatRoomId(doc.data().profile_id, doc.data().user_id);
        docData.push({...doc.data(), roomId});
      }
    });
    return Promise.resolve(docData);
  };

  static ListenForLastChatRoomMessage(
    roomID: string,
    siteOfOrigin: string,
    onUpdate: (
      notification :boolean
    ) => void |  boolean
  ) {
    const supportProfilers= ['v741ZIst2maec64wz3JE','LxsZwDHql2xvEofJztZz'];
    const firestore = getFirestore();
    const messagesRef = collection(
      firestore,
      `sites/${siteOfOrigin}/rooms/${roomID}/messages`
    );
    const messagesQuery = query(
      messagesRef,
      orderBy("createdAt", "asc"),
      limitToLast(1)
    );
    const key = `${roomID}@messages`;
    FirestoreManager.DetachFirestoreListener(key);
    FirestoreManager.AttachFirestoreListenerWithQuery(
      messagesQuery,
      key,
      (snapshot) => {
        if (snapshot) {
          snapshot.forEach((doc) => {
            if (doc) {
              const data = doc.data();
              if(!supportProfilers.includes(data.uuid)){
                 onUpdate(true);
              }
            }
          });
        }
      }
      );
  }

  static async getLastMessage(
    roomId: string,
    siteOfOrigin: string,
  ): Promise<any> {
    const firestore = getFirestore();

    let messagesRef = collection(firestore, `sites/${siteOfOrigin}/rooms/${roomId}/messages`);
    const queries: QueryConstraint[] = [];
    queries.push(orderBy("createdAt", "desc"));
    queries.push(limit(1));

    const messagesQuery = query(
      messagesRef,
      ...queries
    );

    const existSnap = await getDocs(messagesQuery);
    if (existSnap.size > 0) {
      if (existSnap.docs[0]) {
        const data = existSnap.docs[0].data();
         return  Promise.resolve(data)
      }
    } else {
    return  Promise.reject(false);
    }
  }

  static async GetChatLogsForUserSearch(
    siteOfOrigin: string,
    userId: string[] = null as any,
    chatterId: string = null as any,
    startDate: Date,
    endDate: Date,
    timeBefore?: any
  ): Promise<MessageModel[]> {
    const firestore = getFirestore();
    const logsRef = collection(firestore, `sites/${siteOfOrigin}/logs`);
    const queries: QueryConstraint[] = [];
    console.log(queries);

    let allMessage = [];
    // queries.push(orderBy("createdAt", "desc"));

    if (userId) {
      queries.push(where("uuid", "in", userId));
    }
    if(startDate && endDate){
      if (timeBefore) {
        parseInt(timeBefore);
        startDate.setHours(startDate.getHours() - timeBefore);
        console.log("startDate==>", startDate);
      } else {
        startDate.setHours(0, 0, 0, 0);
      }
      console.log(`Start Date after set: ${startDate}`);
      // endDate.setHours(23, 59, 59, 999);
      console.log(`End Date after set: ${endDate}`);
    }

    // queries.push(where("createdAt", ">=", Timestamp.fromDate(startDate)));
    // queries.push(where("createdAt", "<=", Timestamp.fromDate(endDate)));

    const logsQuery = query(logsRef, ...queries);
    const logsSnap = await getDocs(logsQuery);

    let messages: MessageModel[] = [];
    logsSnap.forEach((doc) => {
      if (doc) {
        let docData: DocumentData[] = [];
        logsSnap.forEach((doc) => {
          if (doc) {
            const data = doc.data();
            if (data) docData.push(data);
          }
        });

        if (docData) {
          const temp = Chat.ExtractMessageQuery(docData, logsSnap);
          if (temp) messages = temp;
        }
      }
    });

    const queriesRecepaint: QueryConstraint[] = [];
    if (userId) {
      queriesRecepaint.push(where("recepient_id", "in", userId));
    }
    if(startDate && endDate){
      if (timeBefore) {
        parseInt(timeBefore);
        startDate.setHours(startDate.getHours() - timeBefore);
        console.log("startDate==>", startDate);
      } else {
        startDate.setHours(0, 0, 0, 0);
      }
      console.log(`Start Date after set: ${startDate}`);
      // endDate.setHours(23, 59, 59, 999);
      console.log(`End Date after set: ${endDate}`);
    }

    const logsQueryRecepaint = query(logsRef, ...queriesRecepaint);
    const logsSnapRecepaint = await getDocs(logsQueryRecepaint);

    let messagesRecepaint: MessageModel[] = [];
    logsSnapRecepaint.forEach((doc) => {
      console.log(doc)
      if (doc) {
        let docData: DocumentData[] = [];
        logsSnapRecepaint.forEach((doc) => {
          if (doc) {
            const data = doc.data();
            if (data) docData.push(data);
          }
        });

        if (docData) {
          const tempRecepaint = Chat.ExtractMessageQuery(docData, logsSnapRecepaint);
          if (tempRecepaint) messagesRecepaint = tempRecepaint;
        }
      }
    });
    
    allMessage.push(...messagesRecepaint,...messages)
    console.log(messagesRecepaint,messages)
    console.log(allMessage);

    return Promise.resolve(allMessage);
  }

  static async SendToChatterRequestsFeed(
    params: any,
    modifyRecent: boolean = false,
    siteOfOrigin: string,
    tag: string = "New Message",
  ): Promise<void> {
    const firestore = getFirestore();
    let timeStamp = serverTimestamp();
    params.createdAt = timeStamp;
    params.tag = tag;
    params.siteOfOrigin = siteOfOrigin;
    let messagesRef = collection(
      firestore,
      `chatter/${this.CHATTER_REQUEST_FEED}/feed`
    );
    params = this.CheckValidDataInput(params);

    // if (modifyRecent && "recepient_id" in params && "uuid" in params) {
    //   let recepient_id = params["recepient_id"];
    //   let uuid = params["uuid"];
    //   if (recepient_id !== "" && uuid !== "") {
    //     let messagesQuery = query(
    //       messagesRef,
    //       where("recepient_id", "==", recepient_id),
    //       where("uuid", "==", uuid)
    //     );

    //     const messagesSnap = await getDocs(messagesQuery);
    //     if (!messagesSnap.empty && messagesSnap.docs.length > 0) {
    //       let id = messagesSnap.docs[messagesSnap.docs.length - 1].id;
    //       const messageDoc = doc(messagesRef, id);
    //       await updateDoc(messageDoc, params);
    //       return;
    //     }
    //   }
    // }
    // await addDoc(messagesRef, params);
    //params = this.CheckValidDataInput(params);
    let existQuery = query(messagesRef, where("roomId", "==", params.roomId));

    const existSnap = await getDocs(existQuery);
    if (existSnap.size > 0) {
      if (existSnap.docs[0]) {
        let id = existSnap.docs[0].id;
        let messagesDoc = doc(messagesRef, id);
        await updateDoc(messagesDoc, {...params, createdAt : timeStamp });
      }
    } else {
      params.createdAt = timeStamp;
      await addDoc(messagesRef, params);
    }
  }

  //#region For Admin
  static async SendToAdminFeed(
    roomId: string,
    params: any,
    siteOfOrigin: string,
    tag: string = "New Message"
  ): Promise<void> {
    const firestore = getFirestore();
    params.siteOfOrigin = siteOfOrigin;
    params.tag = tag;
    params.roomId = roomId;
    params.userId = User.Model?.uuid;

    let messagesRef = collection(firestore, `admin/${this.ADMIN_FEED}/feed`);
    //params = this.CheckValidDataInput(params);
    let existQuery = query(messagesRef, where("roomId", "==", roomId));

    const existSnap = await getDocs(existQuery);
    if (existSnap.size > 0) {
      if (existSnap.docs[0]) {
        let id = existSnap.docs[0].id;
        let messagesDoc = doc(messagesRef, id);
        await updateDoc(messagesDoc, {...params, createdAt : serverTimestamp() });
      }
    } else {
      params.createdAt = serverTimestamp();
      await addDoc(messagesRef, params);
    }
  }
  //#endregion

  //TODO: JOJO: Too specific. Make it more versatile.
  static async DeleteConversation(recepient_id: string, sender_id: string) {
    //let path = `sites/${this.SITE}/rooms/${roomID}/messages`;
    let path = `chatter/${this.CHATTER_REQUEST_FEED}/feed`;
    let roomID = this.GetPrivateChatRoomId(recepient_id, sender_id);
    const firestore = getFirestore();
    let messagesRef = collection(firestore, path);
    let messagesQuery = query(messagesRef, where("roomId", "==", roomID));
    console.log(`Delete Room Id: ${roomID}`);
    const messagesSnap = await getDocs(messagesQuery);
    messagesSnap.forEach((message) => {
      deleteDoc(doc(firestore, path, message.id));
    });
  }

  static async DeleteChatterRequestEntry(roomId: string) {
    let path = `chatter/${this.CHATTER_REQUEST_FEED}/feed`;
    const firestore = getFirestore();
    let messagesRef = collection(firestore, path);
    let messagesQuery = query(messagesRef, where("roomId", "==", roomId));

    const messagesSnap = await getDocs(messagesQuery);
    messagesSnap.forEach((message) => {
      deleteDoc(doc(firestore, path, message.id));
    });
  }

  static async DeleteAdminFeedEntry(roomId: string) {
    let path = `admin/${this.ADMIN_FEED}/feed`;
    const firestore = getFirestore();
    let messagesRef = collection(firestore, path);
    let messagesQuery = query(messagesRef, where("roomId", "==", roomId));

    const messagesSnap = await getDocs(messagesQuery);
    messagesSnap.forEach((message) => {
      deleteDoc(doc(firestore, path, message.id));
    });
  }

  //#region Helpers
  private static CheckValidDataInput(params: any): any {
    const allowedData: string[] = [
      "uuid",
      "createdAt",
      "photoURL",
      "text",
      "recepient_id",
      "isChatter",
      "contentType",
      "roomId",
      "siteOfOrigin",
      "tag",
      "message",
      "isTestEntry",
      "userId",
    ];
    let filteredData: any = {};
    allowedData.forEach((element) => {
      if (element in params) {
        filteredData[element] = params[element];
      }
    });

    return filteredData;
  }

  static GetPrivateChatRoomId(uuid_1: string, uuid_2: string): string {
    let roomId = "";
    let uuidArray = [uuid_1, uuid_2];
    uuidArray = uuidArray.sort();

    roomId = uuidArray[0] + "-" + uuidArray[1];

    return roomId;
  }

  static ExtractSingleMessageDoc(
    msgId: string,
    message: DocumentData
  ): MessageModel {
    var timestamp =
      message.createdAt ? message.createdAt.toDate() : undefined;
    const timeDisplay =
      timestamp !== undefined
        ? timestamp.toLocaleDateString() +
          " - " +
          timestamp.toLocaleTimeString()
        : "Loading...";
    let messageType: "in" | "out" = "out";
    if (User.Model) {
      if (
        User.Model?.userType === UserTypes.TYPE_CHATTER ||
        User.Model?.userType === UserTypes.TYPE_ADMIN ||
        User.Model?.userType === UserTypes.TYPE_MODERATOR
      ) {
        if (User.Model?.profile?.id === message.uuid) messageType = "out";
        else messageType = "in";
      } else {
        if (message.uuid === User.Model.uuid) messageType = "out";
        else messageType = "in";
      }
    } else {
      messageType = "in";
    }

    const msg: MessageModel = {
      id: msgId,
      user: message.uuid,
      type: messageType,
      text: message.text,
      time: timeDisplay,
      photoUrl: message.photoURL,
      recepient_id: message.recepient_id,
      contentType: message.contentType ? message.contentType : "text",
      siteOfOrigin: message.siteOfOrigin,
      isChatter: message.isChatter,
      tag: message.tag ? message.tag : UserTag.NEW_MESSAGE,
      isChatterReplied: message?.isChatterReplied ? message?.isChatterReplied : false,
    };

    return msg;
  }

  static ExtractMessageQuery(
    messages: DocumentData[] | undefined,
    snapshot: QuerySnapshot<DocumentData>
  ): Array<MessageModel> {
    const new_message_format: Array<MessageModel> = new Array<MessageModel>();

    if (messages) {
      for (let i = 0; i < messages?.length; i++) {
        var timestamp =
          messages[i].createdAt
            ? messages[i].createdAt.toDate()
            : undefined;
        let newTime = new Date(timestamp);
        const newDate = new Intl.DateTimeFormat(['ban', 'id']).format(newTime);
        const timeDisplay =
          timestamp !== undefined ? timestamp.toLocaleString() : "Loading...";
        let messageType: "in" | "out" = "out";

        if (messages[i].isChatter)
          messageType = "out";
        else 
          messageType = "in";

        const msg: MessageModelNew = {
          id: snapshot.docs[i].id,
          user: messages[i].uuid,
          type: messageType,
          text: messages[i].text,
          time: timeDisplay,
          newDate: newDate,
          newTime: newTime.toLocaleTimeString(),
          photoUrl: messages[i].photoURL,
          recepient_id: messages[i].recepient_id,
          contentType: messages[i].contentType
            ? messages[i].contentType
            : "text",
          siteOfOrigin: messages[i].siteOfOrigin,
          isChatter: messages[i].isChatter,
          chatter_id: messages[i].chatter_id
            ? messages[i].chatter_id
            : undefined,
          tag: messages[i].tag ? messages[i].tag : UserTag.NEW_MESSAGE,
          isChatterReplied: messages[i].isChatterReplied ? messages[i].isChatterReplied : false,
        };
        new_message_format.push(msg);
      }
    }

    return new_message_format;
  }
  //#endregion

  //#region Https Requests
  static async SendRequestToSendLastUserMessage(
    siteOfOrigin: string,
    roomId: string,
    userId: string,
    tag: string
  ) {
    let path = `${HttpsHandler.BASE_URL}/${HttpsActionNames.LAST_MESSAGE_HANDLER}?site=${siteOfOrigin}&roomId=${roomId}&userId=${userId}&tag=${tag}`;
    await HttpsHandler.SendGetRequest(
      path,
      false,
      (success, data, message) => {
        console.log(`Success: ${success} | Data: ${data} | ${message}`);
      },
      (success, message) => {
        console.log(`Success: ${success} | ${message}`);
      }
    );
  }
  //#endregion

  //#region Chat Listeners
  static ListenForChatRoomLock(
    roomID: string,
    siteOfOrigin: string,
    onUpdate: (is_room_locked: boolean) => void | null
  ) {
    const key = `${roomID}@room-lock`;
    FirestoreManager.AttachFirestoreListener(
      `sites/${siteOfOrigin}/rooms`,
      roomID,
      key,
      (doc) => {
        let isRoomLocked: boolean = false;
        if (doc) {
          const data = doc.data();
          if (data) {
            if (data.is_room_locked)
              isRoomLocked = data.is_room_locked as boolean;
          }
        }
        onUpdate(isRoomLocked);
      }
    );
  }

  static StopListeningForChatRoomLock(roomID: string) {
    const key = `${roomID}@room-lock`;
    FirestoreManager.DetachFirestoreListener(key);
  }

  static ListenForChatRoomUserSendLastMessage(
    roomID: string,
    siteOfOrigin: string,
    onUpdate: (user_send_last_message: boolean) => void | null
  ) {
    const key = `${roomID}@user-send-last-message`;
    FirestoreManager.AttachFirestoreListener(
      `sites/${siteOfOrigin}/rooms`,
      roomID,
      key,
      (doc) => {
        let userSendLastMessage: boolean = false;
        if (doc) {
          const data = doc.data();
          if (data) {
            if (data.is_room_locked)
              userSendLastMessage = data.user_send_last_message as boolean;
          }
        }
        onUpdate(userSendLastMessage);
      }
    );
  }

  static StopListeningForChatRoomUserSendLastMessage(roomID: string) {
    const key = `${roomID}@user-send-last-message`;
    FirestoreManager.DetachFirestoreListener(key);
  }

  static ListenForChatRoomMessages(
    roomID: string,
    siteOfOrigin: string,
    entry_limit: number = 25,
    onUpdate: (
      messages: MessageModel[] | undefined
    ) => void | Promise<void> | null
  ) {
    const firestore = getFirestore();
    const messagesRef = collection(
      firestore,
      `sites/${siteOfOrigin}/rooms/${roomID}/messages`
    );
    const messagesQuery = query(
      messagesRef,
      orderBy("createdAt", "asc"),
      limitToLast(entry_limit + 1)
    );
    const key = `${roomID}@messages`;
    FirestoreManager.AttachFirestoreListenerWithQuery(
      messagesQuery,
      key,
      (snapshot) => {
        let messages: MessageModel[] = [];
        if (snapshot) {
          let docData: DocumentData[] = [];
          snapshot.forEach((doc) => {
            if (doc) {
              const data = doc.data();
              if (data) docData.push(data);
            }
          });

          if (docData) {
            const temp = Chat.ExtractMessageQuery(docData, snapshot);
            if (temp) messages = temp;
          }
        }

        onUpdate(messages);
      }
    );
  }
 
  static async updateChatterReplied(roomID:string, messagedId:string, siteOfOrigin:string){
    console.log(roomID)
    const firestore = getFirestore();
      const messageDoc = doc(firestore, `sites/${siteOfOrigin}/rooms/${roomID}/messages`, messagedId);

      let params = {
        "isChatterReplied": true
      }
      const roomSnap = await getDoc(messageDoc);
      if (roomSnap.exists())
         updateDoc(messageDoc, params);
  }

  static ListenForChatRoomMessagesWithDateFilter(
    roomID: string,
    siteOfOrigin: string,
    entry_limit: number = 25,
    startDate: Date,
    endDate: Date,
    timeBefore: string = "1" ,
    onUpdate: (
      messages: MessageModel[] | undefined
    ) => void | Promise<void> | null
  ) {
    console.log(startDate)
    let newDate = new Date(startDate.valueOf())
    if (timeBefore) {
    const newTime = parseInt(timeBefore);
    newDate.setHours(startDate.getHours() - newTime);
    console.log('here')
    } else {
    newDate.setHours(0, 0, 0, 0);
    }
    console.log(newDate)
    const firestore = getFirestore();
    const messagesRef = collection(
      firestore,
      `sites/${siteOfOrigin}/rooms/${roomID}/messages`
    );
    const messagesQuery = query(
      messagesRef,
      orderBy("createdAt", "desc"),
      where("createdAt", ">=",Timestamp.fromDate(newDate)),
      where("createdAt", "<=",Timestamp.fromDate(endDate)),
      // limitToLast(entry_limit + 1),
      limit(entry_limit+1),
      );
    const key = `${roomID}@messages`;
    FirestoreManager.AttachFirestoreListenerWithQuery(
      messagesQuery,
      key,
      (snapshot) => {
        let messages: MessageModel[] = [];
        if (snapshot) {
          let docData: DocumentData[] = [];
          snapshot.forEach((doc) => {
            if (doc) {
              const data = doc.data();
              if (data) docData.push(data);
            }
          });

          if (docData) {
            const temp = Chat.ExtractMessageQuery(docData, snapshot);
            if (temp) messages = temp;
          }
        }

        onUpdate(messages);
      }
    );
  }

  //To get the length of a message in a particular room
  static async ListenForChatRoomMessagesLength(
    roomID: string,
    siteOfOrigin: string,
  ): Promise<void | number>  {
    try{
      const firestore = getFirestore();
      const messagesRef = collection(
        firestore,
        `sites/${siteOfOrigin}/rooms/${roomID}/messages`
      );
      const messagesQuery = query(messagesRef);
      const response =  await getDocs(messagesQuery);
      let length = response.docs.length;
      return Promise.resolve(length);
    } catch(e) {
        return Promise.reject();
    }
  }

  static async CountRoomsByProfileId(
    profileIds: string[],
  ): Promise<any>  {
    try{
      const firestore = getFirestore();
      const messagesRef = collection(
        firestore,
        `sites/flirtybum/rooms`
      );
      const messagesQuery = query(messagesRef, where('profile_id','in',profileIds));
      const response =  await getDocs(messagesQuery);
      // let length = response.docs.length;
      let profileIdList: {profileId: string | null}[] = [];
      response.forEach((doc) => {
        let currData = doc.data();
        const obj: {profileId: string | null} = {
          profileId : currData.profile_id,
        }
        profileIdList.push(obj);
    });
      return Promise.resolve(profileIdList);
    } catch(e) {
        return Promise.reject();
    }
  }

  static StopListeningForChatRoomMessages(roomID: string) {
    const key = `${roomID}@messages`;
    FirestoreManager.DetachFirestoreListener(key);
  }

  public static async GetFeedEntriesPaginated(
    visibleStart: QueryDocumentSnapshot<DocumentData> = null as any,
    entry_limit: number = 25,
    chatterId: string,
    filterTag: string,
    isChatterFeedOnly: boolean = false
  ): Promise<PaginatedFeedEntries> {
    let paginatedFeedEntries: PaginatedFeedEntries = new PaginatedFeedEntries();
    let feedEntries:FeedEntryModel[] = [];

    const firestore = getFirestore();
    let path = "";
    let keyPrefix = "";
    if(!isChatterFeedOnly) {
      switch (User.Model?.userType)
      {
        case UserTypes.TYPE_ADMIN:
          path = `admin/${this.ADMIN_FEED}/feed`;
          keyPrefix = "admin";
          break;
        case UserTypes.TYPE_CHATTER:
          path = `chatter/${this.CHATTER_REQUEST_FEED}/feed`;
          keyPrefix = "chatter";
          break;
        case UserTypes.TYPE_MODERATOR:
          path = `admin/${this.ADMIN_FEED}/feed`;
          keyPrefix = "moderator";
          break;
      }
    } else {
      path = `chatter/${this.CHATTER_REQUEST_FEED}/feed`;
    }

    const messagesRef = collection(firestore, path);
    const queries: QueryConstraint[] = [];
    
    // Filters
    if (chatterId) 
      queries.push(where("message.chatter_id","==", chatterId))
    if (filterTag) 
      queries.push(where("tag","==",filterTag))

    // Sorting and Entry Limit
    queries.push(orderBy("createdAt", "desc"));
    queries.push(limit(entry_limit + 1));

    if (visibleStart)
      queries.push(startAt(visibleStart));
    else
      queries.push(endBefore(null));

    let isStaging = FirebaseApp.environment === EnvironmentType.STAGING ? true : false;
    console.log(`Is Staging? ${isStaging}`);
    queries.push(where("isTestEntry", "==", isStaging));

    const feedQuery = query(messagesRef, ...queries);
    
    const feedEntriesSnap = await getDocs(feedQuery);
    if (feedEntriesSnap.docs)
    {
      /*
        Check if there is 1 more to the entry limit document count.
        If there is, then there is a next page
      */
      if (feedEntriesSnap.docs.length === entry_limit + 1)
      {
        // Assign the next visible start as that last entry so the next page will start at that item
        paginatedFeedEntries.node.visibleStart = feedEntriesSnap.docs[feedEntriesSnap.docs.length - 1];
        paginatedFeedEntries.node.nodeId = paginatedFeedEntries.node.visibleStart.id.toString();
        paginatedFeedEntries.nextNodeExists = true;
      }
    }
    
    console.log(`Snap length: ${feedEntriesSnap.docs.length}`);

    for (let i = 0; i < feedEntriesSnap.docs.length; i++)
    {
      // console.log("Try");
      // zero-indexed. So the latest entry will be on the index of the entry limit
      if (i === entry_limit)
        continue;

      // console.log("Proceed");
      const doc = feedEntriesSnap.docs[i];
      const data = doc.data();
      if (data) 
      {
        // var timestamp = data.createdAt !== null ? data.createdAt.toDate() : undefined;
        // const timeDisplay = timestamp !== undefined ? timestamp.toLocaleDateString() + " - " + timestamp.toLocaleTimeString() : "Loading...";
        const timeDisplay =
          data.createdAt !== null ? data.createdAt.toDate() : undefined;
        let feedEntry: FeedEntryModel = {
          id: doc.id,
          message: data.message
            ? this.ExtractSingleMessageDoc(doc.id, data.message)
            : undefined,
          roomId: data.roomId,
          siteOfOrigin: data.siteOfOrigin,
          tag: data.tag,
          userId: data.userId,
          createdAt: timeDisplay,
          append: data.append ? data.append : "",
        };

        feedEntries.push(feedEntry);
      }
    }

    const compareTime = (tagAction: string) => {
      const filterEntries: FeedEntryModel[] = feedEntries
        .reverse()
        .filter((item: any) => item.tag == tagAction);
      let userObjectArray: FeedEntryModel[] = [];
      let filterEntry: any;

      for (filterEntry of filterEntries) {
        let isFind: FeedEntryModel[] = [];

        // eslint-disable-next-line no-loop-func
        isFind = userObjectArray.filter((item: any) => {
          if (item.userId == filterEntry.userId) {
            let date1: Date = new Date(filterEntry.createdAt);
            let date2: Date = new Date(item.createdAt);
            if (
              Math.abs(date1.getTime() - date2.getTime()) / 3600000 <=
              1
            ) {
              return item;
            }
          }
        });
        if (isFind.length == 0) {
          userObjectArray.push(filterEntry);
        }
      }

      feedEntries = feedEntries.filter(
        (data: any) => data.tag !== tagAction
      );
      feedEntries.push(...userObjectArray);
    };
    /**This function removes logged in tag only when user first time signup (because user is auto redirect to login page).
     * The mins argument takes minute (like currently we pass 2 mins as a time so  within 2 minutes if user redirect then remove it's Logged In tag and keep only New User tag)
     */
    const removeLoggedInTag = (
      tagAction: string,
      tagAction2: string,
      mins: number
    ) => {
      const loggedInIds: any = [];
      const filterEntries: FeedEntryModel[] = feedEntries
        .reverse()
        .filter(
          (item: any) => item.tag === tagAction || item.tag === tagAction2
        );
      let userObjectArray: FeedEntryModel[] = [];
      let filterEntry: any;

      for (filterEntry of filterEntries) {
        let isFind: FeedEntryModel[] = [];

        // eslint-disable-next-line no-loop-func
        isFind = userObjectArray.filter((item: any) => {
          if (item.userId === filterEntry.userId) {
            let date1: Date = new Date(filterEntry.createdAt);
            let date2: Date = new Date(item.createdAt);
            let timeInMs = 60 * mins * 1000;
            if (
              Math.abs(date1.getTime() - date2.getTime()) / timeInMs <=
              1.0
            ) {
              loggedInIds.push(item.id);
              return item;
            }
          }
        });
        if (isFind.length == 0) {
          userObjectArray.push(filterEntry);
        }
      }
      feedEntries = feedEntries.filter(
        (data: any) => !loggedInIds.includes(data.id)
      );
    };

    compareTime("Logged Out");
    compareTime("Logged In");
    removeLoggedInTag("New User", "Logged In", 2);
    feedEntries.sort((a: any, b: any) => a.createdAt - b.createdAt);
    feedEntries.reverse();
    paginatedFeedEntries.feedEntries = feedEntries;

    return Promise.resolve(paginatedFeedEntries);
  }

  static ListenForFeed(
    entry_limit: number = 25,
    hasLimit: boolean = false,
    chatterId: string,
    filterTag: string,
    onUpdate: (feedEntries: FeedEntryModel[] | undefined) => void | null,
    isChatterFeedOnly: boolean = false,
    supportOnly: boolean = false,
  ) {
    //IschatterFeedOnly to display only chatter feed data
    const firestore = getFirestore();
    let path = "";
    let keyPrefix = "";
    if(!isChatterFeedOnly){
    switch (User.Model?.userType) {
      case UserTypes.TYPE_ADMIN:
        path = `admin/${this.ADMIN_FEED}/feed`;
        keyPrefix = "admin";
        break;
      case UserTypes.TYPE_CHATTER:
        path = `chatter/${this.CHATTER_REQUEST_FEED}/feed`;
        keyPrefix = "chatter";
        break;
      case UserTypes.TYPE_MODERATOR:
        path = `admin/${this.ADMIN_FEED}/feed`;
        keyPrefix = "moderator";
        break;
    }
  } else {
      path = `chatter/${this.CHATTER_REQUEST_FEED}/feed`;
    }
    const messagesRef = collection(firestore, path);

    let isStaging =
      FirebaseApp.environment === EnvironmentType.STAGING ? true : false;
    const queries: QueryConstraint[] = [];
    if(chatterId) queries.push(where("message.chatter_id","==", chatterId))
    if(filterTag) queries.push(where("tag","==",filterTag))
    queries.push(orderBy("createdAt", "desc"));
    queries.push(where("isTestEntry", "==", isStaging));
    if (hasLimit) queries.push(limit(entry_limit));

    if (supportOnly)
    {
      queries.push(where("message.recepient_id", "in", Profile.profileSupportIds));
    }

    const messagesQuery = query(messagesRef, ...queries);
    const key = `${keyPrefix}@feed`;
    FirestoreManager.DetachFirestoreListener(key);
    FirestoreManager.AttachFirestoreListenerWithQuery(
      messagesQuery,
      key,
      (snapshot) => {
        // console.log("snap shot for data", snapshot)
        let feedEntries: FeedEntryModel[] = [];
        if (snapshot) {
          snapshot.forEach((doc) => {
            if (doc) {
              const data = doc.data();
              if (data) {
                // var timestamp = data.createdAt !== null ? data.createdAt.toDate() : undefined;
                // const timeDisplay = timestamp !== undefined ? timestamp.toLocaleDateString() + " - " + timestamp.toLocaleTimeString() : "Loading...";
                const timeDisplay =
                  data.createdAt !== null ? data.createdAt.toDate() : undefined;
                let feedEntry: FeedEntryModel = {
                  id: doc.id,
                  message: data.message
                    ? this.ExtractSingleMessageDoc(doc.id, data.message)
                    : undefined,
                  roomId: data.roomId,
                  siteOfOrigin: data.siteOfOrigin,
                  tag: data.tag,
                  userId: data.userId,
                  createdAt: timeDisplay,
                  append: data.append ? data.append : "",
                };

                feedEntries.push(feedEntry);
              }
            }
          });
        }
        const compareTime = (tagAction: string) => {
          const filterEntries: FeedEntryModel[] = feedEntries
            .reverse()
            .filter((item: any) => item.tag == tagAction);
          let userObjectArray: FeedEntryModel[] = [];
          let filterEntry: any;

          for (filterEntry of filterEntries) {
            let isFind: FeedEntryModel[] = [];

            // eslint-disable-next-line no-loop-func
            isFind = userObjectArray.filter((item: any) => {
              if (item.userId == filterEntry.userId) {
                let date1: Date = new Date(filterEntry.createdAt);
                let date2: Date = new Date(item.createdAt);
                if (
                  Math.abs(date1.getTime() - date2.getTime()) / 3600000 <=
                  1
                ) {
                  return item;
                }
              }
            });
            if (isFind.length === 0) {
              userObjectArray.push(filterEntry);
            }
          }

          feedEntries = feedEntries.filter(
            (data: any) => data.tag !== tagAction
          );
          feedEntries.push(...userObjectArray);
        };
        /**This function removes logged in tag only when user first time signup (because user is auto redirect to login page).
         * The mins argument takes minute (like currently we pass 2 mins as a time so  within 2 minutes if user redirect then remove it's Logged In tag and keep only New User tag)
         */
        const removeLoggedInTag = (
          tagAction: string,
          tagAction2: string,
          mins: number
        ) => {
          const loggedInIds: any = [];
          const filterEntries: FeedEntryModel[] = feedEntries
            .reverse()
            .filter(
              (item: any) => item.tag === tagAction || item.tag === tagAction2
            );
          let userObjectArray: FeedEntryModel[] = [];
          let filterEntry: any;

          for (filterEntry of filterEntries) {
            let isFind: FeedEntryModel[] = [];

            // eslint-disable-next-line no-loop-func
            isFind = userObjectArray.filter((item: any) => {
              if (item.userId === filterEntry.userId) {
                let date1: Date = new Date(filterEntry.createdAt);
                let date2: Date = new Date(item.createdAt);
                let timeInMs = 60 * mins * 1000;
                if (
                  Math.abs(date1.getTime() - date2.getTime()) / timeInMs <=
                  1.0
                ) {
                  loggedInIds.push(item.id);
                  return item;
                }
              }
            });
            if (isFind.length === 0) {
              userObjectArray.push(filterEntry);
            }
          }
          feedEntries = feedEntries.filter(
            (data: any) => !loggedInIds.includes(data.id)
          );
        };
        compareTime("Logged Out");
        compareTime("Logged In");
        removeLoggedInTag("New User", "Logged In", 2);
        feedEntries.sort((a: any, b: any) => a.createdAt - b.createdAt);
        feedEntries.reverse();
        onUpdate(feedEntries);
      }
    );
  }

  static StopListeningForFeed() {
    let keyPrefix = "";
    switch (User.Model?.userType) {
      case UserTypes.TYPE_ADMIN:
        keyPrefix = "admin";
        break;
      case UserTypes.TYPE_CHATTER:
        keyPrefix = "chatter";
        break;
      case UserTypes.TYPE_MODERATOR:
        keyPrefix = "moderator";
        break;
    }
    const key = `${keyPrefix}@feed`;
    FirestoreManager.DetachFirestoreListener(key);
  }

  // Listen for ONLY support Messages
  static ListenForSupportRequestsCount(
    onUpdate: (count: number) => void | null,
  ) {
    //IschatterFeedOnly to display only chatter feed data
    const firestore = getFirestore();
    let path = "";
    let keyPrefix = "";
    switch (User.Model?.userType) {
      case UserTypes.TYPE_ADMIN:
        path = `admin/${this.ADMIN_FEED}/feed`;
        keyPrefix = "admin";
        break;
      case UserTypes.TYPE_CHATTER:
        path = `chatter/${this.CHATTER_REQUEST_FEED}/feed`;
        keyPrefix = "chatter";
        break;
      case UserTypes.TYPE_MODERATOR:
        path = `admin/${this.ADMIN_FEED}/feed`;
        keyPrefix = "moderator";
        break;
    }

    const messagesRef = collection(firestore, path);

    let isStaging =
      FirebaseApp.environment === EnvironmentType.STAGING ? true : false;
    const queries: QueryConstraint[] = [];
    queries.push(orderBy("createdAt", "desc"));
    queries.push(where("isTestEntry", "==", isStaging));
    queries.push(where("message.recepient_id", "in", Profile.profileSupportIds));
    
    const messagesQuery = query(messagesRef, ...queries);
    const key = `${keyPrefix}@support-requests`;
    FirestoreManager.DetachFirestoreListener(key);
    FirestoreManager.AttachFirestoreListenerWithQuery(
      messagesQuery,
      key,
      (snapshot) => {
        // console.log("snap shot for data", snapshot)
        let count = 0;
        if (snapshot) {
          if (snapshot.docs.length > 0)
            count = snapshot.docs.length;
        }
  
        onUpdate(count);
      }
    );
  }

  static StopListeningForSupportRequestsCount() {
    let keyPrefix = "";
    switch (User.Model?.userType) {
      case UserTypes.TYPE_ADMIN:
        keyPrefix = "admin";
        break;
      case UserTypes.TYPE_CHATTER:
        keyPrefix = "chatter";
        break;
      case UserTypes.TYPE_MODERATOR:
        keyPrefix = "moderator";
        break;
    }
    const key = `${keyPrefix}@support-requests`;
    FirestoreManager.DetachFirestoreListener(key);
  }

  //new api made for listen messages by user in sorted format

  static ListenForAllMessageUser(
    userId: string,
    siteOfOrigin: string,
    limitDoc:number,
    hasLimit: boolean,
    onUpdate: (messages: UserMessageTable[] | undefined) => void | null
  ) {
    console.log("listenforallmessageuser");
    const firestore = getFirestore();
    const messagesRef = collection(firestore, `sites/${siteOfOrigin}/rooms`);
    const queries: QueryConstraint[] = [];
    queries.push(orderBy("last_updated", "desc"));
    queries.push(where("user_id", "==", userId))
    if(hasLimit) queries.push(limit(limitDoc));
    const messagesQuery = query(messagesRef,...queries);
    const key = `${User.Model?.uuid}@${"keyPrefix"}-last-read-messages`;
    FirestoreManager.AttachFirestoreListenerWithQuery(
      messagesQuery,
      key,
      async (snapshot) => {
        let messages: UserMessageTable[] = [];
        let sortMsgArr:any = [];
        if(snapshot){
          try{
            for(let i=0;i<snapshot.size;i++){
              const doc = snapshot.docs[i];
              const data = doc.data();
              if(data){
                if(!doc.id.includes(userId)) continue;
                const roomId = Chat.GetPrivateChatRoomId(userId,data.profile_id);
                const result = await Chat.ListenForChatRoomMessagesLength(roomId, siteOfOrigin);
                sortMsgArr.push({
                          length:result,
                          profileId: data.profile_id
                        });
              }
            }
            for (const element of sortMsgArr) {
            const profile = await Profile.GetProfile(element.profileId);
                            let model: UserMessageTable = {
                            profile_id: profile.id,
                            gender: profile.gender,
                            profile_name: profile.displayName,
                            age: String(profile.age),
                            country: profile.country,
                            appearance: profile.bodyType,
                            sexualorientation: profile.sexualOrientation,
                            meritalstatus: profile.maritalStatus,
                            length: String(element.length),
                            photoUrl: profile.photoURL,
                          };
                          messages.push(model);
            }
            onUpdate(messages);
          } catch(e) {
          console.log("Error while loading previous conversation");
          }
        }
      }
    );
  }

  static ListenForPurchaseRoomMessages(
    roomID: string,
    siteOfOrigin: string,
    entry_limit: number = 10,
    isChatterTrueOnly: boolean = false,
    onUpdate: (
      messages: MessageModel[] | undefined
    ) => void | Promise<void> | null
  ) {
    const firestore = getFirestore();
    const messagesRef = collection(
      firestore,
      `sites/${siteOfOrigin}/purchase_logs/${roomID}/messages`
    );
    let messagesQuery:any;
    if(isChatterTrueOnly){
      messagesQuery = query(
        messagesRef,
        where("isChatter", "==", true),
        orderBy("createdAt", "asc"),
        limitToLast(entry_limit + 1)
      );
    } else{
      messagesQuery = query(
        messagesRef,
        orderBy("createdAt", "asc"),
        limitToLast(entry_limit + 1)
      );
    }
    const key = `${roomID}@messages`;
    FirestoreManager.AttachFirestoreListenerWithQuery(
      messagesQuery,
      key,
      (snapshot) => {
        let messages: MessageModel[] = [];
        if (snapshot) {
          let docData: DocumentData[] = [];
          snapshot.forEach((doc) => {
            if (doc) {
              var timestamp =
              doc.data().createdAt
                ? doc.data().createdAt.toDate()
                : undefined;
              const data = doc.data();
              const timeDisplay =
              timestamp !== undefined ? timestamp.toLocaleString() : "Loading...";
              if (data) docData.push({...data, time: timeDisplay});
            }
          });

          if (docData) {
            const temp = Chat.ExtractMessageQuery(docData, snapshot);
            if (temp) messages = temp;
          }
        }

        onUpdate(messages);
      }
    );
  }

  static StopListenForAllMessageUser() {
    const key = `${User.Model?.uuid}@${"keyPrefix"}-last-read-messages`;
    // const key = `${User.Model?.uuid}@last-read-messages`;
    FirestoreManager.DetachFirestoreListener(key);
  }

  // static ListenForLastReadMessages(siteOfOrigin: string, userID: string, onUpdate: (messages: ProfileAndLatestMessageModel[] | undefined) => void | null)
  // {
  //   const firestore = getFirestore();
  //   const messagesRef = collection(firestore, `sites/localhost/rooms`);
  //   const messagesQuery = query(messagesRef, orderBy('last_updated', 'desc'),where("user_id", "==", userID), limit(15));
  //   const key = `${User.Model?.uuid}@last-read-messages`;
  //   FirestoreManager.AttachFirestoreListenerWithQuery(messagesQuery, key, async (snapshot) => {
  //     let messages: ProfileAndLatestMessageModel[] = [];
  //     if (snapshot) {
  //       for (let i = 0; i < snapshot.size; i++) {
  //         let doc = snapshot.docs[i];
  //         const data = doc.data();

  //         console.log(data)
  //         if (data) {
  //           let id: string = data.profile_id;
  //           await this.GetLatestMessage(doc.id, siteOfOrigin).then((msg) => {
  //             // console.log("chatapp msg", msg)
  //             let model: ProfileAndLatestMessageModel = {
  //               profile_id: id,
  //               message: msg,
  //               read_status: data.read_status
  //             }
  //             messages.push(model);
  //           })
  //         }
  //       }
  //     }

  //     onUpdate(messages);
  //   });
  // }

  static StopListeningForLastReadMessages() {
    const key = `${User.Model?.uuid}@last-read-messages`;
    FirestoreManager.DetachFirestoreListener(key);
  }
  //#endregion
}
