/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, createContext, useContext, useEffect, useState, useCallback} from 'react'
import { Chat } from '../../../client/chat/Chat';
import { EnvironmentType, FirebaseApp } from '../../../client/FirebaseApp';
import { FirestoreManager } from '../../../client/system/FirestoreManager';
import { ChatRoomModel } from '../../helpers';

const SupportContext = createContext<any>({});

const SupportProvider: React.FC = ({children}) => {
    const siteRef = FirebaseApp.environment === EnvironmentType.STAGING ? 'localhost' : 'flirtybum';
    const [supportLogs, setSupportLogs] = useState<ChatRoomModel[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [notificationCount, setNotificationCount] = useState(0);

    const getSuppportLogs = useCallback(async function(siteRef:string){
        await Chat.getSupportLogs(siteRef, null as any, null as any, '', 'false').then((res)=>{
           if(res.length){
            const supportProfilers= ['v741ZIst2maec64wz3JE','LxsZwDHql2xvEofJztZz'];
            let totalNotification:boolean[] = [];
            let dataWithNewTag:any = [];
            for (const data of res) {
                FirestoreManager.DetachFirestoreListener(`${data.roomId}@messages`)
                Chat.ListenForChatRoomMessages(data.roomId, siteRef, 1, async(message:any )=>{
                    if(message?.length===2){
                  if(!supportProfilers.includes(message[1].user)){
                    totalNotification.push(true);
                    dataWithNewTag.push({...data, isNew:true})
                  }
                  else{
                    dataWithNewTag.push({...data, isNew: false})
                  }
                }
                if(message?.length===1){
                  if(!supportProfilers.includes(message[0].user)){
                    totalNotification.push(true);
                    dataWithNewTag.push({...data, isNew:true})
                  }
                  else{
                    dataWithNewTag.push({...data, isNew:false})
                  }
                }
                if(res.indexOf(data) ===res.length-1){
                    setSupportLogs(dataWithNewTag);
                    setNotificationCount(totalNotification.length);
                    setIsLoading(false);
                }
              })
            }
           } else {
                setIsLoading(false);
           }
        });
     },[])
     
     useEffect(() => {
            getSuppportLogs(siteRef);
     }, [getSuppportLogs])
     
    const value: any = {
        supportLogs,
        isLoading,
        notificationCount
    }

  return <SupportContext.Provider value={value}>{children}</SupportContext.Provider>
}

function useSupportContext() {
  return useContext(SupportContext)
}

export { useSupportContext, SupportProvider }
